import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import { Badge } from "@/components/ui/shadcn/badge";

interface FactionStat {
  name: string;
  count: number;
  win_rate: number;
}

interface PlayerFactionStatsCardProps {
  factions: FactionStat[];
}

export function PlayerFactionStatsCard({ factions }: PlayerFactionStatsCardProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Preferred Factions</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {factions?.map((faction) => (
            <div
              key={faction.name}
              className="flex items-center justify-between p-3 bg-secondary/50 rounded-lg"
            >
              <span>{faction.name}</span>
              <Badge variant="secondary">
                {faction.count} {faction.count === 1 ? 'tournament' : 'tournaments'}
              </Badge>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
