import React from 'react';
import { Checkbox } from "@/components/ui/shadcn/checkbox";
import { ExternalLink } from 'lucide-react';
import {Player} from "@/components/ui/players/Types.ts";

interface PlayerMergeInfoCardProps {
  player: Player;
  isSelected: boolean;
  onSelect: () => void;
}

const PlayerMergeInfoCard = ({
                               player,
                               isSelected,
                               onSelect
                             }: PlayerMergeInfoCardProps) => {
  return (
    <div className={`
      flex flex-col min-h-[400px] p-6 rounded-lg border-2
      ${isSelected ? 'border-primary' : 'border-border'}
      bg-card
    `}>
      <div className="space-y-4 flex-grow">
        <div className="text-xl font-bold">{player.full_name}</div>

        <div className="space-y-2">
          <div>
            <div className="text-sm text-muted-foreground">First Name:</div>
            <div>{player.first_name || '—'}</div>
          </div>

          <div>
            <div className="text-sm text-muted-foreground">Last Name:</div>
            <div>{player.last_name || '—'}</div>
          </div>

          <div>
            <div className="text-sm text-muted-foreground">Nickname:</div>
            <div>{player.nickname || 'N/A'}</div>
          </div>
        </div>

        {player.external_platforms?.length > 0 && (
          <div>
            <div className="text-sm font-medium text-muted-foreground mb-2">
              Platform IDs:
            </div>
            <div className="space-y-1.5">
              {player.external_platforms.map((platform, index) => (
                <div key={index} className="flex items-center gap-2 text-sm">
                  <span className="text-muted-foreground">
                    {platform.platform_name} ID:
                  </span>
                  <a
                    href={platform.platform_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline inline-flex items-center gap-1"
                  >
                    {platform.external_id}
                    <ExternalLink className="h-3 w-3" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <div className="text-sm font-medium text-muted-foreground mb-2">
            Recent Tournaments:
          </div>
          <ul className="list-disc list-inside space-y-1">
            {player.recent_tournaments?.map(tournament => (
              <li key={tournament.id} className="text-sm">
                {tournament.name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="pt-4 border-t mt-4">
        <label className="flex items-center space-x-2">
          <Checkbox
            checked={isSelected}
            onCheckedChange={onSelect}
          />
          <span>Merge to this Player</span>
        </label>
      </div>
    </div>
  );
};

export default PlayerMergeInfoCard;
