import React from 'react';
import { Button } from "@/components/ui/shadcn/button";
import { Loader2 } from 'lucide-react';

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
  loadingText?: string;
}

const LoadingButton = ({
                         children,
                         loading,
                         loadingText = "Loading...",
                         ...props
                       }: LoadingButtonProps) => {
  return (
    <Button disabled={loading} {...props}>
      {loading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          {loadingText}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
