import { useEffect } from 'react';
import { Table } from '@tanstack/react-table';
import { useFilterParams } from './useFilterParams';

export interface FilterConfig {
  columnId: string;
  paramName: string;
}

export const useCombinedFilters = <T,>(
  table: Table<T>,
  filters: FilterConfig[]
) => {
  // Initialize all filters
  filters.forEach(filter => {
    useFilterParams(table, filter.columnId, filter.paramName);
  });

  // Set initial filters from URL on mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    filters.forEach(({ columnId, paramName }) => {
      const value = params.get(paramName);
      if (value) {
        table.getColumn(columnId)?.setFilterValue(value);
      }
    });
  }, []);
};
