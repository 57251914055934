import React, { useCallback, useState } from 'react';
import { router, usePage } from '@inertiajs/react';
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/shadcn/command";
import {
  Search,
  Users,
  Trophy,
  LayoutDashboard,
  CalendarDays, CalendarArrowUp, CalendarCheck
} from "lucide-react";
import { debounce } from 'lodash';
import axios from 'axios';

interface SearchResult {
  players: Array<{
    id: string;
    full_name: string;
    nickname?: string;
    home_region?: {
      code: string;
      country_flag: string;
    };
  }>;
  tournaments: Array<{
    id: string;
    name: string;
    start_date: string;
  }>;
}

const STATIC_PAGES = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    href: '/:region/',
    icon: LayoutDashboard
  },
  {
    id: 'rankings',
    name: 'Rankings',
    href: '/:region/rankings',
    icon: Trophy
  },
  {
    id: 'latest',
    name: 'Latest Results',
    href: '/:region/tournaments/latest',
    icon: CalendarDays
  },
  {
    id: 'upcoming',
    name: 'Upcoming Tournaments',
    href: '/:region/tournaments/upcoming',
    icon: CalendarArrowUp
  },
  {
    id: 'tournaments',
    name: 'Finished Tournaments',
    href: '/:region/tournaments',
    icon: CalendarCheck
  },
  {
    id: 'players',
    name: 'Players',
    href: '/players',
    icon: Users
  }
];

// Helper function for OS detection
const isMacOS = () => {
  return typeof window !== 'undefined' &&
    (navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.userAgent.toUpperCase().indexOf('MAC') >= 0);
};

const UniversalSearch = () => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SearchResult>({ players: [], tournaments: [] });
  const { current_region } = usePage().props;

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      performSearch(searchQuery);
    }, 200),
    []
  );

  // Toggle search dialog with keyboard shortcut
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && ((isMacOS() && e.metaKey) || (!isMacOS() && e.ctrlKey))) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  // Perform search
  const performSearch = async (searchQuery: string) => {
    if (searchQuery.length < 2) {
      setResults({ players: [], tournaments: [] });
      return;
    }

    try {
      const response = await axios.get('/search', {
        params: {
          query: searchQuery,
          region: current_region.slug
        }
      });
      setResults(response.data);
    } catch (error) {
      console.error('Search error:', error);
      setResults({ players: [], tournaments: [] });
    }
  };

  // Handle search input changes
  React.useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch]);

  // Navigate to selected item
  const handleSelect = (itemType: string, item: any) => {
    setOpen(false);

    const staticPage = STATIC_PAGES.find(page => page.id === itemType);
    if (staticPage) {
      // Replace :region with the current region slug
      const resolvedHref = staticPage.href.replace(/:region/, current_region.slug);

      router.visit(`${resolvedHref}`);
      return;
    }

    if (itemType === 'player') {
      router.visit(item.friendly_url);
    } else if (itemType === 'tournament') {
      router.visit(item.friendly_url);
    }
  };

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="w-full max-w-xl h-9 rounded-md border border-warhammer-gold/20
                 px-3 py-2 text-sm flex items-center justify-between
                 bg-warhammer-black/50 text-warhammer-gold/80
                 hover:bg-warhammer-black/70 hover:text-warhammer-gold
                 focus:outline-none focus:ring-2 focus:ring-warhammer-gold/50"
      >
        <div className="flex items-center gap-2">
          <Search className="h-4 w-4 text-white" />
          <span>Search...</span>
        </div>
        <kbd className="hidden sm:inline-flex h-5 select-none items-center gap-1 rounded
                     border border-warhammer-gold/20 bg-warhammer-black/50
                     px-1.5 font-mono text-xs font-medium text-warhammer-gold/80">
          {isMacOS() ? <span className="text-xs">⌘</span> : <span className="text-xs">Ctrl</span>}
          K
        </kbd>
      </button>

      <CommandDialog open={open} onOpenChange={setOpen} className="dark">
        <Command
          className="bg-warhammer-black border border-warhammer-gold/20 [&_[cmdk-input-wrapper]_svg]:text-white"
          shouldFilter={false}
        >
          <CommandInput
            placeholder="Search players, tournaments..."
            value={query}
            onValueChange={setQuery}
            className="text-warhammer-gold/80 border-b border-warhammer-gold/20"
          />
          <CommandList className="text-warhammer-gold/80">
            <CommandEmpty className="py-6 text-warhammer-gold/60">
              No results found.
            </CommandEmpty>

            {/* Static Pages */}
            {query.length < 2 && (
              <CommandGroup heading="Quick Navigation" className="text-warhammer-gold">
                {STATIC_PAGES.map((page) => (
                  <CommandItem
                    key={page.id}
                    onSelect={() => handleSelect(page.id, null)}
                    className="flex items-center gap-2 cursor-pointer hover:bg-warhammer-red-dark/30"
                  >
                    <page.icon className="h-4 w-4" />
                    {page.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            )}

            {/* Players Results */}
            {results.players.length > 0 && (
              <CommandGroup heading="Players" className="text-warhammer-gold">
                {results.players.map((player) => (
                  <CommandItem
                    key={player.id}
                    onSelect={() => handleSelect('player', player)}
                    className="flex items-center gap-2 cursor-pointer hover:bg-warhammer-red-dark/30"
                  >
                    <Users className="h-4 w-4" />
                    <span>{player.full_name}</span>
                    {player.home_region && (
                      <span className="text-sm text-warhammer-gold/60 ml-2">
                        {player.home_region.country_flag}
                      </span>
                    )}
                    {player.nickname && (
                      <span className="text-sm text-warhammer-silver">
                        (@{player.nickname})
                      </span>
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            )}

            {/* Tournaments Results */}
            {results.tournaments.length > 0 && (
              <CommandGroup heading="Tournaments" className="text-warhammer-gold">
                {results.tournaments.map((tournament) => (
                  <CommandItem
                    key={tournament.id}
                    onSelect={() => handleSelect('tournament', tournament)}
                    className="flex items-center gap-2 cursor-pointer hover:bg-warhammer-red-dark/30 hover:text-white"
                  >
                    <Trophy className="h-4 w-4" />
                    <span>{tournament.name}</span>
                    <span className="text-sm text-warhammer-gold/60 ml-2">
                      {new Date(tournament.start_date).toLocaleDateString()}
                    </span>
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </CommandDialog>
    </>
  );
};

export default UniversalSearch;
