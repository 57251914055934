import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { Loader2, RefreshCw } from 'lucide-react';
import { Checkbox } from "@/components/ui/shadcn/checkbox";
import { Label } from "@/components/ui/shadcn/label";
import { router } from '@inertiajs/react';

interface TournamentResyncDialogProps {
  isOpen: boolean;
  onClose: () => void;
  platformId: string;
  tournamentId: string;
  platformName: string;
}

const TournamentResyncDialog = ({
                                  isOpen,
                                  onClose,
                                  platformId,
                                  tournamentId,
                                  platformName
                                }: TournamentResyncDialogProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [resyncResults, setResyncResults] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const handleResync = () => {
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }

    setIsProcessing(true);
    router.post('/import', {
      platform: platformId,
      tournament_id: tournamentId,
      resync_results: resyncResults
    }, {
      onFinish: () => {
        setIsProcessing(false);
        setIsConfirming(false);
        setResyncResults(false);
        onClose();
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Resync Tournament from {platformName}</DialogTitle>
        </DialogHeader>

        <div className="py-6">
          <div className="space-y-4">
            <p className="text-muted-foreground">
              This will fetch the latest tournament event information from {platformName}.
              Any manual changes to tournament details may be overwritten.
            </p>

            <div className="flex items-start space-x-2">
              <Checkbox
                id="resync-results"
                checked={resyncResults}
                onCheckedChange={(checked) => setResyncResults(checked as boolean)}
              />
              <div className="grid gap-1.5 leading-none">
                <Label
                  htmlFor="resync-results"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Resync player results
                </Label>
                <p className="text-sm text-muted-foreground">
                  This will update all player results, including wins, losses, and rankings.
                  Factions will be copied if faction is not already set.
                </p>
                {platformName === 'New Recruit' && (
                    <p className="text-sm text-muted-foreground">
                        This may take up to a minute...
                    </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            variant="outline"
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            variant={isConfirming ? "destructive" : "default"}
            onClick={handleResync}
            disabled={isProcessing}
            className="min-w-[100px]"
          >
            {isProcessing ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Syncing...
              </>
            ) : (
              <>
                <RefreshCw className="mr-2 h-4 w-4" />
                {isConfirming ? "Confirm Resync" : "Resync"}
              </>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TournamentResyncDialog;
