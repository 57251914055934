import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { LinkedPlayerCard } from "./LinkedPlayerCard";
import { Storage } from "@/lib/storage";
import {AlarmClock} from "lucide-react";

interface LinkPlayerPromptDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LinkPlayerPromptDialog = ({ isOpen, onClose }: LinkPlayerPromptDialogProps) => {
  const handleDismiss = () => {
    Storage.setLinkPlayerDismissed();
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Link Your Player Profile</DialogTitle>
        </DialogHeader>
        <div className="py-6">
          <p className="text-sm text-muted-foreground mb-6">
            You haven't yet linked your player profile with your oldworldrankings.com user account.
            This allows you to track your tournament results and be notified of new events, rankings, and more.
          </p>
          <LinkedPlayerCard player={null} />
          <div className="flex justify-end gap-2 mt-6">
            <Button
              variant="outline"
              onClick={handleDismiss}
            >
              <AlarmClock className="h-4 w-4 mr-2" />
              Remind Me Later
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LinkPlayerPromptDialog;
