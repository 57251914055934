import React, { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { CheckCircle, XCircle } from 'lucide-react';
import { useToast } from "@/components/ui/shadcn/hooks/use-toast.tsx";

interface FlashMessage {
  [key: string]: string | string[] | { [key: string]: string[] };
}

const Flash = () => {
  const { flash } = usePage().props as { flash: FlashMessage };
  const { toast } = useToast();

  useEffect(() => {
    if (flash.notice) {
      showToast('success', flash.notice);
    }
    if (flash.alert) {
      showToast('error', flash.alert);
    }
  }, [flash]);

  const showToast = (type: 'success' | 'error', message: string | string[] | { [key: string]: string[] }) => {
    const title = type === 'success' ? 'Success' : 'Error';
    const icon = type === 'success' ? <CheckCircle className="h-5 w-5 text-green-500" /> : <XCircle className="h-5 w-5 text-red-500" />;

    let description: React.ReactNode;

    if (typeof message === 'string') {
      description = <p>{message}</p>;
    } else if (Array.isArray(message)) {
      description = (
        <ul className="list-disc pl-4">
          {message.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      );
    } else if (typeof message === 'object') {
      description = (
        <ul className="list-disc pl-4">
          {Object.entries(message).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}
            </li>
          ))}
        </ul>
      );
    }

    toast({
      title: <span className={`font-semibold ${type === 'success' ? 'text-green-500' : 'text-white-500'}`}>{title}</span>,
      description: description,
      duration: 5000,
      variant: type === 'success' ? "default" : "destructive",
      icon: icon,
    });
  };

  return null;
};

export default Flash;
