import { createInertiaApp } from '@inertiajs/react'
import { createElement } from 'react'
import { createRoot } from 'react-dom/client'
import App from "@/pages/App.tsx";
import EmptyLayout from "@/components/layouts/EmptyLayout.tsx";
import StaticPageLayout from "@/components/layouts/StaticPageLayout.tsx";

createInertiaApp({
  title: title => title ? `${title} | oldworldrankings.com` : 'oldworldrankings.com',

  progress: {
    delay: 100,
    color: '#FFD700',
    includeCSS: true,
    showSpinner: false,
  },

  resolve: (name) => {
    const pages = import.meta.glob('../pages/**/*.tsx', {eager: true})
    const page = pages[`../pages/${name}.tsx`]

    // If the page doesn't have a custom layout defined, apply default layouts
    page.default.layout = page.default.layout || ((page) => {
      // Auth pages use empty layout
      if (name.startsWith('auth/')) {
        return createElement(EmptyLayout, null, page)
      }

      // Home (landing) page and static pages use StaticPageLayout
      if (name.startsWith('home') || name.startsWith('static')) {
        return createElement(StaticPageLayout,
          { hideHome: name.startsWith('home') },
          page
        )
      }

      // All other pages use the main App layout
      return createElement(App, null, page)
    })

    return page
  },

  setup({el, App, props}) {
    const root = createRoot(el)
    root.render(createElement(App, props))
  },
})
