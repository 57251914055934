import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { ArrowLeftCircle, ArrowRightCircle } from 'lucide-react';
import { useForm } from '@inertiajs/react';
import PlayerMergeInfoCard from "./PlayerMergeInfoCard";
import DoubleConfirmButton from "@/components/ui/controls/DoubleConfirmButton";
import { PlayerSelect } from "@/components/ui/controls/PlayerSelect";
import axios from 'axios';
import { Loader2 } from 'lucide-react';

interface PlayerDetails {
  id: string;
  full_name: string;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  home_region?: {
    code: string;
    country_flag: string;
  };
  external_platforms: {
    platform_name: string;
    platform_identifier: string;
    external_id: string;
    platform_url: string;
  }[];
  recent_tournaments: {
    id: number;
    name: string;
  }[];
}

interface MergePlayerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  sourcePlayerId: string;
  destinationPlayerId?: string;
}

const MergePlayerDialog = ({
                             isOpen,
                             onClose,
                             sourcePlayerId,
                             destinationPlayerId
                           }: MergePlayerDialogProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sourcePlayer, setSourcePlayer] = useState<PlayerDetails | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<PlayerDetails | null>(null);
  const [mergeInto, setMergeInto] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { post, processing, data, setData, transform } = useForm({
    source_player_id: null
  });

  // Fetch player details when needed
  const fetchPlayerDetails = async (playerId: string) => {
    try {
      const response = await axios.get(`/players/${playerId}/duplicate_player_info`);
      return response.data;
    } catch (error) {
      console.error('Error fetching player details:', error);
      setError('Failed to load player details');
      return null;
    }
  };

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Load source player
        const sourceDetails = await fetchPlayerDetails(sourcePlayerId);
        setSourcePlayer(sourceDetails);

        // Load destination player if provided
        if (destinationPlayerId) {
          const destinationDetails = await fetchPlayerDetails(destinationPlayerId);
          setSelectedDestination(destinationDetails);
        }
      } catch (error) {
        setError('Failed to load player details');
      }

      setIsLoading(false);
    };

    if (isOpen) {
      loadData();
    }
  }, [isOpen, sourcePlayerId, destinationPlayerId]);
  const checkMergeability = (player1: PlayerDetails, player2: PlayerDetails) => {
    if (player1.has_user && player2.has_user) {
      setError("Cannot merge players: Both players are already linked to user accounts");
      return false;
    }
    setError(null);
    return true;
  };

  const handleMerge = () => {
    if (!mergeInto) return;

    const destinationPlayer = mergeInto === sourcePlayer?.id ? sourcePlayer : selectedDestination;
    const checkSourcePlayer = mergeInto === sourcePlayer?.id ? selectedDestination : sourcePlayer;

    if (!checkMergeability(checkSourcePlayer, destinationPlayer)) return;

    transform((data) => ({
      player: {
        ...data
      }
    }));

    post(`/players/${destinationPlayer!.id}/merge`, {
      preserveState: true,
      preserveScroll: true,
      onSuccess: () => {
        onClose();
      },
    });
  };

  // Handle player selection from search
  const handlePlayerSelect = async (player: { id: string; full_name: string }) => {
    setIsLoading(true);
    const details = await fetchPlayerDetails(player.id);
    if (details && sourcePlayer) {
      if (checkMergeability(sourcePlayer, details)) {
        setSelectedDestination(details);
      }
    }
    setIsLoading(false);
  };

  if (error) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="p-6 text-center text-red-500">
            <p>{error}</p>
            <Button onClick={onClose} className="mt-4">Close</Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (isLoading || !sourcePlayer) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="p-6 text-center">
            <Loader2 className="h-8 w-8 animate-spin mx-auto" />
            <p className="mt-2">Loading player details...</p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[900px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">Merge Players</DialogTitle>
        </DialogHeader>

        {!selectedDestination ? (
          <div className="py-6">
            <p className="text-sm text-muted-foreground mb-4">
              Select a player to merge with {sourcePlayer.full_name}
            </p>
            <PlayerSelect
              selectedPlayer={null}
              onSelect={handlePlayerSelect}
              allowCreate={false}
              currentPlayerId={sourcePlayer.id}
            />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-[1fr,auto,1fr] items-center gap-6">
              <PlayerMergeInfoCard
                player={sourcePlayer}
                isSelected={mergeInto === sourcePlayer.id}
                onSelect={() => {
                  setMergeInto(sourcePlayer.id);
                  setData({ ...data, source_player_id: selectedDestination.id });
                }}
              />

              <div className="flex items-center justify-center w-8">
                {mergeInto && (
                  <div className="text-primary">
                    {mergeInto === sourcePlayer.id ? (
                      <ArrowLeftCircle className="w-8 h-8" />
                    ) : (
                      <ArrowRightCircle className="w-8 h-8" />
                    )}
                  </div>
                )}
              </div>

              <PlayerMergeInfoCard
                player={selectedDestination}
                isSelected={mergeInto === selectedDestination.id}
                onSelect={() => {
                  setMergeInto(selectedDestination.id);
                  setData({ ...data, source_player_id: sourcePlayer.id });
                }}
              />
            </div>

            <p className="text-sm text-muted-foreground mt-4">
              Merging will combine tournament results and fill in any blank attributes of the destination player with data from the source player. This action cannot be undone.
            </p>

            <div className="flex justify-end gap-4 mt-4">
              <Button variant="ghost" onClick={() => setSelectedDestination(null)}>
                Change Player
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <DoubleConfirmButton
                onConfirm={handleMerge}
                initialText="Merge Players"
                confirmText="Confirm to merge"
                loadingText="Merging..."
                isLoading={processing}
                disabled={!mergeInto}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MergePlayerDialog;
