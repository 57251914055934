import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/shadcn/button";
import { Loader2 } from 'lucide-react';

interface DoubleConfirmButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onConfirm: () => void;
  initialText: string;
  confirmText: string;
  loadingText?: string;
  isLoading?: boolean;
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link';
}

const DoubleConfirmButton = ({
                               onConfirm,
                               initialText,
                               confirmText,
                               loadingText = "Processing...",
                               isLoading = false,
                               className,
                               variant = "default",
                               ...props
                             }: DoubleConfirmButtonProps) => {
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isConfirming) {
      timeout = setTimeout(() => setIsConfirming(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirming]);

  const handleClick = () => {
    if (isLoading) return;

    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }

    onConfirm();
  };

  return (
    <Button
      variant={isConfirming ? "destructive" : variant}
      onClick={handleClick}
      disabled={isLoading}
      className={className}
      {...props}
    >
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          {loadingText}
        </>
      ) : (
        isConfirming ? confirmText : initialText
      )}
    </Button>
  );
};

export default DoubleConfirmButton;
