import React from 'react';
import {
  flexRender,
  ColumnDef,
  Table as TableInstance,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/shadcn/table";
import { cn } from "@/lib/utils";

interface DataTableProps<T> {
  columns: ColumnDef<T>[];
  getRowClassName?: (row: T, index: number) => string;
  showDivider?: (currentRow: T, previousRow: T | undefined) => boolean;
  table: TableInstance<T>;
}

export function DataTable<T>({
                               columns,
                               getRowClassName,
                               showDivider,
                               table,
                             }: DataTableProps<T>) {
  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, index) => {
              const previousRow = index > 0 ? table.getRowModel().rows[index - 1].original : undefined;
              const shouldShowDivider = showDivider?.(row.original, previousRow);

              return (
                <React.Fragment key={row.id}>
                  {shouldShowDivider && (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="p-0">
                        <div className="border-b-2 border-warhammer-gold" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow
                    className={cn(getRowClassName?.(row.original, index))}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
