import React from 'react';
import { usePage } from '@inertiajs/react';

interface GuardProps {
  children: React.ReactNode;
  requiresAuth?: boolean;
  requiresSuperAdmin?: boolean;
  requiresRegionAdmin?: boolean;
  fallback?: React.ReactNode;
}

interface User {
  id: number;
  email: string;
  is_super_admin: boolean;
  is_region_admin: boolean;
}

interface PageProps {
  current_user?: User;
}

function Guard({
                 children,
                 requiresAuth = false,
                 requiresSuperAdmin = false,
                 requiresRegionAdmin = false,
                 fallback = null,
               }: GuardProps) {
  const { current_user } = usePage<PageProps>().props;

  const isAuthenticated = !!current_user;
  const isSuperAdmin = current_user?.is_super_admin;
  const isRegionAdmin = current_user?.is_region_admin;

  if (requiresAuth && !isAuthenticated) {
    return fallback;
  }

  if (requiresSuperAdmin && !isSuperAdmin) {
    return fallback;
  }

  if (requiresRegionAdmin && !(isRegionAdmin || isSuperAdmin)) {
    return fallback;
  }

  return <>{children}</>;
}

export default Guard;
