import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { ThumbsUp, ThumbsDown, Loader2, MessageSquare } from 'lucide-react';
import {useForm, usePage} from '@inertiajs/react';
import { useToast } from "@/components/ui/shadcn/hooks/use-toast";
import { ArmyListDetails } from "../tournaments/common/ArmyListDetails";
import {Feedback} from "@/components/ui/feedback/Types";

interface FeedbackReviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  feedback: Feedback;
}

export default function FeedbackReviewDialog({ isOpen, onClose, feedback }: FeedbackReviewDialogProps) {
  const { current_region } = usePage().props;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { toast } = useToast();
  const { transform, put, processing, errors, reset } = useForm({
    id: feedback.id,
  });

  const handleStatusUpdate = (status: 'approved' | 'rejected') => {
    setIsProcessing(true);

    transform((data) => ({
      feedback: {
        ...data,
        status
      }
    }));

    put(`/${current_region.slug}/feedback/${feedback.id}`, {
      preserveState: true,
      onSuccess: () => {
        toast({
          title: status === 'approved' ? "Feedback approved" : "Feedback rejected",
          description: `The feedback has been marked as ${status}`,
        });
        onClose();
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Failed to update feedback status",
          variant: "destructive",
        });
      },
      onFinish: () => setIsProcessing(false)
    });
  };

  const renderContent = () => {
    switch (feedback.subject_type) {
      case 'ArmyList': {
        const result = feedback.parent;
        if (!result) return null;

        // Create a modified result with the submitted army list
        const resultWithSubmittedList = {
          ...result,
          army_list: {
            list_type: feedback.data?.list_type || 'text',
            list_text: feedback.data?.list_type === 'text' ? feedback.data.list_content as string : undefined,
            list_json: feedback.data?.list_type === 'owb' ? feedback.data.list_content : undefined,
          }
        };

        return (
          <div className="space-y-4">
            <ArmyListDetails
              result={resultWithSubmittedList}
              tournament={{ name: feedback.parent?.name || '' }}
            />

            {feedback.comment && (
              <div className="border rounded-lg p-4 space-y-2">
                <div className="flex items-center gap-2 text-muted-foreground">
                  <MessageSquare className="h-4 w-4" />
                  <h3 className="font-medium">Additional Comments</h3>
                </div>
                <p className="text-sm">{feedback.comment}</p>
              </div>
            )}
          </div>
        );
      }
      default:
        return (
          <div className="p-4 text-center text-muted-foreground">
            No preview available for this feedback type
          </div>
        );
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Review Feedback</DialogTitle>
        </DialogHeader>

        <div className="py-6">
          {renderContent()}
        </div>

        <DialogFooter className="flex justify-between">
          <Button
            variant="destructive"
            onClick={() => handleStatusUpdate('rejected')}
            disabled={isProcessing}
            className="flex items-center gap-2"
          >
            {isProcessing ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <ThumbsDown className="h-4 w-4" />
            )}
            Reject
          </Button>

          <Button
            onClick={() => handleStatusUpdate('approved')}
            disabled={isProcessing}
            className="flex items-center gap-2"
          >
            {isProcessing ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <ThumbsUp className="h-4 w-4" />
            )}
            Approve
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
