import { Head } from '@inertiajs/react';
import { StaticPage } from "@/components/layouts/StaticPageLayout.tsx";

const TermsPage = () => {
  return (
    <>
      <Head title="Terms and Conditions" />
      <StaticPage title="Terms and Conditions">
        <div className="space-y-6 text-white">
          {/* Introduction */}
          <section className="mb-8">
            <p className="text-white/90 mb-4">
              Welcome to Old World Rankings (the "Service"), operated by Tributech Pty Ltd ("we", "our", or "us").
              By accessing or using Old World Rankings, you agree to be bound by these Terms and Conditions ("Terms").
            </p>
          </section>

          {/* Intellectual Property Notice */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Intellectual Property Notice</h2>
            <p className="text-white/90">
              Warhammer: The Old World, the Warhammer: The Old World logo, and all associated marks, names, races, race insignia,
              characters, vehicles, locations, units, illustrations and images from the Warhammer: The Old World universe are either
              ®, ™ and/or © Games Workshop Limited.
            </p>
            <p className="text-white/90 mt-4">
              Old World Rankings is in no way endorsed by or affiliated with Games Workshop Limited.
              We make use of Games Workshop's intellectual property under fair use provisions for the purpose of
              tournament tracking, rankings, and community engagement.
            </p>
          </section>

          {/* Tournament Platform Data Usage */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Tournament Platform Data Usage</h2>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>We collect publicly available tournament data from various platforms.</li>
              <li>Data collection is performed in accordance with each platform's terms of service.</li>
              <li>We only store and display publicly available information.</li>
              <li>Users are responsible for ensuring any data they submit is accurate and permitted to be shared.</li>
            </ul>
          </section>

          {/* User Agreement & Content */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">User Agreement & Content</h2>
            <h3 className="text-xl text-warhammer-gold mb-2">Account Creation</h3>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>You must provide accurate information when creating an account.</li>
              <li>You are responsible for maintaining the security of your account.</li>
              <li>You must be at least 13 years old to create an account.</li>
            </ul>
            <h3 className="text-xl text-warhammer-gold mt-6 mb-2">User Generated Content</h3>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>You retain ownership of content you submit.</li>
              <li>You grant us a license to use, display, and distribute content you submit.</li>
              <li>You are responsible for ensuring you have the right to share any content.</li>
            </ul>
          </section>

          {/* Privacy & Data */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Privacy & Data</h2>
            <p className="text-white/90">
              We collect and use data in accordance with our Privacy Policy. This includes tournament results, user account information,
              and platform integration data.
            </p>
          </section>

          {/* Service Usage */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Service Usage</h2>
            <p className="text-white/90 mb-4">You agree not to:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>Attempt to disrupt the Service.</li>
              <li>Use automated systems without permission.</li>
              <li>Misrepresent tournament results.</li>
              <li>Harass other users.</li>
            </ul>
          </section>

          {/* Disclaimers & Limitations */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Disclaimers & Limitations</h2>
            <p className="text-white/90">
              THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. We are not responsible for accuracy of third-party data,
              user-submitted content, service interruptions, or any consequential damages.
            </p>
          </section>

          {/* Modifications to Service */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Modifications to Service</h2>
            <p className="text-white/90">
              We reserve the right to modify or discontinue any part of the Service, update these Terms, and change features or functionality.
            </p>
          </section>

          {/* Governing Law */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Governing Law</h2>
            <p className="text-white/90">
              These Terms are governed by the laws of New South Wales, Australia. Any disputes shall be subject to the exclusive jurisdiction
              of the courts of New South Wales, Australia.
            </p>
          </section>

          {/* Contact Information */}
          <section className="bg-black/30 rounded-lg p-6 border border-warhammer-gold/20">
            <h2 className="text-xl font-semibold text-warhammer-gold mb-4">Contact Us</h2>
            <p className="text-white/90">
              Old World Rankings is operated by Tributech Pty Ltd. For support or inquiries, contact us through the chat function on the
              website or email us at <a href="mailto:support@oldworldrankings.com" className="text-warhammer-gold hover:underline">
              support@oldworldrankings.com</a>.
            </p>
          </section>

          <div className="text-sm text-white/60 mt-8">
            Last updated: November 19, 2024
          </div>
        </div>
      </StaticPage>
    </>
  );
};

export default TermsPage;
