import React, { useState, useCallback } from 'react';
import { DirectUpload } from '@rails/activestorage';
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import { Card, CardContent } from "@/components/ui/shadcn/card";
import { ImageIcon, X, Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from "@/components/ui/shadcn/button";
import { useToast } from "@/components/ui/shadcn/hooks/use-toast";

interface ThumbnailState {
  previewUrl: string | null;
  signedId: string | null;
  uploading: boolean;
  error: string | null;
}

interface TournamentThumbnailProps {
  existingThumbnail?: string;
  onThumbnailChange: (signedId: string | null, remove?: boolean) => void;
  onUploadStart?: () => void;
  onUploadComplete?: () => void;
}

export function TournamentThumbnail({
                                      existingThumbnail,
                                      onThumbnailChange,
                                      onUploadStart,
                                      onUploadComplete }: TournamentThumbnailProps) {
  const { toast } = useToast();
  const [thumbnailState, setThumbnailState] = useState<ThumbnailState>({
    previewUrl: existingThumbnail || null,
    signedId: null,
    uploading: false,
    error: null
  });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type and size
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validTypes.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload a JPG or PNG image",
        variant: "destructive",
      });
      return;
    }

    if (file.size > 10 * 1024 * 1024) { // 10MB
      toast({
        title: "File too large",
        description: "Please upload an image under 10MB",
        variant: "destructive",
      });
      return;
    }

    // Create preview
    const previewUrl = URL.createObjectURL(file);
    setThumbnailState(prev => ({
      ...prev,
      previewUrl,
      uploading: true,
      error: null
    }));

    onUploadStart?.();

    try {
      const upload = new DirectUpload(
        file,
        '/rails/active_storage/direct_uploads'
      );

      await new Promise((resolve, reject) => {
        upload.create((error, blob) => {
          if (error) {
            reject(error);
          } else {
            setThumbnailState(prev => ({
              ...prev,
              signedId: blob.signed_id,
              uploading: false
            }));
            onThumbnailChange(blob.signed_id);
            resolve(blob);
          }
        });
      });

      toast({
        title: "Upload successful",
        description: "Thumbnail uploaded successfully",
      });
    } catch (error) {
      console.error('Upload error:', error);
      setThumbnailState(prev => ({
        ...prev,
        error: "Failed to upload image",
        uploading: false
      }));
      toast({
        title: "Upload failed",
        description: "Failed to upload thumbnail",
        variant: "destructive",
      });
    }
    finally {
      onUploadComplete?.();
    }
  };

  const handleRemoveThumbnail = useCallback(() => {
    if (thumbnailState.previewUrl && !existingThumbnail) {
      URL.revokeObjectURL(thumbnailState.previewUrl);
    }
    setThumbnailState({
      previewUrl: null,
      signedId: null,
      uploading: false,
      error: null
    });
    onThumbnailChange(null, true);
  }, [thumbnailState.previewUrl, existingThumbnail, onThumbnailChange]);

  return (
    <Card>
      <CardContent className="pt-6">
        <div>
          <Label>Tournament Thumbnail</Label>
          <div className="mt-2">
            {thumbnailState.previewUrl ? (
              <div className="relative">
                <img
                  src={thumbnailState.previewUrl}
                  alt="Tournament thumbnail preview"
                  className="w-full h-[200px] object-cover rounded-lg"
                />
                <Button
                  variant="destructive"
                  size="icon"
                  className="absolute top-2 right-2"
                  onClick={handleRemoveThumbnail}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <div className="relative">
                <Input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={handleFileChange}
                  className="hidden"
                  id="thumbnail-upload"
                  disabled={thumbnailState.uploading}
                />
                <Label
                  htmlFor="thumbnail-upload"
                  className={cn(
                    "cursor-pointer block w-full h-[200px]",
                    "rounded-lg border-2 border-dashed",
                    "flex flex-col items-center justify-center",
                    "hover:border-primary transition-colors",
                    thumbnailState.uploading && "opacity-50 cursor-not-allowed"
                  )}
                >
                  {thumbnailState.uploading ? (
                    <>
                      <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                      <p className="mt-2 text-sm text-muted-foreground">Uploading...</p>
                    </>
                  ) : (
                    <>
                      <ImageIcon className="h-8 w-8 text-muted-foreground" />
                      <p className="mt-2 text-sm text-muted-foreground">
                        Click to upload tournament thumbnail
                      </p>
                      <p className="mt-1 text-xs text-muted-foreground">
                        PNG, JPG up to 10MB
                      </p>
                    </>
                  )}
                </Label>
              </div>
            )}
            {thumbnailState.error && (
              <p className="mt-2 text-sm text-destructive">
                {thumbnailState.error}
              </p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
