import React from 'react';
import LandingPage from "@/components/ui/landing/LandingPage.tsx";

const Show = () => {
  return (
    <LandingPage />
  );
};

export default Show;
