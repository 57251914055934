import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import { Switch } from "@/components/ui/shadcn/switch";
import { Label } from "@/components/ui/shadcn/label";

const FactionWinRateChart = ({ factionStats, factions }) => {
  const [showMinAppearances, setShowMinAppearances] = useState(true);
  const MIN_APPEARANCES = 10;

  const processedData = useMemo(() => {
    // Get all factions (both parent and subfactions)
    const allFactions = factions.map(faction => {
      const stats = factionStats.find(s => s.name === faction.name);
      if (!stats) return null;

      return {
        id: faction.id,
        name: faction.name,
        parentId: faction.parent_id,
        count: stats.count,
        winRate: stats.winRate,
        color: faction.color
      };
    }).filter(Boolean);

    // Filter and sort the data
    return allFactions
      .filter(faction => !showMinAppearances || faction.count >= MIN_APPEARANCES)
      .sort((a, b) => b.winRate - a.winRate)
      .map(faction => ({
        ...faction,
        formattedWinRate: Math.round(faction.winRate * 10) / 10
      }));
  }, [factionStats, factions, showMinAppearances]);

  const getWinRateColor = (winRate) => {
    if (winRate >= 45 && winRate <= 55) return '#4ade80';
    return '#ef4444';
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;

    const data = payload[0].payload;

    return (
      <div className="bg-background border border-border rounded-lg shadow-lg p-3">
        <p className="font-semibold mb-2">{data.name}</p>
        <div className="space-y-1">
          <p className="text-sm">
            Win Rate: <span className="font-medium">{data.formattedWinRate}%</span>
          </p>
          <p className="text-sm">
            Appearances: <span className="font-medium">{data.count}</span>
          </p>
          <p className="text-sm text-muted-foreground">
            {data.formattedWinRate >= 45 && data.formattedWinRate <= 55
              ? "Within balanced range (45-55%)"
              : "Outside balanced range"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="p-4 sm:p-6">
        <CardTitle>Faction Win Rates</CardTitle>
      </CardHeader>
      <CardContent className="p-2 sm:p-6">
        <div className="h-[600px] -ml-2 sm:ml-0">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={processedData}
              layout="vertical"
              margin={{
                top: 0,
                right: 16,
                left: 60,
                bottom: 20
              }}
            >
              <XAxis
                type="number"
                domain={[0, 100]}
                tickFormatter={(value) => `${value}%`}
                fontSize={12}
              />
              <YAxis
                dataKey="name"
                type="category"
                width={60}
                interval={0}
                tick={{ fontSize: 11 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="winRate">
                {processedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getWinRateColor(entry.winRate)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="mt-4 space-y-4">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex flex-wrap gap-4 text-sm text-muted-foreground">
              <div className="flex items-center gap-2">
                <span className="inline-block w-3 h-3 bg-green-400"></span>
                <span>45-55% win rate</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="inline-block w-3 h-3 bg-red-500"></span>
                <span>&lt;45% or &gt;55% win rate</span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                checked={showMinAppearances}
                onCheckedChange={setShowMinAppearances}
                aria-label="Toggle minimum appearances filter"
              />
              <Label className="text-sm text-muted-foreground">
                {MIN_APPEARANCES}+ appearances
              </Label>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FactionWinRateChart;
