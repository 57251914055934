import React from 'react';
import { User, Settings, LogOut } from 'lucide-react';
import { Link, usePage, router } from "@inertiajs/react";
import { cn } from "@/lib/utils";
import ProfileAvatar from "@/components/layouts/ProfileAvatar.tsx";
import {Button} from "@/components/ui/controls/Button.tsx";

interface ProfileButtonsProps {
  isCollapsed: boolean;
}

function ProfileButtons({ isCollapsed }: ProfileButtonsProps) {
  const { current_user } = usePage().props;

  const handleLogout = () => {
    router.delete('/logout', {
      preserveState: false,
      preserveScroll: false,
    });
  };

  return (
    <div className="">
      {current_user ? (
        <>
          <div className={cn("space-y-1 pt-2", isCollapsed && "items-center")}>
            <Link
              href="/profile"
              className={cn(
                "flex items-center w-full px-4 py-2 text-warhammer-gold hover:text-white hover:bg-warhammer-red-dark/30 transition-colors duration-200",
                isCollapsed ? "justify-center" : "justify-start"
              )}
            >
              <Settings className={cn("h-5 w-5", isCollapsed ? "mx-auto" : "mr-2")} />
              {!isCollapsed && <span>Settings</span>}
            </Link>
            <button
              onClick={handleLogout}
              className={cn(
                "flex items-center w-full px-4 py-2 text-warhammer-gold hover:text-white hover:bg-warhammer-red-dark/30 transition-colors duration-200",
                isCollapsed ? "justify-center" : "justify-start"
              )}
            >
              <LogOut className={cn("h-5 w-5", isCollapsed ? "mx-auto" : "mr-2")} />
              {!isCollapsed && <span>Logout</span>}
            </button>
            <ProfileAvatar isCollapsed={isCollapsed} />
          </div>
        </>
      ) : (
        <div className="p-4 w-full">
        `<Link href="/login">
          <Button
            className={cn(
              "w-full bg-warhammer-gold text-warhammer-black",
              "hover:bg-warhammer-gold-dark transition-colors duration-200"
            )}
          >
            {!isCollapsed ? (
              <div className="flex items-center justify-center gap-2">
                <User className="h-5 w-5" />
                <span>Login/Signup</span>
              </div>
            ) : (
              <User className="h-5 w-5" />
            )}
          </Button>
        </Link>
        </div>
      )}
    </div>
  );
}

export default ProfileButtons;
