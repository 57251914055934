import { useState } from 'react';
import { Button } from "@/components/ui/controls/Button";
import { RefreshCw } from 'lucide-react';
import TournamentResyncDialog from '../TournamentResyncDialog';

interface ResyncButtonProps {
  platformId: string;
  tournamentId: string;
  platformName: string;
  disabled?: boolean;
}

export const ResyncButton = ({
                               platformId,
                               tournamentId,
                               platformName,
                               disabled = false
                             }: ResyncButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsDialogOpen(true)}
        disabled={disabled}
        variant="secondary"
        className="flex items-center"
      >
        <RefreshCw className="mr-2 h-4 w-4" />
        Resync {platformName}
      </Button>

      <TournamentResyncDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        platformId={platformId}
        tournamentId={tournamentId}
        platformName={platformName}
      />
    </>
  );
};
