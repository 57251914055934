import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Label } from "@/components/ui/shadcn/label";
import { Switch } from "@/components/ui/shadcn/switch";
import {NotificationSetting} from "@/components/ui/profile/Types.ts";

const NOTIFICATION_SETTINGS: NotificationSetting[] = [
  {
    id: 'new_tournaments',
    title: 'New Tournament Notifications',
    description: 'Get notified when new tournaments are added in your region'
  },
  {
    id: 'ranking_changes',
    title: 'Ranking Updates',
    description: 'Receive notifications about changes to your ranking position'
  },
  {
    id: 'followed_players',
    title: 'Player Updates',
    description: 'Get notified about tournament results from players you follow'
  },
  {
    id: 'site_updates',
    title: 'Site Updates',
    description: 'Subscribe to updates and announcements from Old World Rankings'
  },
];

const INFORMATIONAL_MESSAGE = "This feature is currently in development and will be available soon.";

export const NotificationCard = ({ notifications, onUpdate }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Notification Preferences</CardTitle>
        <CardDescription>
          Manage how you want to be notified about updates
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {NOTIFICATION_SETTINGS.map(setting => (
            <div key={setting.id} className="flex items-start space-x-4">
              <Switch
                id={setting.id}
                checked={notifications[setting.id]}
                onCheckedChange={(checked) => onUpdate(setting.id, checked)}
              />
              <div className="flex-1 space-y-1">
                <Label
                  htmlFor={setting.id}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {setting.title}
                </Label>
                <p className="text-sm text-muted-foreground">
                  {setting.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-warhammer-gold p-4 mt-4 rounded-lg">
          <p className="text-sm text-foreground">{INFORMATIONAL_MESSAGE}</p>
        </div>
      </CardContent>
    </Card>
  );
};
