import React, {useState} from 'react';
import { DataTable } from "@/components/ui/controls/tables/DataTable";
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  SortingState
} from '@tanstack/react-table';
import { createColumns, type FeedbackItem } from "./FeedbackTableColumns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import FeedbackReviewDialog from "@/components/ui/feedback/FeedbackReviewDialog";
import {Feedback} from "@/components/ui/feedback/Types.ts";
import ReviewStatusBadge from "@/components/ui/feedback/ReviewStatusBadge.tsx";

interface FeedbackDataTableProps {
  data: FeedbackItem[];
  onView: (feedback: FeedbackItem) => void;
}

export default function FeedbackDataTable({ data, onView }: FeedbackDataTableProps) {
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'date', desc: true }
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    {
      id: 'status',
      value: 'open'
    }
  ]);  const columns = createColumns((feedback) => {
    setSelectedFeedback(feedback);
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: {
      sorting,
      columnFilters,
    },
    meta: {
      onView
    }
  });

  const handleStatusFilter = (value: string) => {
    if (value === 'all') {
      table.getColumn('status')?.setFilterValue(undefined);
    } else {
      table.getColumn('status')?.setFilterValue(value);
    }
  };

  return (
    <div className="space-y-4 pt-6">
      <div className="flex items-center gap-4">
        <span>Filter by:</span>
        <Select
          defaultValue="open"
          onValueChange={handleStatusFilter}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Status</SelectItem>
            <SelectItem value="open"><ReviewStatusBadge status="open" /></SelectItem>
            <SelectItem value="approved"><ReviewStatusBadge status="approved" /></SelectItem>
            <SelectItem value="rejected"><ReviewStatusBadge status="rejected" /></SelectItem>
            <SelectItem value="archived"><ReviewStatusBadge status="archived" /></SelectItem>
          </SelectContent>
        </Select>
      </div>

      <DataTable
        columns={columns}
        table={table}
      />

      {selectedFeedback && (
        <FeedbackReviewDialog
          isOpen={!!selectedFeedback}
          onClose={() => setSelectedFeedback(null)}
          feedback={selectedFeedback}
        />
      )}
    </div>
  );
}
