import React from 'react';
import { ExternalLink } from 'lucide-react';
import { cn } from "@/lib/utils";

interface BuyMeCoffeeButtonProps {
  className?: string;
}

const BuyMeCoffee = ({ className }: BuyMeCoffeeButtonProps) => {
  return (
    <a
      href={'https://buymeacoffee.com/gommo'}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(
        "inline-flex items-center gap-2 rounded-lg px-3 py-1.5",
        "bg-[#FFDD00] hover:bg-[#FFDD00]/90",
        "text-black font-semibold text-sm",
        "transition-colors duration-200",
        "shadow-sm hover:shadow",
        className
      )}
    >
      <img
        src='https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg'
        alt="Buy Me A Coffee"
        className="h-6 w-6"
      />
      <span className="hidden md:inline">Buy me a coffee</span>
    </a>
  );
};

export default BuyMeCoffee;
