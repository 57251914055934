import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/shadcn/select";

interface Faction {
  id: string;
  name: string;
  is_subfaction: boolean;
  parent_id: string | null;
}

interface FactionSelectProps {
  factions: Faction[];
  selectedFactionId: string | null;
  onSelect: (factionId: string | null) => void;
}

export function FactionSelect({
                                factions,
                                selectedFactionId,
                                onSelect,
                              }: FactionSelectProps) {
  const handleSelect = (value: string) => {
    onSelect(value === "null" ? null : value);
  };

  return (
    <Select value={selectedFactionId || "null"} onValueChange={handleSelect}>
      <SelectTrigger>
        <SelectValue placeholder="Select a faction" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="null" className="text-muted-foreground">
          No Faction
        </SelectItem>
        {factions.map((faction) => (
          <SelectItem
            key={faction.id}
            value={faction.id}
            className={faction.is_subfaction ? 'pl-10' : ''}
          >
            {faction.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
