import React from 'react';
import { Card, CardContent } from "@/components/ui/shadcn/card";
import { Link as LinkIcon } from "lucide-react";
import { SocialIcon } from 'react-social-icons';

interface TournamentUrlCardProps {
  url: string;
}
function TournamentUrlCard({ url }: TournamentUrlCardProps) {
  const getSocialIcon = (url: string) => {
    try {
      return <SocialIcon url={url} style={{ height: 25, width: 25 }} />;
    } catch {
      return <LinkIcon className="h-5 w-5" />;
    }
  };

  return (
    <Card className="w-auto">
      <CardContent className="p-4">
        <p className="text-sm font-medium text-gray-500">Tournament URL</p>
        <div className="flex items-center space-x-2">
          {getSocialIcon(url)}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-semibold text-blue-600 hover:underline"
          >
            Visit Site
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

export default TournamentUrlCard;
