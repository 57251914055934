import React from 'react';
import { useTheme } from '@/components/ThemeProvider';
import { Button } from '@/components/ui/shadcn/button';
import {Moon, Sun, Menu, Heart, Sparkles, Trophy} from 'lucide-react';
import RegionSelector from "@/components/ui/RegionSelector";
import owfLogoWhite from '@/assets/images/owr_logo_white.png';
import paypalLogo from '@/assets/images/paypal_logo.png';
import {Link, usePage} from '@inertiajs/react';
import UniversalSearch from "@/components/ui/search/UniversalSearch";
import BuyMeCoffee from "@/components/ui/controls/BuyMeACoffee";
import HeadwayWidget from "@/components/layouts/Headway";
import {getSurveyUrl} from "@/components/ui/dashboard/SurveyPromptDialog.tsx";

interface HeaderProps {
  onOpenSidebar: () => void;
}

function Header({ onOpenSidebar }: HeaderProps) {
  const { theme, setTheme } = useTheme();
  const { front_end_configs, current_region, current_user } = usePage().props;
  const { paypal_link } = front_end_configs;

  return (
    <header className="bg-warhammer-black p-4 flex justify-between items-center">
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          className="lg:hidden mr-2 text-warhammer-gold hover:text-white"
          onClick={onOpenSidebar}
        >
          <Menu className="h-6 w-6"/>
        </Button>
        <Link href={`/`}>
          <img src={owfLogoWhite} alt="OWF Logo" className="h-8 w-8 mr-2"/>
        </Link>
        <div
          className="hidden md:flex items-center bg-yellow-600/20 border border-yellow-600 rounded-full md:mx-3 px-3 py-0.5 shadow-lg">
          <Sparkles className="h-4 w-4 text-yellow-500 animate-pulse mr-1"/>
          <span className="text-yellow-500 font-bold text-sm">BETA</span>
        </div>
        <div className="flex-1 lg:w-96 mx-4">
          <UniversalSearch/>
        </div>
      </div>
      <div className="flex items-center space-x-4">

        {current_user && current_region.code === 'AU' ? (
          // Show Survey button for logged-in users in AU region
          <Button
            variant="outline"
            size="sm"
            className="hidden md:flex font-bold rounded-md bg-warhammer-gold text-warhammer-black hover:bg-yellow-400 border-2 border-yellow-600 hover:border-yellow-500 transition-colors duration-200"
            onClick={() => window.open(getSurveyUrl(current_user.email), '_blank')}
          >
            <Trophy className="h-4 w-4 mr-2" />
            Take Masters Survey
          </Button>
        ) : (
          // Show PayPal button for others
          <Button
            variant="outline"
            size="sm"
            className="hidden md:flex font-bold rounded-md bg-warhammer-gold text-warhammer-black hover:bg-yellow-400 border-2 border-yellow-600 hover:border-yellow-500 transition-colors duration-200"
            onClick={() => window.open(paypal_link, '_blank')}
          >
            <img src={paypalLogo} alt="PayPal Logo" className="h-4 w-auto mr-2" />
            Donate
          </Button>
        )}

        <BuyMeCoffee />
        <RegionSelector />
        <HeadwayWidget />
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className="text-warhammer-gold hover:text-black"
        >
          {theme === 'dark' ? <Sun className="h-6 w-6" /> : <Moon className="h-6 w-6" />}
        </Button>
      </div>
    </header>
  );
}

export default Header;
