import { Button } from "@/components/ui/shadcn/button";

interface MicrosoftSignInButtonProps {
  onClick?: () => void;
  text?: string;
}

function MicrosoftSignInButton({ text = "Sign in with Microsoft", onClick }: MicrosoftSignInButtonProps) {
  return (
    <Button
      type="button"
      onClick={onClick}
      className="w-full bg-gray-800 hover:bg-gray-900 text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
    >
      <svg className="h-5 w-5 mr-2" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0H0V11H11V0Z" fill="#F25022"/>
        <path d="M23 0H12V11H23V0Z" fill="#7FBA00"/>
        <path d="M11 12H0V23H11V12Z" fill="#00A4EF"/>
        <path d="M23 12H12V23H23V12Z" fill="#FFB900"/>
      </svg>
      {text}
    </Button>
  );
}

export default MicrosoftSignInButton;
