import React, { useState } from 'react';
import {ToastProvider} from "@/components/ui/shadcn/toast.tsx";
import Ga4 from "@/lib/ga.tsx";
import {Toaster} from "@/components/ui/shadcn/toaster.tsx";
import Flash from "@/components/ui/Flash.tsx";
import {ThemeProvider} from "@/components/ThemeProvider.tsx";

interface LayoutProps {
  children: React.ReactNode;
}

const EmptyLayout = ({ children }: LayoutProps) => {

return (
  <div className="flex h-screen bg-background text-foreground">
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-y-auto bg-background">
          <ThemeProvider>
            <ToastProvider>
              <Ga4 />
              {children}
              <Toaster />
              <Flash />
            </ToastProvider>
          </ThemeProvider>
        </main>
      </div>
  </div>
  );
};

export default EmptyLayout;
