import { Link } from '@inertiajs/react';
import { ChevronRight } from 'lucide-react';

interface RegionCardProps {
  region: {
    code: string;
    name: string;
    country_flag: string;
    player_count: number;
    total_tournaments: number;
    coming_soon: boolean;
    slug: string;
  }
}

const RegionCard = ({ region }: RegionCardProps) => {
  return (
    <Link
      key={region.code}
      href={`/${region.slug}`}
      className={`
        group relative overflow-hidden
        bg-black/40 border border-warhammer-gold/20 rounded-lg p-4
        hover:bg-black/60 transition-all
        ${region.coming_soon ? 'cursor-not-allowed opacity-60' : ''}
      `}
    >
      <div className="flex items-center justify-between relative z-10">
        <div>
          <span className="text-2xl mr-2">{region.country_flag}</span>
          <span className="text-white">{region.name}</span>

          {region.coming_soon ? (
            <div className="text-sm text-warhammer-gold">
              Coming Soon
            </div>
          ) : (
            <div className="text-sm space-y-0.5">
              <div className="text-gray-400">
                {region.player_count.toLocaleString()} players
              </div>
              <div className="text-gray-400">
                {region.total_tournaments.toLocaleString()} tournaments
              </div>
            </div>
          )}
        </div>

        <ChevronRight
          className={`
            h-5 w-5
            ${region.coming_soon ? 'text-gray-500' : 'text-warhammer-gold'}
            opacity-0 group-hover:opacity-100 transition-opacity
          `}
        />
      </div>

      {/* Overlay for coming soon regions */}
      {region.coming_soon && (
        <div className="absolute inset-0 bg-black/20" />
      )}
    </Link>
  );
};

export default RegionCard;
