import { router } from '@inertiajs/react';
import { useEffect, useState } from 'react';
import { Table } from '@tanstack/react-table';

export const useFilterParams = <T,>(
  table: Table<T>,
  columnId: string,
  paramName: string
) => {
  const [initialized, setInitialized] = useState(false);

  // On mount, check URL params and set initial filter
  useEffect(() => {
    if (!initialized) {
      const params = new URLSearchParams(window.location.search);
      const filterValue = params.get(paramName);
      if (filterValue) {
        table.getColumn(columnId)?.setFilterValue(filterValue);
      }
      setInitialized(true);
    }
  }, [table, columnId, paramName, initialized]);

  // Update URL when filter changes
  useEffect(() => {
    if (!initialized) return;

    const currentValue = table.getColumn(columnId)?.getFilterValue();
    const params = new URLSearchParams(window.location.search);

    if (currentValue) {
      params.set(paramName, String(currentValue));
    } else {
      params.delete(paramName);
    }

    // Only update if the params have changed
    const newSearch = params.toString();
    const currentSearch = window.location.search.replace(/^\?/, '');

    if (newSearch !== currentSearch) {
      router.get(
        window.location.pathname + (newSearch ? `?${newSearch}` : ''),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          replace: true
        }
      );
    }
  }, [table.getState().columnFilters, initialized]);
};
