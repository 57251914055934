import { Card, CardContent } from "@/components/ui/shadcn/card";
import { Calendar, MapPin, Users, Hash } from 'lucide-react';
import { cn } from "@/lib/utils";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";
import TournamentStatusBadge from "@/components/ui/tournaments/common/TournamentStatusBadge";
import FormattedDate from "@/components/ui/FormattedDate";
import { Tournament } from '../Types';
import { PlatformLink } from "@/components/ui/controls/PlatformLink";
import defaultThumb from "@/assets/images/default-tournament-thumb.png";
import {TournamentPodiumInfo} from "@/components/ui/tournaments/common/TournamentPodiumInfo.tsx";

interface TournamentResult {
  rank: number;
  player: {
    id: string;
    full_name: string;
    nickname?: string;
  };
  wins: number;
  losses: number;
  draws: number;
}

interface TournamentCardProps {
  tournament: Tournament & {
    tournament_results?: TournamentResult[];
  };
  variant: 'default' | 'results';
}

export function TournamentCard({ tournament, variant = 'default' }: TournamentCardProps) {
  const thumbnailUrl = tournament.thumbnail_urls?.card || defaultThumb;
  const registerUrl = tournament.url || tournament.platform_url || tournament.friendly_url;

  const shouldShowStatusBadge = variant === 'results' &&
    (tournament.status === 'in_progress' || tournament.status === 'complete' || tournament.status === 'incomplete');

  return (
    <Card className="w-full transition-all duration-200 hover:shadow-lg">
      <div className="relative">
        <a href={registerUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={thumbnailUrl}
            alt="Tournament thumbnail"
            className={cn(
              "w-full object-cover rounded-t-lg",
              variant === 'results' ? "h-32" : "h-48"
            )}
          />
        </a>
        {shouldShowStatusBadge && (
          <div className="absolute top-4 right-4">
            <TournamentStatusBadge
              status={tournament.status}
              visibility={tournament.visibility}
            />
          </div>
        )}
      </div>

      <CardContent className="p-4 space-y-4">
        <div>
          <div className="flex items-start justify-between mb-2">
            <div className="flex-1 min-w-0 pr-4">
              <TournamentLink tournament={tournament} />
            </div>
            <div className="flex-shrink-0 pl-2">
              <PlatformLink tournament={tournament} noLabel />
            </div>
          </div>
          <div className="flex items-center justify-between text-sm text-muted-foreground">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2" />
              <FormattedDate
                startDate={tournament.start_date}
                endDate={tournament.end_date}
              />
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <Hash className="h-4 w-4 mr-1" />
                {`${tournament.rounds} rounds`}
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-1" />
                {`${tournament.player_cap} cap`}
              </div>
            </div>
          </div>
        </div>

        {variant === 'results' && (
          <TournamentPodiumInfo tournament={tournament} />
        )}

        {variant === 'default' && tournament.address && (
          <div className="flex items-center text-sm text-muted-foreground">
            <MapPin className="h-4 w-4 mr-1" />
            <span>
              {[tournament.address.city, tournament.address.state].filter(Boolean).join(", ")}
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
