export const StorageKeys = {
  LINK_PLAYER_DISMISSED: 'link_player_dismissed_until',
  SURVEY_DISMISSED_GUEST: 'survey_dismissed_until_guest',
  SURVEY_DISMISSED_USER: 'survey_dismissed_until_user'
} as const;

export const Storage = {
  setSurveyDismissed: (isRegistered: boolean) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 3);
    const key = isRegistered ?
      StorageKeys.SURVEY_DISMISSED_USER :
      StorageKeys.SURVEY_DISMISSED_GUEST;
    localStorage.setItem(key, expiryDate.toISOString());
  },

  shouldShowSurvey: (isRegistered: boolean) => {
    const key = isRegistered ?
      StorageKeys.SURVEY_DISMISSED_USER :
      StorageKeys.SURVEY_DISMISSED_GUEST;
    const dismissedUntil = localStorage.getItem(key);
    if (!dismissedUntil) return true;
    const dismissedDate = new Date(dismissedUntil);
    return new Date() > dismissedDate;
  },

  clearSurveyDismissed: () => {
    localStorage.removeItem(StorageKeys.SURVEY_DISMISSED_GUEST);
    localStorage.removeItem(StorageKeys.SURVEY_DISMISSED_USER);
  },

  setLinkPlayerDismissed: () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7); // 7 days from now
    localStorage.setItem(StorageKeys.LINK_PLAYER_DISMISSED, expiryDate.toISOString());
  },

  shouldShowLinkPlayer: () => {
    const dismissedUntil = localStorage.getItem(StorageKeys.LINK_PLAYER_DISMISSED);
    if (!dismissedUntil) return true;

    const dismissedDate = new Date(dismissedUntil);
    return new Date() > dismissedDate;
  },

  clearLinkPlayerDismissed: () => {
    localStorage.removeItem(StorageKeys.LINK_PLAYER_DISMISSED);
  }
};


