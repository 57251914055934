import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { Trophy, AlarmClock } from 'lucide-react';
import {Link, usePage} from "@inertiajs/react";
import { Storage } from "@/lib/storage";

interface SurveyPromptDialogProps {
  isOpen: boolean;
  onClose: () => void;
}


export const getSurveyUrl = (email: string) => {
  return `https://docs.google.com/forms/d/e/1FAIpQLSdJDrKZLKcsSlaKB5Ib_nFqx-t0IyIL9Sz1-DQoVhui1gV7oQ/viewform?usp=pp_url&entry.93247057=${encodeURIComponent(email)}`;
};

const SurveyPromptDialog = ({ isOpen, onClose }: SurveyPromptDialogProps) => {
  const { current_user } = usePage().props;

  const handleDismiss = () => {
    Storage.setSurveyDismissed(!!current_user);
    onClose();
  };

  const handleRegister = () => {
    Storage.clearSurveyDismissed();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <Trophy className="h-6 w-6 text-warhammer-gold" />
            <DialogTitle>Help Shape The Old World Masters</DialogTitle>
          </div>
        </DialogHeader>
        <div className="py-6">
          <p className="text-sm text-muted-foreground mb-6">
            {current_user
              ? "Have your say on how we run our inaugural season of The Old World Masters events in Australia. Your feedback will shape how our tournament circuit runs, how rankings work, and how we'll crown our Australian Masters champion."
              : "Sign up to have your say on how we run our inaugural season of The Old World Masters events in Australia. Your feedback will shape how our tournament circuit runs, how rankings work, and how we'll crown our Australian Masters champion."
            }
          </p>
          <p className="text-sm text-muted-foreground mb-6">
            If you've already filled this in just click ignore for now. If you haven't, please take a few minutes to fill it in.
          </p>

          <div className="space-y-4">
            {current_user ? (
              <a
                href={getSurveyUrl(current_user.email)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
                >
                  Take the Survey
                </Button>
              </a>
            ) : (
              <Link href="/register">
                <Button
                  className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
                  onClick={handleRegister}
                >
                  Register & Take Survey
                </Button>
              </Link>
            )}

            <Button
              variant="outline"
              onClick={handleDismiss}
              className="w-full"
            >
              <AlarmClock className="h-4 w-4 mr-2" />
              Ignore for Now
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};


export default SurveyPromptDialog;
