import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatNameToAcronyms(name: string, shorten: boolean = false): string {
  if (!shorten) return name;

  return name
    .split(' ')
    .filter(word => word !== word.toLowerCase()) // Remove words that are all lowercase
    .join(' ')
    .replace(/[^a-zA-Z\s]/g, ''); // Remove special characters
};
