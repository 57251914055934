import {useEffect, useMemo, useState} from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import { Table } from '@tanstack/react-table';
import { Tournament } from '@/types/Tournament.types';

interface StateFilterProps {
  table: Table<Tournament>;
  data: Tournament[];
}

export function StateFilter({ table, data }: StateFilterProps) {
  const initialState = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('state') || 'all';
  }, []);
  const [currentFilter, setCurrentFilter] = useState<string>(initialState);

  // Memoize unique states to prevent unnecessary recalculation
  const states = useMemo(() => {
    const uniqueStates = new Set<string>();
    data.forEach(tournament => {
      if (tournament.address?.state) {
        uniqueStates.add(tournament.address.state);
      }
    });
    return Array.from(uniqueStates).sort();
  }, [data]);

  useEffect(() => {
    if (initialState !== 'all') {
      table.getColumn('location')?.setFilterValue(initialState);
    }
  }, [initialState, table]);

  const handleStateFilter = (value: string) => {
    setCurrentFilter(value);
    if (value === "all") {
      table.getColumn('location')?.setFilterValue(undefined);
    } else {
      table.getColumn('location')?.setFilterValue(value);
    }
  };

  return (
    <Select
      value={currentFilter}
      onValueChange={handleStateFilter}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Filter by state" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All states</SelectItem>
        {states.map((state) => (
          <SelectItem key={state} value={state}>
            {state}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
