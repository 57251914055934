import React from 'react';
import {Link, usePage} from '@inertiajs/react';
import { cn } from "@/lib/utils";
import BackgroundSlider from '@/components/ui/landing/BackgroundSlider';
import LandingFooter from '@/components/ui/landing/LandingPageFooter';
import { ToastProvider } from "@/components/ui/shadcn/toast.tsx";
import { Toaster } from "@/components/ui/shadcn/toaster.tsx";
import Flash from "@/components/ui/Flash.tsx";
import Ga4 from "@/lib/ga";
import SentryInit from "@/lib/SentryInit.tsx";
import { ThemeProvider } from "@/components/ThemeProvider.tsx";
import wtowLogo from '@/assets/images/wtow_logo.png';

interface StaticPageLayoutProps {
  children: React.ReactNode;
  hideHome?: boolean;
}

export const StaticPageLayout = ({ children, hideHome }: StaticPageLayoutProps) => {
  const { current_user, regions } = usePage<PageProps>().props;

  // Find the user's last region from the regions array, default to US
  const userRegion = current_user?.last_region
    ? regions.find(r => r.code === current_user.last_region)
    : regions.find(r => r.code === 'US');

  const renderAuthButton = () => {
    if (current_user && userRegion) {
      return (
        <Link
          href={`/${userRegion.slug}/dashboard`}
          className="bg-warhammer-gold text-black px-4 py-2 rounded-md hover:bg-warhammer-gold-dark transition-colors flex items-center gap-2"
        >
          <span className="text-xl">{userRegion.country_flag}</span>
          <span>Dashboard</span>
        </Link>
      );
    }

    return (
      <Link
        href="/login"
        className="bg-warhammer-gold text-black px-4 py-2 rounded-md hover:bg-warhammer-gold-dark transition-colors"
      >
        Login
      </Link>
    );
  };

  return (
    <div className="min-h-screen bg-background text-foreground">
      <ThemeProvider>
        <ToastProvider>
          <SentryInit />
          <Ga4 />
          <div className="flex min-h-screen flex-col relative">
            {/* Background Slider */}
            <div className="fixed inset-0 z-0">
              <BackgroundSlider />
            </div>

            {/* Top Navigation */}
            <div className="fixed top-0 w-full z-30 px-6 py-4 bg-gradient-to-b from-black/80 to-transparent">
              <nav className="max-w-7xl mx-auto flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <Link href="/" className="flex items-center">
                    <img src={wtowLogo} alt="WTOW Logo" className="h-8" />
                  </Link>
                  {!hideHome && (
                    <Link href="/" className="text-white hover:text-warhammer-gold transition-colors">
                      Home
                    </Link>
                  )}
                </div>
                <div className="flex items-center gap-4">
                  <Link href="/pricing" className="text-white hover:text-warhammer-gold transition-colors">
                    Pricing
                  </Link>
                  {renderAuthButton()}
                </div>
              </nav>
            </div>

            {/* Main Content */}
            <main className={cn(
              "flex-grow flex flex-col relative z-10 px-4",
              "pt-24",
              "pb-24"
            )}>
              <div className="max-w-4xl mx-auto w-full">
                {children}
              </div>
            </main>

            <LandingFooter className="fixed bottom-0 left-0 right-0" />
          </div>
          <Toaster />
          <Flash />
        </ToastProvider>
      </ThemeProvider>
    </div>
  );
};

export const StaticPage = ({
                             title,
                             children,
                             className
                           }: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn(
      "bg-black/70 backdrop-blur-sm rounded-lg p-8 shadow-lg",
      "border border-warhammer-gold/20",
      className
    )}>
      <h1 className="text-3xl font-bold text-warhammer-gold mb-8">{title}</h1>
      <div className="prose prose-xl dark:prose-invert max-w-none">
        {children}
      </div>
    </div>
  );
};

export default StaticPageLayout;
