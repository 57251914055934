import { useState } from 'react';
import { usePage } from '@inertiajs/react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";

interface Region {
  code: string;
  name: string;
  country_flag: string;
  slug: string;
  coming_soon: boolean;
}

interface PageProps {
  current_region: Region;
  regions: Region[];
}

const RegionSelector = () => {
  const { current_user, current_region, regions } = usePage<PageProps>().props;
  const [isOpen, setIsOpen] = useState(false);

  const getRedirectPath = (newRegionSlug: string) => {
    const currentPath = window.location.pathname;

    // Global routes that should be preserved as-is
    const globalRoutes = ['/players', '/profile', '/pricing', '/terms', '/privacy', '/admin/stats'];
    if (globalRoutes.some(route => currentPath.startsWith(route))) {
      return `${currentPath}?region=${newRegionSlug}`;
    }

    // Player profile routes should be preserved
    if (currentPath.startsWith('/@')) {
      return `${currentPath}?region=${newRegionSlug}`;
    }

    // Region-scoped routes that should be preserved with new region
    const currentPathWithoutRegion = currentPath.replace(`/${current_region.slug}`, '');
    const regionScopedRoutes = ['/rankings', '/tournaments', '/tournaments/upcoming', '/tournaments/latest'];
    if (regionScopedRoutes.some(route => currentPathWithoutRegion === route)) {
      return `/${newRegionSlug}${currentPathWithoutRegion}`;
    }

    // Default to region root
    return `/${newRegionSlug}`;
  };

  const handleRegionChange = (value: string) => {
    window.location.href = getRedirectPath(value);
  };

  const availableRegions = current_user?.is_super_admin
    ? regions
    : regions.filter(region => !region.coming_soon);

  const sortedRegions = [...availableRegions].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div className="relative">
      <Select
        defaultValue={current_region.slug}
        onValueChange={handleRegionChange}
        onOpenChange={(open) => setIsOpen(open)}
      >
        <SelectTrigger className="w-[60px] md:w-[180px] text-warhammer-gold whitespace-nowrap">
          <SelectValue placeholder="Select a region">
            <span className="flex items-center min-w-0">
              <span className="mr-2 text-2xl">{current_region.country_flag}</span>
              <span className={`text-foreground truncate ${isOpen ? '' : 'hidden md:inline'}`}>
                {current_region.name}
              </span>
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {sortedRegions.map((region) => (
            <SelectItem
              key={region.code}
              value={region.slug}
              className="min-w-[180px]"
            >
              <span className="flex items-center whitespace-nowrap cursor-pointer">
                <span className="mr-2 text-2xl">{region.country_flag}</span>
                <span className="text-foreground">{region.name}</span>
              </span>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default RegionSelector;
