import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/shadcn/table";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import FormattedDate from "@/components/ui/FormattedDate";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/shadcn/tooltip";
import { usePage } from '@inertiajs/react';
import ArmyListButton from "@/components/ui/tournaments/common/ArmyListViewButton";

interface TournamentResult {
  id: number;
  rank: number;
  tournament: {
    id: number;
    name: string;
    start_date: string;
    end_date?: string;
    url: string;
    region: {
      code: string;
      country_flag: string;
      name: string;
    };
  };
  player: {
    id: string;
    full_name: string;
    nickname?: string;
  };
  faction?: {
    name: string;
  };
  wins: number;
  losses: number;
  draws: number;
  ranking_points: number;
  has_list?: boolean;
}

interface PlayerTournamentResultsTableProps {
  results: TournamentResult[];
}

export function PlayerTournamentResultsTableCard({ results }: PlayerTournamentResultsTableProps) {
  const { current_region } = usePage<PageProps>().props;

  const getRankEmoji = (rank: number) => {
    if (rank === 1) return '🏆';
    if (rank === 2) return '🥈';
    if (rank === 3) return '🥉';
    return `${rank}`;
  };

  const renderTournamentNameCell = (result: TournamentResult) => {
    const isCrossRegion = result.tournament.region.code !== current_region.code;
    return (
      <div className="flex items-center gap-2">
        <TournamentLink tournament={result.tournament} />
        {isCrossRegion && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-lg" title={`${result.tournament.region.name} Tournament`}>
                  {result.tournament.region.country_flag}
                </span>
              </TooltipTrigger>
              <TooltipContent>
                <p>{result.tournament.region.name} Tournament</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Tournament History</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Tournament</TableHead>
              <TableHead>Faction</TableHead>
              <TableHead>List</TableHead>
              <TableHead className="text-right">Rank</TableHead>
              <TableHead className="text-right">W/L/D</TableHead>
              <TableHead className="text-right">Points</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {results.map((result) => (
              <TableRow key={result.id}>
                <TableCell>
                  <FormattedDate
                    startDate={result.tournament.start_date}
                    endDate={result.tournament.end_date}
                  />
                </TableCell>
                <TableCell>{renderTournamentNameCell(result)}</TableCell>
                <TableCell>{result.faction?.name || 'Unknown'}</TableCell>
                <TableCell>
                  <ArmyListButton
                    tournament={result.tournament}
                    result={result}
                  />
                </TableCell>
                <TableCell className="text-right">{getRankEmoji(result.rank)}</TableCell>
                <TableCell className="text-right font-mono">
                  <span className="text-green-600">{result.wins}</span>/
                  <span className="text-red-600">{result.losses}</span>/
                  <span className="text-orange-600">{result.draws}</span>
                </TableCell>
                <TableCell className="text-right font-mono">
                  {result.ranking_points}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
