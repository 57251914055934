import React, {useState} from 'react';
import { DataTable } from "@/components/ui/controls/tables/DataTable";
import { columns } from "./TournamentTableColumns";
import { Tournament } from "@/types/Tournament.types";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useCombinedFilters } from '../common/useCombinedFilters';
import { TournamentFilterControls } from '../common/TournamentFilterControls';

export default function TournamentDataTable({ data }: { data: Tournament[] }) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useCombinedFilters(table, [
    { columnId: 'location', paramName: 'state' },
    { columnId: 'rounds', paramName: 'type' }
  ]);

  const getRowClassName = (row: Tournament) => {
    return row.status === 'upcoming' ? "bg-muted/50 dark:bg-muted/20" : "";
  };

  const showDivider = (currentRow: Tournament, previousRow?: Tournament) => {
    return !!previousRow && previousRow.status === 'upcoming' && currentRow.status !== 'upcoming';
  };

  return (
    <div className="space-y-4">
      <TournamentFilterControls table={table} data={data} />
      <DataTable
        columns={columns}
        getRowClassName={getRowClassName}
        showDivider={showDivider}
        table={table}
      />
    </div>
  );
}
