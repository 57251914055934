import { Link, usePage } from '@inertiajs/react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import RankingsTable from "@/components/ui/rankings/RankingsTable";

interface TopPlayersCardProps {
  rankings: any[];
}

const TopPlayersCard = ({ rankings }: TopPlayersCardProps) => {
  const { current_region } = usePage<PageProps>().props;

  return (
    <Card>
      <CardHeader>
        <Link href={`/${current_region.slug}/rankings`} className="text-xl font-semibold">
          <h2 className="text-2xl font-semibold">Top Regional Players {`${current_region.country_flag}`} </h2>
        </Link>
      </CardHeader>
      <CardContent>
        <RankingsTable rankings={rankings} showDetailedView={true} />
      </CardContent>
    </Card>
  );
};

export default TopPlayersCard;
