import { StateFilter } from "@/components/ui/tournaments/index/StateFilter";
import { RoundsFilter } from "@/components/ui/tournaments/index/RoundsFilter";
import { ReportButton } from "@/components/ui/tournaments/common/ReportButton";
import { Table } from '@tanstack/react-table';
import { Tournament } from "@/types/Tournament.types";

interface TournamentFilterControlsProps {
  table: Table<Tournament>;
  data: Tournament[];
}

export function TournamentFilterControls({ table, data }: TournamentFilterControlsProps) {
  return (
    <div className="flex items-center gap-4 pb-4">
      <span>Filter by: </span>
      <StateFilter table={table} data={data} />
      <RoundsFilter table={table} />
      <ReportButton variant="submit-event" />
    </div>
  );
}
