import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/shadcn/button";
import {cn} from "@/lib/utils.ts";
import {Trash2} from "lucide-react";

interface DeleteButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onDelete: () => void;
  initialText?: string;
  confirmText?: string;
}

const DeleteButton = ({
                        onDelete,
                        initialText = "Delete",
                        confirmText = "Are you sure?",
                        className,
                        ...props
                      }: DeleteButtonProps) => {
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    if (isConfirming) {
      const timer = setTimeout(() => setIsConfirming(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isConfirming]);

  const handleClick = () => {
    if (isConfirming) {
      onDelete();
      setIsConfirming(false);
    } else {
      setIsConfirming(true);
    }
  };

  return (
    <Button
      type="button"
      variant={isConfirming ? "destructive" : "ghost"}
      onClick={handleClick}
      className={cn(
        "transition-colors",
        isConfirming ? "bg-red-600 hover:bg-red-700" : "text-red-500 hover:text-red-700 hover:bg-red-100",
        className
      )}
      {...props}
    >
      <Trash2 className="w-4 h-4 mr-2" />
      {isConfirming ? confirmText : <span className="hidden md:inline">{initialText}</span>}
    </Button>
  );
};

export default DeleteButton;
