import React, { useState, useEffect } from 'react';

// Dynamic import of all images from the landing directory
const images = import.meta.glob('@/assets/images/landing/*.jpg', { eager: true });
const IMAGE_URLS = Object.values(images)
  .map(module => module.default)
  .sort(() => Math.random() - 0.5);

const DISPLAY_DURATION = 5000;    // How long to display each image fully
const FADE_DURATION = 3000;       // Duration of fade transition (2 seconds)

const BackgroundSlider = () => {
  const [displayIndex, setDisplayIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);

      const next = (displayIndex + 1) % IMAGE_URLS.length;
      setNextIndex(next);

      const timeoutId = setTimeout(() => {
        setDisplayIndex(next);
        setIsTransitioning(false);
      }, FADE_DURATION);

      return () => clearTimeout(timeoutId);
    }, DISPLAY_DURATION);

    return () => clearInterval(intervalId);
  }, [displayIndex]);

  return (
    <div className="relative w-full h-screen overflow-hidden bg-black">
      {/* Bottom layer - next image */}
      <div className="absolute inset-0">
        <img
          src={IMAGE_URLS[nextIndex]}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      {/* Top layer - current image */}
      <div
        style={{
          transition: `opacity ${FADE_DURATION}ms ease-in-out`
        }}
        className={`
          absolute inset-0
          ${isTransitioning ? 'opacity-0' : 'opacity-100'}
        `}
      >
        <img
          src={IMAGE_URLS[displayIndex]}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/85" />

    </div>
  );
};

export default BackgroundSlider;


