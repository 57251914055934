import { Link } from "@inertiajs/react";

interface Player {
  id: number;
  full_name: string;
  nickname?: string;
  friendly_url: string;
  home_region: Region;
}

interface PlayerNameLinkProps {
  player: Player;
  link?: boolean;
  showRegion?: boolean;
}

const PlayerNameLink = ({ player, link = true, showRegion = false  }: PlayerNameLinkProps) => {
  const nameContent = (
    <span className="inline-flex items-center">
      {showRegion && player.home_region && (
        <span className="mr-1.5 text-lg" title={`${player.home_region.code} Region`}>
          {player.home_region.country_flag}
        </span>
      )}
      <span>
        {player.full_name}
        {player.nickname && player.nickname !== player.full_name && (
          <span className="text-muted-foreground ml-1 text-xs">
            ({player.nickname})
          </span>
        )}
      </span>
    </span>
  );

  if (link) {
    return (
      <Link href={`${player.friendly_url}`} className="text-primary hover:underline">
        {nameContent}
      </Link>
    )
  }

  return <span>{nameContent}</span>;
};

export default PlayerNameLink;
