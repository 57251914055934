import { usePage } from '@inertiajs/react';
import RegionCard from './RegionCard';

interface Region {
  code: string;
  name: string;
  country_flag: string;
  player_count: number;
  coming_soon: boolean;
  slug: string;
}

interface PageProps {
  regions: Region[];
}

export const RegionsSection = ({ regions } : PageProps) => {
  // Sort regions so that active regions appear first
  const sortedRegions = [...regions].sort((a, b) => {
    if (a.coming_soon === b.coming_soon) {
      return a.name.localeCompare(b.name);
    }
    return a.coming_soon ? 1 : -1;
  });

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-white text-xl mb-4 text-center">Regions and Leagues</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {sortedRegions.map((region) => (
          <RegionCard key={region.code} region={region} />
        ))}
      </div>
    </div>
  );
};
