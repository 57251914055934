import React from 'react';
import { useForm, Head } from '@inertiajs/react';
import { Button } from "@/components/ui/shadcn/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import owfLogoWhite from '@/assets/images/owf_logo_white.png';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

interface Props {
  reset_password_token: string;
  errors?: {
    password?: string[];
    password_confirmation?: string[];
  };
}

const ResetPasswordConfirm = ({ reset_password_token, errors = {} }: Props) => {
  const { data, setData, put, processing, transform } = useForm<ResetPasswordFormData>({
    password: '',
    password_confirmation: '',
    reset_password_token,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    transform((data) => ({
      user: { ...data }
    }));

    put('/password');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-warhammer-red via-warhammer-red-dark to-warhammer-black p-4">
      <Head title="Reset Password" />

      <Card className="w-full max-w-md bg-warhammer-black border-warhammer-gold">
        <CardHeader className="relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img src={owfLogoWhite} alt="OWF Logo" className="h-16 w-16" />
          </div>
          <CardTitle className="text-2xl text-warhammer-gold mt-8">Set New Password</CardTitle>
          <CardDescription className="text-warhammer-silver">
            Please enter your new password below
          </CardDescription>
        </CardHeader>

        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="password" className="text-warhammer-gold">New Password</Label>
              <Input
                id="password"
                type="password"
                value={data.password}
                onChange={e => setData('password', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
                autoComplete="new-password"
              />
              {errors.password && (
                <p className="text-sm text-destructive">{errors.password}</p>
              )}
              <p className="text-xs text-muted-foreground">
                Minimum 6 characters
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="password_confirmation" className="text-warhammer-gold">
                Confirm New Password
              </Label>
              <Input
                id="password_confirmation"
                type="password"
                value={data.password_confirmation}
                onChange={e => setData('password_confirmation', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
                autoComplete="new-password"
              />
              {errors.password_confirmation && (
                <p className="text-sm text-destructive">{errors.password_confirmation}</p>
              )}
            </div>

            <Button
              type="submit"
              className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
              disabled={processing}
            >
              {processing ? 'Setting Password...' : 'Set New Password'}
            </Button>

            <div className="mt-4 text-center">
              <a
                href="/login"
                className="text-sm text-warhammer-gold hover:text-warhammer-gold-dark"
              >
                Back to login
              </a>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPasswordConfirm;
