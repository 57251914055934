import PlayerNameLink from "@/components/ui/players/PlayerNameLink";

interface Player {
  id: string;
  full_name: string;
  nickname?: string;
}

interface ArmyList {
  list_type: 'text' | 'owb';
  list_text?: string;
  list_json?: object | null;
}

export interface ArmyListResult {
  id: number;
  rank: number;
  player: Player;
  faction?: {
    name: string;
  };
  army_list?: ArmyList;
}

interface ArmyListDetailsProps {
  result: ArmyListResult;
  tournament: {
    name: string;
  };
}

export function ArmyListDetails({ result, tournament }: ArmyListDetailsProps) {
  const podiumEmoji = result.rank <= 3 ? ['🥇', '🥈', '🥉'][result.rank - 1] : null;

  return (
    <div className="space-y-6">
      <div>
        <div className="text-lg font-semibold flex items-center gap-2">
          {podiumEmoji && <span className="text-xl">{podiumEmoji}</span>}
          <PlayerNameLink player={result.player}/>
          {result.faction && (
            <span className="text-muted-foreground">• {result.faction.name}</span>
          )}
        </div>
        <div className="text-sm">
          {tournament.name}
        </div>
      </div>
      {/* List Content */}
      {result.army_list && (
        <div className="overflow-y-auto max-h-[calc(90vh-180px)]">
          {result.army_list.list_type === 'text' ? (
            <pre className="whitespace-pre-wrap font-mono text-sm bg-muted p-4 rounded-lg">
              {result.army_list.list_text}
            </pre>
          ) : (
            <pre className="whitespace-pre-wrap font-mono text-sm bg-muted p-4 rounded-lg">
              {JSON.stringify(result.army_list.list_json, null, 2)}
            </pre>
          )}
        </div>
      )}
    </div>
  );
}
