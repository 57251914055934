// app/frontend/components/ui/FormattedDate.tsx
import React from 'react';
import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

interface FormattedDateProps {
  startDate: string | Date;
  endDate?: string | Date;
}

function FormattedDate({ startDate, endDate }: FormattedDateProps) {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;

  if (!end || isSameDay(start, end)) {
    return <>{format(start, "do MMM yyyy")}</>;
  }

  if (isSameMonth(start, end) && isSameYear(start, end)) {
    return <>{format(start, "do")}-{format(end, "do MMM yyyy")}</>;
  }

  if (isSameYear(start, end)) {
    return <>{format(start, "do MMM")}-{format(end, "do MMM yyyy")}</>;
  }

  return <>{format(start, "do MMM yyyy")}-{format(end, "do MMM yyyy")}</>;
}

export default FormattedDate;
