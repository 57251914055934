import {Feedback} from "@/components/ui/feedback/Types.ts";
import {Badge} from "@/components/ui/shadcn/badge.tsx";

interface ReviewStatusBadgeProps {
  status: Feedback['status']
}

const getStatusBadgeProps = (status: Feedback['status']) => {
  const variants = {
    open: { variant: 'default' as const, label: 'Open' },
    approved: { variant: 'secondary' as const, label: 'Approved' },
    rejected: { variant: 'destructive' as const, label: 'Rejected' },
    archived: { variant: 'outline' as const, label: 'Archived' }
  };
  return variants[status];
};

export default function ReviewStatusBadge({ status }: ReviewStatusBadgeProps) {
  const { variant, label } = getStatusBadgeProps(status);
  return <Badge variant={variant}>{label}</Badge>;
}
