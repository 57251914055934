import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/shadcn/dialog";
import PlayerNameLink from "@/components/ui/players/PlayerNameLink";
import { Button } from "@/components/ui/shadcn/button";
import { X } from "lucide-react";
import {ArmyListDetails} from "@/components/ui/tournaments/common/ArmyListDetails.tsx";

interface ArmyListDialogProps {
  isOpen: boolean;
  onClose: () => void;
  result: {
    id: number;
    rank: number;
    player: {
      id: string;
      full_name: string;
      nickname?: string;
    };
    faction?: {
      name: string;
    };
    army_list?: {
      list_type: 'text' | 'owb';
      list_text?: string;
      list_json?: object;
    };
  };
  tournament: {
    name: string;
  };
}

export default function ArmyListDialog({ isOpen, onClose, result, tournament }: ArmyListDialogProps) {


  if (!result.army_list) {
    return null;
  }

  const podiumEmoji = result.rank <= 3 ? ['🥇', '🥈', '🥉'][result.rank - 1] : null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] max-h-[90vh]">
        <ArmyListDetails result={result} tournament={tournament} />

        <div className="pb-4 flex justify-end">
          <Button onClick={onClose} variant="default" size="sm">
            <X className="h-4 w-4 mr-2" />
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
