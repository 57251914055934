import React from 'react';
import { usePage, router } from '@inertiajs/react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";

interface Season {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
}

interface Region {
  id: string;
  code: string;
  name: string;
}

interface PageProps {
  seasons: Season[];
  current_season: Season;
  current_region: Region;
}

export function SeasonSelector() {
  const { seasons, current_season, current_region } = usePage<PageProps>().props;

  const handleSeasonChange = (seasonId: string) => {
    //FIXME
    router.post(`/${current_region.slug}/switch_season`, {
        season_id: seasonId,
    });
  };

  return (
    <Select value={current_season.id} onValueChange={handleSeasonChange}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Select season" />
      </SelectTrigger>
      <SelectContent>
        {seasons.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime()).map((season) => (
          <SelectItem key={season.id} value={season.id}>
            {season.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
