import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import { Textarea } from "@/components/ui/shadcn/textarea";
import { Separator } from "@/components/ui/shadcn/separator";
import { cn } from "@/lib/utils.ts";
import Avatar from "@/components/ui/controls/Avatar";
import { Mail, ImageIcon } from 'lucide-react';

interface BasicInfoProps {
  email: string;
  bio: string;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBioChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  player?: {
    id: string;
    first_name: string;
    last_name: string;
    nickname: string;
  } | null;
  onPlayerFieldChange?: (field: string, value: string) => void;
  errors?: {
    email?: string[];
    bio?: string[];
    first_name?: string[];
    last_name?: string[];
    nickname?: string[];
  };
}

export const BasicInfoCard = ({
                                email,
                                bio,
                                onEmailChange,
                                onBioChange,
                                player,
                                onPlayerFieldChange,
                                errors = {}
                              }: BasicInfoProps) => {
  const renderError = (fieldErrors?: string[]) => {
    if (!fieldErrors?.length) return null;
    return (
      <p className="text-sm font-medium text-destructive mt-1">
        {fieldErrors[0]}
      </p>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Basic Information</CardTitle>
        <CardDescription>
          Update your basic profile information
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {player && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2 space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="first_name">First Name</Label>
                    <Input
                      id="first_name"
                      value={player.first_name}
                      onChange={e => onPlayerFieldChange?.('first_name', e.target.value)}
                      className={cn(errors?.['first_name'] && "border-destructive")}
                    />
                    {renderError(errors?.['first_name'])}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="last_name">Last Name</Label>
                    <Input
                      id="last_name"
                      value={player.last_name}
                      onChange={e => onPlayerFieldChange?.('last_name', e.target.value)}
                      className={cn(errors?.['last_name'] && "border-destructive")}
                    />
                    {renderError(errors?.['last_name'])}
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="nickname">Nickname</Label>
                  <div className="relative">
                    <div
                      className={cn(
                        "absolute inset-y-0 left-0 flex items-center px-3",
                        "bg-muted border-r border-input text-muted-foreground",
                        "select-none rounded-l-md"
                      )}
                    >
                      @
                    </div>
                    <Input
                      id="nickname"
                      value={player.nickname}
                      onChange={e => onPlayerFieldChange?.('nickname', e.target.value)}
                      className={cn(
                        "pl-12",
                        errors?.['nickname'] && "border-destructive"
                      )}
                    />
                  </div>
                  {renderError(errors?.['nickname'])}
                  <p className="text-xs text-muted-foreground">
                    Nickname must start with a letter and can only contain lowercase letters, numbers, underscores and dashes
                  </p>
                </div>
              </div>

              <div className="lg:col-span-1">
                <div className="bg-muted/50 rounded-lg p-6">
                  <div className="flex flex-col items-center text-center">
                    <div className="w-[120px] h-[120px] rounded-full overflow-hidden mb-4 border-4 border-warhammer-gold shadow-lg">
                      <Avatar
                        name={`${player.first_name} ${player.last_name}`}
                        email={email}
                        size={120}
                        round={true}
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm text-muted-foreground">
                        Avatar automatically matched from:
                      </p>
                      <div className="flex justify-center gap-4">
                        <div className="flex items-center gap-1 text-xs text-muted-foreground">
                          <Mail className="h-3 w-3" />
                          <span>Gravatar</span>
                        </div>
                        <div className="flex items-center gap-1 text-xs text-muted-foreground">
                          <ImageIcon className="h-3 w-3" />
                          <span>Social</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Separator className="my-6" />
          </>
        )}

        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            value={email}
            onChange={onEmailChange}
            type="email"
            className={cn(errors?.email && "border-destructive")}
          />
          {renderError(errors?.email)}
        </div>

        <div className="space-y-2">
          <Label htmlFor="bio">Bio</Label>
          <Textarea
            id="bio"
            value={bio}
            onChange={onBioChange}
            placeholder="Tell us about yourself..."
            className={cn(
              "min-h-[100px]",
              errors?.bio && "border-destructive"
            )}
          />
          {renderError(errors?.bio)}
        </div>
      </CardContent>
    </Card>
  );
};
