import React, { useState } from 'react';
import { Button } from "@/components/ui/shadcn/button";
import { Info } from "lucide-react";
import PointSystemInfo from "./PointSystemInfo";

export function RankingInfoButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="default"
        size="sm"
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2"
      >
        <Info className="h-4 w-4" />
        <span className="hidden sm:inline">How is the ranking score calculated?</span>
        <span className="sm:hidden">Help</span>
      </Button>
      <PointSystemInfo iconTrigger={false} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
