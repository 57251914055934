import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/shadcn/table";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import { Crown } from "lucide-react";
import Avatar from "@/components/ui/controls/Avatar.tsx";
import PlayerNameLink from "@/components/ui/players/PlayerNameLink";

interface FactionLeader {
  player: {
    id: string;
    full_name: string;
    nickname?: string;
  };
  total_points: number;
  results: Array<{
    points: number;
    tournament_id: number;
  }>;
}

interface FactionLeadersTableProps {
  factionRankings: Record<string, FactionLeader[]>;
  factions: Array<{
    id: string;
    name: string;
    color?: string;
  }>;
}

const FactionLeadersTable = ({ factionRankings, factions }: FactionLeadersTableProps) => {
  // Sort factions by name
  const sortedFactions = [...factions].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Crown className="h-5 w-5" /> Faction Leaders
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Faction</TableHead>
              <TableHead>🥇 Champion</TableHead>
              <TableHead className="hidden md:table-cell">🥈 Runner-up</TableHead>
              <TableHead className="hidden md:table-cell">🥉 Third Place</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedFactions.map((faction) => {
              const leaders = factionRankings[faction.id] || [];
              return (
                <TableRow key={faction.id}>
                  <TableCell>
                    <div className="flex items-center gap-3">
                      <Avatar
                        name={faction.name}
                        size={32}
                        color={faction.color || '#808080'}
                      />
                      <span className="font-bold">{faction.name}</span>
                    </div>
                  </TableCell>
                  <TableCell className="font-semibold">
                    {leaders[0] ? (
                      <div>
                        <div className="flex items-center gap-2">
                          <PlayerNameLink player={leaders[0].player} />
                        </div>
                        <div className="text-sm text-muted-foreground">
                          {leaders[0].total_points} points
                        </div>
                      </div>
                    ) : (
                      <span className="text-muted-foreground text-sm font-normal">-</span>
                    )}
                  </TableCell>
                  {[1, 2].map((position) => (
                    <TableCell key={position} className="hidden md:table-cell">
                      {leaders[position] ? (
                        <div>
                          <div className="flex items-center gap-2">
                            <PlayerNameLink player={leaders[position].player} />
                          </div>
                          <div className="text-sm text-muted-foreground">
                            {leaders[position].total_points} points
                          </div>
                        </div>
                      ) : (
                        <span className="text-muted-foreground text-sm font-normal">-</span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default FactionLeadersTable;
