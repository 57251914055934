import { usePage } from '@inertiajs/react';
import TournamentTimeline from '@/components/ui/tournaments/timeline/TournamentTimeline';
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import {useState} from "react";
import TournamentForm from "@/components/ui/tournaments/show/TournamentForm.tsx";

const TimelineView = () => {
  const { tournaments } = usePage<PageProps>().props;
  const [isCreating, setIsCreating] = useState(false);

  return (
    <PageContainer
      header={<PageHeader
        showSeasonSelector={false}
        description="Upcoming tournaments in your region"
      />}
      noPadding
    >
      <TournamentTimeline tournaments={tournaments} onAdd={() => setIsCreating(true)} />
      <TournamentForm
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
      />
    </PageContainer>
  );
};

export default TimelineView;
