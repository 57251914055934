import React, { useState, useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { cn } from "@/lib/utils";
import TickerLine from './TickerLine';

export interface TickerItem {
  region_code: string;
  region_flag: string;
  player_name: string;
  player_nickname?: string;
  text: string;
  tournament_name?: string;
  points?: number;
}

interface PageProps {
  ticker_items: TickerItem[];
}

const TournamentTicker = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { ticker_items } = usePage<PageProps>().props;

  useEffect(() => {
    const isMobile = window.innerWidth < 640; // sm breakpoint
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % ticker_items.length);
        setIsTransitioning(false);
      }, isMobile ? 1500 : 1000); // Longer transition on mobile
    }, isMobile ? 5000 : 4000); // Longer display time on mobile

    return () => clearInterval(interval);
  }, [ticker_items.length]);

  const getIndexes = () => {
    const prev = (currentIndex - 1 + ticker_items.length) % ticker_items.length;
    const next = (currentIndex + 1) % ticker_items.length;
    return { prev, current: currentIndex, next };
  };

  const { prev, current, next } = getIndexes();

  return (
    <div className={cn(
      "mb-2 sm:mb-2 min-h-[60px] sm:min-h-[80px]",
      "relative overflow-hidden w-full"
    )}>
      {[prev, current, next].map((index, i) => {
        const item = ticker_items[index];
        return (
          <div
            key={index}
            className={cn(
              "absolute w-full text-center transition-all duration-1000 ease-in-out",
              "whitespace-nowrap text-white"
            )}
            style={{
              transform: `translateY(${100 * (i - 1 + (isTransitioning ? -1 : 0))}%)`,
              opacity: i === 1 ? 1 : 0.3,
            }}
          >
            <div className="py-2">
              <TickerLine item={item}/>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TournamentTicker;
