import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";
import { Badge } from "@/components/ui/shadcn/badge";
import PlayerNameLink from "@/components/ui/players/PlayerNameLink";
import {Button} from "@/components/ui/controls/Button";
import {CheckCircle} from "lucide-react";
import { Feedback } from "@/components/ui/feedback/Types";
import ReviewStatusBadge from "@/components/ui/feedback/ReviewStatusBadge.tsx";
import React from "react";

const getSubjectTypeLabel = (type: string) => {
  const labels: Record<string, string> = {
    'Tournament': 'Tournament',
    'TournamentResult': 'Tournament Result',
    'ArmyList': 'Army List'
  };
  return labels[type] || type;
};

export const createColumns = (onVerify: (feedback: Feedback) => void): ColumnDef<Feedback>[] => [
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }) => {
      return format(new Date(row.original.created_at), 'MMM d, yyyy');
    }
  },
  {
    accessorKey: "subject_type",
    header: "Type",
    cell: ({ row }) => {
      return getSubjectTypeLabel(row.original.subject_type);
    }
  },
  {
    accessorKey: "parent",
    header: "Related To",
    cell: ({ row }) => {
      const feedback = row.original;
      const parent = feedback.parent;

      if (!parent) return "-";

      // For Army Lists, show both tournament and player
      if (feedback.subject_type === 'ArmyList' && parent.tournament && parent.player) {
        return (
          <div className="space-y-1">
            <div>
              <TournamentLink tournament={parent.tournament} />
            </div>
            <div className="text-sm text-muted-foreground">
              <PlayerNameLink player={parent.player} />
            </div>
          </div>
        );
      }

      // For tournament results, show tournament name
      if (feedback.subject_type === 'TournamentResult' && parent.tournament) {
        return <TournamentLink tournament={parent.tournament} />;
      }

      // For tournaments, show tournament name directly
      if (feedback.subject_type === 'Tournament' && parent.name) {
        return parent.name;
      }

      return "-";
    }
  },
  {
    accessorKey: "contact_email",
    header: "Submitted by",
    cell: ({ row }) => {
      const { user, contact_email } = row.original;

      // If we have a user, show their name and email
      if (user) {
        return (
          <div>
            <div className="font-medium">
              {user.first_name} {user.last_name}
            </div>
            <a
              href={`mailto:${user.email}`}
              className="text-sm text-muted-foreground hover:text-primary"
            >
              {user.email}
            </a>
          </div>
        );
      }

      // Otherwise just show the contact email if available
      if (contact_email) {
        return (
          <a
            href={`mailto:${contact_email}`}
            className="text-primary hover:underline"
          >
            {contact_email}
          </a>
        );
      }

      return "-";
    }
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      return <ReviewStatusBadge status={row.original.status} />
    }
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const feedback = row.original;
      return (
        <div className="flex justify-end">
          {feedback.status === 'open' && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => onVerify(feedback)}
              className="flex items-center gap-2"
            >
              <CheckCircle className="h-4 w-4" />
              Verify
            </Button>
          )}
        </div>
      );
    },
  },
];
