import { useState } from 'react';
import {Link, usePage} from '@inertiajs/react';
import { Button } from "@/components/ui/shadcn/button";
import {Settings, Users} from 'lucide-react';
import PageHeader from "@/components/layouts/PageHeader";
import MergePlayerDialog from "@/components/ui/players/MergePlayerDialog";
import PlayerPlatformLink from "@/components/ui/players/PlayerPlatformLink";
import { PlayerAchievementsCard } from "@/components/ui/players/cards/PlayerAchievementsCard";
import { PlayerFactionStatsCard } from "@/components/ui/players/cards/PlayerFactionStatsCard";
import { PlayerTournamentResultsTableCard } from "@/components/ui/players/cards/PlayerTournamentResultsTableCard";
import PlayerBioCard from "@/components/ui/players/cards/PlayerBioCard.tsx";

const PlayerProfile = () => {
  const { player, tournament_results, current_user } = usePage().props;
  const [isMergeDialogOpen, setMergeDialogOpen] = useState(false);
  const isCurrentUsersProfile = current_user?.player && current_user?.player?.id === player.id;

  return (
    <div className="container mx-auto p-4 space-y-6">
      <PageHeader>
        <div className="flex gap-2 mt-2">
          {player.external_ids?.map((id, index) => (
            <PlayerPlatformLink
              key={index}
              platform={id.platform}
              platformIdentifier={id.platform_identifier}
              url={id.url}
            />
          ))}
          {isCurrentUsersProfile && (
            <Link href="/profile">
              <Button
                size="sm"
                className="flex items-center gap-2"
              >
                <Settings className="h-4 w-4" />
                <span className="hidden sm:inline">Edit Profile</span>
                <span className="sm:hidden">Edit</span>
              </Button>
            </Link>
          )}
        </div>
      </PageHeader>

      <PlayerBioCard player={player} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <PlayerAchievementsCard achievements={player.achievements} />
        <PlayerFactionStatsCard factions={player.factions} />
      </div>

      <PlayerTournamentResultsTableCard results={tournament_results} />

      <div className="mt-6 flex justify-between items-center">
        <Button
          variant="secondary"
          onClick={() => setMergeDialogOpen(true)}
          className="flex items-center"
        >
          <Users className="h-4 w-4 mr-2" />
          Merge Player
        </Button>
      </div>

      {isMergeDialogOpen && (
        <MergePlayerDialog
          isOpen={isMergeDialogOpen}
          onClose={() => setMergeDialogOpen(false)}
          sourcePlayerId={player.id}
        />
      )}
    </div>
  );
};

export default PlayerProfile;
