import { useState, useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { Storage } from '@/lib/storage';

export const useSurveyPrompt = () => {
  const [showSurveyPrompt, setShowSurveyPrompt] = useState(false);
  const { current_region, current_user } = usePage().props;

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();

        // Only continue if user is in Australia
        if (data.country_code !== 'AU') {
          return;
        }

        const shouldShow = Storage.shouldShowSurvey(!!current_user) &&
          current_region.code === 'AU';

        if (shouldShow) {
          setShowSurveyPrompt(true);
        }
      } catch (error) {
        console.error('Error checking location:', error);
        // If location check fails, proceed with showing the prompt
        // This ensures we don't accidentally hide it from Australian users
        const shouldShow = Storage.shouldShowSurvey(!!current_user) &&
          current_region.code === 'AU';

        if (shouldShow) {
          setShowSurveyPrompt(true);
        }
      }
    };

    checkLocation();
  }, [current_region, current_user]);

  const closeSurveyPrompt = () => {
    setShowSurveyPrompt(false);
  };

  return {
    showSurveyPrompt,
    closeSurveyPrompt
  };
};
