import PlayerNameLink from "@/components/ui/players/PlayerNameLink";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@/components/ui/shadcn/tooltip';
import ArmyListButton from "@/components/ui/tournaments/common/ArmyListViewButton.tsx";
import {Tournament} from "@/types/Tournament.types.ts";

interface Player {
  id: string;
  full_name: string;
  nickname?: string;
}

export interface TournamentResult {
  rank: number;
  player: Player;
  wins: number;
  losses: number;
  draws: number;
  faction?: {
    name: string;
  };
}

interface TournamentPodiumInfoProps {
  tournament: Tournament;
}

export function TournamentPodiumInfo({ tournament }: TournamentPodiumInfoProps) {
  const results = tournament.tournament_results || [];
  const rankedResults = results
    .filter(result => result.rank !== null && result.rank <= 3)
    .sort((a, b) => a.rank - b.rank)
    .slice(0, 3);

  if (rankedResults.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-6 text-center select-none">
        <p className="text-base font-semibold text-foreground mb-2">
          No rankings available yet
        </p>
        <div className="space-y-1 text-sm text-muted-foreground">
          <p>Results will appear here once the tournament is complete</p>
          <p>
            Results taking longer than expected? Buy your TO a beer and ask nicely! 🍺
          </p>
        </div>
      </div>
    );
  }

  const getRankEmoji = (rank: number) => {
    switch (rank) {
      case 1: return '🥇';
      case 2: return '🥈';
      case 3: return '🥉';
      default: return `${rank}.`;
    }
  };

  return (
    <TooltipProvider>
      <div className="space-y-2">
        {/* Stats Header */}
        <div className="flex justify-end items-center pr-14 text-xs text-muted-foreground">
          <div className="grid grid-cols-3 gap-2 font-medium">
            <span>W</span>
            <span>L</span>
            <span>D</span>
          </div>
        </div>

        {/* Results */}
        {rankedResults.map((result) => (
          <div key={result.rank} className="flex items-center justify-between py-1">
            <div className="flex items-center gap-2">
              <Tooltip>
                <TooltipTrigger>
                  <span className="text-xl">
                    {getRankEmoji(result.rank)}
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{`${result.rank}${result.rank === 1 ? 'st' : result.rank === 2 ? 'nd' : 'rd'} Place`}</p>
                </TooltipContent>
              </Tooltip>
              <div className="flex flex-col">
                <PlayerNameLink player={result.player}/>
                {result.faction && (
                  <span className="text-sm text-muted-foreground">
                    {result.faction.name}
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="grid grid-cols-3 gap-2 font-mono text-sm min-w-[60px]">
                <span className="text-green-500 font-semibold">{result.wins}</span>
                <span className="text-red-500 font-semibold">{result.losses}</span>
                <span className="text-blue-500 font-semibold">{result.draws}</span>
              </div>
              <ArmyListButton tournament={tournament} result={result} />
            </div>
          </div>
        ))}
      </div>
    </TooltipProvider>
  );
}
