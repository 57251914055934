import React, {useState} from 'react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import { Button } from "@/components/ui/shadcn/button";
import { Mail } from 'lucide-react';
import Header from "@/components/layouts/Header.tsx";
import {ThemeProvider} from "@/components/ThemeProvider.tsx";
import {Head, Link} from "@inertiajs/react";

const RegionComingSoon = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-background text-foreground p-4">
      <Card className="max-w-2xl w-full">
        <CardHeader>
          <Head title={`Coming Soon`} />
          <h1 className="text-3xl font-bold text-center text-warhammer-gold">🏗️ New Regions Coming Soon! 🏗️</h1>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="text-center">
            <p className="text-xl mb-4">
              We're still in the early stages of development, but we're working hard to bring Old World Rankings to more regions!
            </p>
            <p className="text-lg">
              🌍 The world of Warhammer is vast, and so is our ambition! 🌍
            </p>
          </div>

          <div className="flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="w-48 h-48 text-warhammer-gold">
              <circle cx="100" cy="100" r="90" fill="none" stroke="currentColor" strokeWidth="10" />
              <path d="M100 10 L100 190 M10 100 L190 100" stroke="currentColor" strokeWidth="10" />
              <circle cx="100" cy="100" r="20" fill="currentColor" />
              <path d="M60 60 L140 140 M60 140 L140 60" stroke="currentColor" strokeWidth="10" />
            </svg>
          </div>

          <div className="text-center">
            <p className="text-lg mb-4">
              Want to see your region featured? We'd love to hear from you! 📬
            </p>
            <p className="text-lg font-semibold">
              Contact 'gommo' at:
            </p>
            <Button
              variant="outline"
              className="mt-2"
              onClick={() => window.location.href = 'mailto:info@oldworldrankings.com'}
            >
              <Mail className="mr-2 h-4 w-4" />
              info@oldworldrankings.com
            </Button>
          </div>

          <p className="text-center text-muted-foreground">
            Together, we can build the best ranking site for Warhammer: The Old World! 🏆
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

const JustContentLayout = ({ children }: LayoutProps) => {

  return (
    <ThemeProvider>
      <div className="flex h-screen bg-background text-foreground">
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header  />
          <main className="flex-1 overflow-y-auto bg-background p-4">
            {children}
          </main>
        </div>
      </div>
    </ThemeProvider>
  );
};

RegionComingSoon.layout = (page) => <JustContentLayout children={page}  />

export default RegionComingSoon;
