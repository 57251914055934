import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Button } from "@/components/ui/shadcn/button";
import {LinkIcon, MessageCircle, UserPlus, Users} from 'lucide-react';
import LinkPlayerDialog from "@/components/ui/profile/LinkPlayerDialog.tsx";
import React, {useState} from "react";
import {Link} from "@inertiajs/react";
import {Alert, AlertDescription} from "@/components/ui/shadcn/alert.tsx";

export const LinkedPlayerCard = ({ player, onLinkPlayer }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Linked Player</CardTitle>
          <CardDescription>
            Connect your user account to your player profile
          </CardDescription>
        </CardHeader>
        <CardContent>
          {player ? (
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div className="flex items-center gap-4">
                  <Link href={player.friendly_url}>
                    <div className="flex flex-col">
                      <span className="text-lg font-semibold">@{player.nickname}</span>
                      <span className="text-sm text-muted-foreground">{player.full_name}</span>
                    </div>
                  </Link>
                </div>
                <Link href={player.friendly_url}>
                  <Button variant="outline" className="flex items-center gap-2">
                    Your Player Profile
                    <Users className="h-4 w-4" />
                  </Button>
                </Link>
              </div>

              <Alert className="bg-warhammer-gold/30">
                <AlertDescription>
                  <p className="text-sm text-warhammer-black">
                    Need to change or unlink your player profile? Click the chat icon
                    <MessageCircle className="h-5 w-5 inline-block mx-1 text-warhammer-gold" />
                    in the bottom right corner and we'll help you out.
                  </p>
                </AlertDescription>
              </Alert>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg">
              <UserPlus className="w-12 h-12 text-muted-foreground mb-4" />
              <h3 className="text-lg font-semibold mb-2">No Player Linked</h3>
              <p className="text-sm text-muted-foreground mb-4 text-center">
                Link your player profile to track your tournament results and achievements
              </p>
              <Button type="button" onClick={() => setIsDialogOpen(true)}>
                <LinkIcon className="w-4 h-4 mr-2" /> Link Player Profile
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <LinkPlayerDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
  </>
  );
};
