import React from 'react';

interface SidebarGroupProps {
  label: string;
  children: React.ReactNode;
  isCollapsed: boolean;
}

const SidebarGroup = ({ label, children, isCollapsed }: SidebarGroupProps) => {
  return (
    <div className="">
      {!isCollapsed && (
        <h4 className="text-xs uppercase font-medium text-warhammer-gold/50 px-4 pt-2 pointer-events-none select-none">
          {label}
        </h4>
      )}
      {children}
    </div>
  );
};

export default SidebarGroup;
