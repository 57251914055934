import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import { Loader2, Check } from 'lucide-react';
import { useForm, usePage } from '@inertiajs/react';

interface FeedbackDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  parent?: {
    type: string;
    id: string;
  };
  subject?: {
    type: string;
    id?: string;
  };
  children?: React.ReactNode;
}

export function FeedbackDialog({
                                 isOpen,
                                 onClose,
                                 title,
                                 parent,
                                 subject,
                                 children
                               }: FeedbackDialogProps) {
  const { current_region, current_user } = usePage().props;

  const { data, setData, transform, post, processing, errors, reset } = useForm({
    parent_type: parent?.type,
    parent_id: parent?.id,
    subject_type: subject?.type,
    subject_id: subject?.id,
    contact_email: '',
    comment: '',
    feedback_data: {}
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    transform((data) => ({
      feedback: {
        ...data
      }
    }));

    post(`/${current_region.slug}/feedback`, {
      preserveScroll: true,
      onSuccess: () => {
        reset();
        onClose();
      }
    });
  };

  const handleUpdateFeedbackData = (newData: any) => {
    setData('feedback_data', newData);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          {!current_user && (
            <div className="space-y-2">
              <Label htmlFor="contact_email">Contact Email</Label>
              <Input
                id="contact_email"
                type="email"
                value={data.contact_email}
                onChange={(e) => setData('contact_email', e.target.value)}
                placeholder="Enter your email address"
                required
              />
              {errors.contact_email && (
                <p className="text-sm text-destructive">{errors.contact_email}</p>
              )}
            </div>
          )}

          {/* Render children with the feedback data update function */}
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                onUpdateData: handleUpdateFeedbackData
              });
            }
            return child;
          })}

          <div className="space-y-2">
            <Label htmlFor="comment">Additional Comments</Label>
            <textarea
              id="comment"
              value={data.comment}
              onChange={(e) => setData('comment', e.target.value)}
              className="w-full min-h-[100px] p-3 rounded-md border bg-background text-sm"
              placeholder="Any additional details or context..."
            />
            {errors.comment && (
              <p className="text-sm text-destructive">{errors.comment}</p>
            )}
          </div>

          <div className="flex justify-end gap-4">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              disabled={processing}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={processing}>
              {processing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Submitting...
                </>
              ) : (
                'Submit Feedback'
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
