import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/shadcn/dialog";
import LoadingButton from "@/components/ui/controls/LoadingButton";
import DeleteButton from "@/components/ui/controls/DeleteButton";
import { cn } from "@/lib/utils.ts";

interface EditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  onDelete?: () => void;
  isProcessing: boolean;
  isDisabled?: boolean;
  className?: string;
}

function EditorDialog({
                        isOpen,
                        onClose,
                        title,
                        children,
                        onSubmit,
                        onDelete,
                        isProcessing,
                        isDisabled,
                        className
                      }: EditorDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          "sm:max-w-[425px] h-auto max-h-[95vh]",
          "flex flex-col",
          className
        )}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>

        <form onSubmit={onSubmit} className="flex flex-col flex-1 overflow-hidden">
          <div className="flex-1 overflow-y-auto pr-2 -mr-2">
            {children}
          </div>

          <DialogFooter className="flex justify-between items-center mt-6 pt-6 border-t">
            {onDelete && (
              <DeleteButton
                onDelete={onDelete}
                className="mr-auto"
                tabIndex={0}
                disabled={isDisabled || isProcessing}
              />
            )}
            <LoadingButton
              type="submit"
              loading={isProcessing}
              loadingText="Saving..."
              className="ml-auto"
              disabled={isDisabled || isProcessing}
            >
              Save changes
            </LoadingButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditorDialog;
