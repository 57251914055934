// app/frontend/pages/auth/register.tsx

import React from 'react';
import { useForm, Head, Link } from '@inertiajs/react';
import { Button } from "@/components/ui/shadcn/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import GoogleSignInButton from "@/components/ui/controls/GoogleSignInButton";
import Divider from "@/components/ui/controls/Divider";
import owfLogoWhite from '@/assets/images/owr_logo_white.png';
import { Loader2 } from 'lucide-react';
import Guard from "@/components/Guard.tsx";
import MicrosoftSignInButton from "@/components/ui/controls/MicrosoftSignInButton.tsx";
import {handleGoogleSignIn, handleMicrosoftSignIn} from "@/lib/auth.ts";

function Register() {
  const { data, setData, transform, post, processing, errors } = useForm({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  // Wrap the credentials in a user object for devise
  transform((data) => ({
    user: { ...data }
  }));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    post('/register');
  };

  const handleGoogleSignUp = () => {
    // Implement Google sign-up logic here
    console.log('Google sign-up clicked');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-warhammer-red via-warhammer-red-dark to-warhammer-black p-4">
      <Head title="Register" />
      <Card className="w-full max-w-md bg-warhammer-black border-warhammer-gold">
        <CardHeader className="relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img src={owfLogoWhite} alt="OWF Logo" className="h-16 w-16" />
          </div>
          <CardTitle className="text-2xl text-warhammer-gold mt-8">Register</CardTitle>
          <CardDescription className="text-warhammer-silver">
            Create your account to join the Old World Rankings community
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="first_name" className="text-warhammer-gold">First Name</Label>
              <Input
                id="first_name"
                type="text"
                value={data.first_name}
                onChange={e => setData('first_name', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.first_name && <p className="text-red-500">{errors.first_name}</p>}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="last_name" className="text-warhammer-gold">Last Name</Label>
              <Input
                id="last_name"
                type="text"
                value={data.last_name}
                onChange={e => setData('last_name', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.last_name && <p className="text-red-500">{errors.last_name}</p>}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email" className="text-warhammer-gold">Email</Label>
              <Input
                id="email"
                type="email"
                value={data.email}
                onChange={e => setData('email', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password" className="text-warhammer-gold">Password</Label>
              <Input
                id="password"
                type="password"
                value={data.password}
                onChange={e => setData('password', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.password && <p className="text-red-500">{errors.password}</p>}
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password_confirmation" className="text-warhammer-gold">Confirm Password</Label>
              <Input
                id="password_confirmation"
                type="password"
                value={data.password_confirmation}
                onChange={e => setData('password_confirmation', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.password_confirmation && <p className="text-red-500">{errors.password_confirmation}</p>}
            </div>
            <Button
              type="submit"
              className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
              disabled={processing}
            >
              {processing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Registering...
                </>
              ) : (
                'Register'
              )}
            </Button>
          </form>
          <Divider text="OR" />

          <div className="mt-4 space-y-3">
            <GoogleSignInButton text="Sign up with Google"
              onClick={() => handleGoogleSignIn({ authType: 'signup' })}
            />
            <MicrosoftSignInButton text="Sign up with Microsoft"
              onClick={() => handleMicrosoftSignIn({ authType: 'signup' })}
            />
          </div>
          <div className="mt-6 text-center text-sm text-warhammer-silver">
            Already have an account?{" "}
            <Link href="/login" className="underline text-warhammer-gold hover:text-warhammer-gold-dark">
              Log in
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Register;
