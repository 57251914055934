import {MapPin, Users, Calendar, Hash} from 'lucide-react';
import FormattedDate from "@/components/ui/FormattedDate";
import { cn } from "@/lib/utils";
import { PLATFORM_CONFIGS, PlatformIdentifier } from '../common/PlatformConfigs';

interface TournamentStatsProps {
  tournament: {
    start_date: string;
    end_date?: string;
    tournament_results: any[];
    player_cap: number;
    platform?: string;
    platform_url?: string;
    address?: {
      street?: string;
      city?: string;
      state?: string;
      country?: string;
      postal_code?: string;
      latitude?: number;
      longitude?: number;
    };
  };
}

const InfoItem = ({
                    icon: Icon,
                    label,
                    value,
                    iconColor = "",
                    className = ""
                  }) => (
  <div className={cn("flex items-center gap-2 text-sm", className)}>
    <div className="p-2 bg-muted/50 rounded-lg">
      <Icon className={cn("h-4 w-4", iconColor || "text-muted-foreground")} />
    </div>
    <div>
      <p className="text-xs text-muted-foreground">{label}</p>
      <p className="font-medium">{value}</p>
    </div>
  </div>
);

const PlatformInfoItem = ({ platform, platformUrl }: { platform: string, platformUrl: string }) => {
  const platformConfig = PLATFORM_CONFIGS[platform as PlatformIdentifier];

  if (!platformConfig) {
    return (
      <div className="flex items-center gap-2 text-sm">
        <div className="p-2 bg-muted/50 rounded-lg">
          <div className="h-4 w-4 text-purple-500" />
        </div>
        <div>
          <p className="text-xs text-muted-foreground">Platform</p>
          <p className="font-medium text-muted-foreground">
            {platform || 'Manual Entry'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 text-sm">
      <div className="p-2 bg-muted/50 rounded-lg">
        <img
          src={platformConfig.logo}
          alt={`${platformConfig.displayName} logo`}
          className={platformConfig.logoClass}
        />
      </div>
      <div>
        <p className="text-xs text-muted-foreground">Platform</p>
        <a
          href={platformUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-foreground hover:text-foreground/80 hover:underline"
        >
          {platformConfig.displayName}
        </a>
      </div>
    </div>
  );
};

const TournamentInfoBar = ({ tournament }: TournamentStatsProps) => {
  const formatAddress = (address) => {
    if (!address) return 'Location not specified';
    const parts = [address.city, address.state].filter(Boolean);
    return parts.join(', ');
  };

  const formatPlayerStats = () => {
    const currentPlayers = tournament.tournament_results.length;
    if (tournament.player_cap === 0) {
      return `${currentPlayers} players registered`;
    }
    const spotsLeft = tournament.player_cap - currentPlayers;
    return `${currentPlayers}/${tournament.player_cap} players (${spotsLeft} spots left)`;
  };

  const formatPlayerRounds = () => {
    return `${tournament.rounds} rounds`;
  };

  const getLocationLink = (address) => {
    if (!address?.latitude || !address?.longitude) return null;
    return `https://www.google.com/maps?q=${address.latitude},${address.longitude}`;
  };

  const locationLink = getLocationLink(tournament.address);

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6 bg-card p-4 rounded-lg border">
      <InfoItem
        icon={Calendar}
        label="Date"
        iconColor="text-blue-500"
        value={
          <FormattedDate
            startDate={tournament.start_date}
            endDate={tournament.end_date}
          />
        }
      />

      <InfoItem
        icon={Hash}
        label="Rounds"
        iconColor="text-purple-500"
        value={formatPlayerRounds()}
      />

      <InfoItem
        icon={Users}
        label="Players"
        iconColor="text-green-500"
        value={formatPlayerStats()}
      />

      <div className="md:col-span-1">
        {locationLink ? (
          <a
            href={locationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity"
          >
            <InfoItem
              icon={MapPin}
              label="Location"
              iconColor="text-red-500"
              value={formatAddress(tournament.address)}
              className="hover:text-primary"
            />
          </a>
        ) : (
          <InfoItem
            icon={MapPin}
            label="Location"
            iconColor="text-red-500"
            value={formatAddress(tournament.address)}
          />
        )}
      </div>

      {tournament.platform && tournament.platform_url && (
        <div className="md:col-span-1">
          <PlatformInfoItem
            platform={tournament.platform}
            platformUrl={tournament.platform_url}
          />
        </div>
      )}
    </div>
  );
};

export default TournamentInfoBar;
