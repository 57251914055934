import type { TickerItem } from './TournamentTicker';

interface TickerLineProps {
  item: TickerItem;
}

const TickerLine = ({ item }: TickerLineProps) => {
  const playerDisplay = item.player_nickname ?
    `${item.player_name} (@${item.player_nickname})` :
    item.player_name;

  return (
    <div className="flex flex-col sm:flex-row items-center justify-center gap-2 text-white px-4">
      {/* Primary Info - Always Visible */}
      <div className="flex items-center gap-2 text-center sm:text-left">
        <span className="text-white">{item.region_flag}</span>
        <span className="text-white font-medium truncate max-w-[200px]">
          {playerDisplay}
        </span>
        <span className="hidden xs:inline text-warhammer-gold">•</span>
      </div>

      {/* Achievement/Rank - Always Visible but Styled Differently on Mobile */}
      <div className="flex items-center gap-2">
        <span className="text-warhammer-gold font-medium">
          {item.text}
        </span>
      </div>

      {/* Tournament Name/Points - Hidden on Small Mobile */}
      {(item.tournament_name || item.points) && (
        <div className="hidden sm:flex items-center gap-2">
          <span className="text-warhammer-gold">•</span>
          <span className="text-white">
            {item.tournament_name || `${item.points} points`}
          </span>
        </div>
      )}
    </div>
  );
};

export default TickerLine;
