import { usePage } from '@inertiajs/react';
import TournamentTimeline from '@/components/ui/tournaments/timeline/TournamentTimeline';
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";

const LatestResultsView = () => {
  const { tournaments } = usePage<PageProps>().props;

  return (
    <PageContainer
      header={<PageHeader showSeasonSelector={false} />}
      noPadding
    >
      <TournamentTimeline tournaments={tournaments} onAdd={()=>{}} showMonthTimeline={false} variant={"results"} />
    </PageContainer>
  );
};

export default LatestResultsView;
