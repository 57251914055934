import React, { useState } from 'react';
import { usePage, Head, Link, router } from '@inertiajs/react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/shadcn/accordion";
import { Button } from "@/components/ui/shadcn/button";
import { ArrowRight, X } from 'lucide-react';
import PlayerName from "@/components/ui/players/PlayerNameLink";
import MergePlayerDialog from "@/components/ui/players/MergePlayerDialog";

interface Tournament {
  id: string;
  name: string;
}

interface SimilarPlayer {
  id: string;
  full_name: string;
  nickname?: string;
  region_code: string;
  tournament_count: number;
  recent_tournaments: Tournament[];
}

interface DuplicateResult {
  duplicate_id: string; // Added this field
  player: SimilarPlayer;
  potential_matches: SimilarPlayer[];
}

interface PageProps {
  duplicate_data: {
    metadata: {
      total_players_checked: number;
      potential_duplicates_found: number;
      generated_at: string;
    };
    results: DuplicateResult[];
  };
}

const Duplicates = () => {
  const { duplicate_data } = usePage<PageProps>().props;
  const [mergePlayers, setMergePlayers] = useState<{
    player1: SimilarPlayer | null;
    player2: SimilarPlayer | null;
  }>({
    player1: null,
    player2: null,
  });

  const handleMerge = (player1: SimilarPlayer, player2: SimilarPlayer) => {
    setMergePlayers({ player1, player2 });
  };

  const closeMergeDialog = () => {
    setMergePlayers({ player1: null, player2: null });
  };

  const handleIgnore = (duplicateId: string) => {
    router.post(`/players/ignore_duplicate/${duplicateId}`, {}, {
      preserveScroll: true,
    });
  };

  return (
    <div className="space-y-6 p-6">
      <Head title="Potential Duplicate Players" />

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Potential Duplicate Players</h1>
          <div className="text-muted-foreground">
            Found {duplicate_data.metadata.potential_duplicates_found} potential
            duplicates from {duplicate_data.metadata.total_players_checked} players
          </div>
        </div>
        <Link href="/players">
          <Button variant="outline">Back to Players</Button>
        </Link>
      </div>

      <Card>
        <CardHeader>
          <div className="space-y-2">
            <h2 className="text-2xl font-semibold">Matched Duplicates</h2>
          </div>
        </CardHeader>

        <CardContent>
          <Accordion type="single" collapsible className="w-full">
            {duplicate_data.results.map((result, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger className="hover:no-underline">
                  <div className="flex items-center gap-2">
                    <PlayerName player={result.player} link={false} />
                    <span className="text-muted-foreground text-sm">
                      ({result.potential_matches.length} similar players)
                    </span>
                  </div>
                </AccordionTrigger>

                <AccordionContent>
                  <div className="space-y-4 mt-2">
                    {result.potential_matches.map((player) => (
                      <div key={player.id}
                           className="flex justify-between items-start p-4 rounded-lg bg-muted/50">
                        <div className="space-y-2">
                          <PlayerName player={player} />
                          <div className="mt-2">
                            <h4 className="text-sm font-semibold text-muted-foreground">Recent tournaments:</h4>
                            <ul className="list-disc list-inside">
                              {player.recent_tournaments.map((tournament) => (
                                <li key={tournament.id} className="text-sm">
                                  <Link
                                    href={`/tournaments/${tournament.id}`}
                                    className="hover:underline text-muted-foreground"
                                  >
                                    {tournament.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="flex gap-2 ml-4">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleIgnore(result.duplicate_id)}
                            className="text-red-500 hover:text-red-700 hover:bg-red-100"
                          >
                            <X className="h-4 w-4" />
                            <span className="hidden md:inline ml-2">Ignore</span>
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleMerge(result.player, player)}
                          >
                            Merge <ArrowRight className="ml-2 h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
      </Card>

      {mergePlayers.player1 && mergePlayers.player2 && (
        <MergePlayerDialog
          isOpen={true}
          onClose={closeMergeDialog}
          sourcePlayerId={mergePlayers.player1.id}
          destinationPlayerId={mergePlayers.player2.id}
        />
      )}
    </div>
  );
};

export default Duplicates;
