import { cn } from "@/lib/utils";
import Avatar from "@/components/ui/controls/Avatar.tsx";
import { usePage } from "@inertiajs/react";

interface ProfileAvatarProps {
  isCollapsed: boolean;
}

function ProfileAvatar({ isCollapsed }: ProfileAvatarProps) {
  const { current_user } = usePage().props;

  return (
    <div className={cn("flex items-center space-x-2 px-4 py-1 mb-2", isCollapsed && "lg:justify-center")}>
      <Avatar
        name={`${current_user.first_name}`}
        email={current_user?.email}
        size={isCollapsed ? 32 : 40}
      />
      {!isCollapsed && current_user && (
        <div className="flex-1">
          <p className="text-sm font-medium text-warhammer-gold">{current_user.first_name} {current_user.last_name}</p>
        </div>
      )}
    </div>
  );
}

export default ProfileAvatar;
