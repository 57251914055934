import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";

interface Achievement {
  emoji: string;
  label: string;
  count: number;
}

interface PlayerAchievementsCardProps {
  achievements: Achievement[];
}

export function PlayerAchievementsCard({ achievements }: PlayerAchievementsCardProps) {
  return (
    <Card className="md:col-span-2">
      <CardHeader>
        <CardTitle>Achievements</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {achievements.map((achievement) => (
            <div
              key={`${achievement.emoji}-${achievement.label}`}
              className="flex items-center p-4 bg-secondary/50 rounded-lg hover:bg-secondary/70 transition-colors"
            >
              <span className="text-4xl mr-4">{achievement.emoji}</span>
              <div>
                <p className="font-semibold">{achievement.label}</p>
                <p className="text-sm text-muted-foreground">
                  {achievement.count}x earned
                </p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
