import React, { useState } from 'react';
import {ArmyListSubmission} from "@/components/ui/tournaments/common/ArmyListSubmission";

// Army list content component that will be passed to FeedbackDialog
export function ArmyListContent({ onUpdateData }: { onUpdateData?: (data: any) => void }) {
  const [listData, setListData] = useState({
    id: null,
    list_type: 'text' as const,
    list_text: '',
    list_json: null,
    verified: false,
    locked: false
  });

  const handleDataChange = (data: any) => {
    setListData(data);
    onUpdateData?.({
      list_type: data.list_type,
      list_content: data.list_type === 'text' ? data.list_text : data.list_json
    });
  };

  return (
    <ArmyListSubmission
      data={listData}
      onDataChange={handleDataChange}
    />
  );
}
