import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import {HelpCircle, MessageCircle, UserPlus} from 'lucide-react';
import { PlayerSelect } from "@/components/ui/controls/PlayerSelect";
import DoubleConfirmButton from "@/components/ui/controls/DoubleConfirmButton";
import { router } from '@inertiajs/react';
import {Alert, AlertDescription} from "@/components/ui/shadcn/alert.tsx";
import {Storage} from "@/lib/storage.ts";

interface Player {
  id: string;
  full_name: string;
  nickname?: string;
  home_region?: {
    code: string;
    country_flag: string;
  };
}

interface LinkPlayerDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LinkPlayerDialog = ({ isOpen, onClose }: LinkPlayerDialogProps) => {
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePlayerSelect = (player: Player) => {
    setSelectedPlayer(player);
  };

  const handleLink = () => {
    if (!selectedPlayer) return;

    setIsProcessing(true);
    router.post('/profile/link', {
      player_id: selectedPlayer.id
    }, {
      onFinish: () => {
        setIsProcessing(false);
        Storage.clearLinkPlayerDismissed();
        onClose();
      },
      preserveScroll: false,
      preserveState: false
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <UserPlus className="h-5 w-5" />
            Link Player Profile
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-6">
          <p className="text-sm text-muted-foreground mb-4">
            Search for your player profile to link it to your account. This will allow you to manage tournament notifications and other details.
          </p>
          <PlayerSelect
            selectedPlayer={selectedPlayer}
            onSelect={handlePlayerSelect}
            allowCreate={false}
            excludeLinked={true}
          />

          <Alert>
            <HelpCircle className="h-4 w-4" />
            <AlertDescription>
              <p className="text-sm">
                Can't find your player profile or seeing "already linked" errors? Click the chat icon
                <MessageCircle className="h-4 w-4 inline-block mx-1 text-warhammer-gold" />
                in the bottom right corner and we'll help sort it out!
              </p>
            </AlertDescription>
          </Alert>
        </div>
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={onClose} disabled={isProcessing}>
            Cancel
          </Button>
          <DoubleConfirmButton
            onConfirm={handleLink}
            initialText="Link Player"
            confirmText="Confirm Link"
            loadingText="Linking..."
            isLoading={isProcessing}
            disabled={!selectedPlayer}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LinkPlayerDialog;
