import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import { formatNameToAcronyms } from "@/lib/utils";

const FactionAppearanceChart = ({ factionStats, factions }) => {
  const processedData = useMemo(() => {
    const parentFactions = factions.filter(f => !f.parent_id);

    return parentFactions.map(parentFaction => {
      const subfactions = factions.filter(f => f.parent_id === parentFaction.id);
      const parentStats = factionStats.find(s => s.name === parentFaction.name) ||
        { count: 0, color: parentFaction.color };

      // Get stats for each subfaction
      const subfactionData = subfactions.map(subfaction => {
        const stats = factionStats.find(s => s.name === subfaction.name) ||
          { count: 0, color: subfaction.color };
        return {
          name: subfaction.name,
          fullName: subfaction.name,
          count: stats.count,
          color: stats.color || '#808080'
        };
      });

      // Use the full parent faction count without subtracting subfactions
      const directCount = parentStats.count;
      const totalCount = directCount + subfactionData.reduce((sum, sf) => sum + sf.count, 0);

      return {
        name: formatNameToAcronyms(parentFaction.name, window.innerWidth < 640),
        fullName: parentFaction.name,
        directCount,
        totalCount, // For sorting
        color: parentFaction.color || '#808080',
        subfactions: subfactionData.map(sf => ({
          ...sf,
          name: formatNameToAcronyms(sf.name, window.innerWidth < 640),
          fullName: sf.name
        }))
      };
    }).filter(faction =>
      faction.directCount > 0 || faction.subfactions.some(sf => sf.count > 0)
    ).sort((a, b) => b.totalCount - a.totalCount);
  }, [factionStats, factions]);

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;

    const data = payload[0].payload;
    const total = data.directCount + data.subfactions.reduce((sum, sf) => sum + sf.count, 0);

    return (
      <div className="bg-background border border-border rounded-lg shadow-lg p-3">
        <p className="font-semibold mb-2">{data.fullName}</p>
        <div className="space-y-1">
          <p className="text-sm font-medium mb-1">
            Total appearances: {total}
          </p>
          <p className="text-sm text-muted-foreground">
            Base faction: {data.directCount} appearances
          </p>
          {data.subfactions.map((sf, index) => (
            sf.count > 0 && (
              <p key={index} className="text-sm text-muted-foreground">
                {sf.fullName}: {sf.count} appearances
              </p>
            )
          ))}
        </div>
      </div>
    );
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="p-4 sm:p-6">
        <CardTitle>Faction Tournament Appearances</CardTitle>
      </CardHeader>
      <CardContent className="p-2 sm:p-6">
        <div className="h-[600px] -ml-2 sm:ml-0">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={processedData}
              layout="vertical"
              margin={{
                top: 0,
                right: 16,
                left: 60,
                bottom: 20
              }}
            >
              <XAxis type="number" fontSize={12} />
              <YAxis
                dataKey="name"
                type="category"
                width={60}
                interval={0}
                tick={{ fontSize: 11 }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar dataKey="directCount" stackId="a">
                {processedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>

              {processedData.reduce((max, faction) =>
                Math.max(max, faction.subfactions.length), 0
              ) > 0 && processedData[0].subfactions.map((_, subfactionIndex) => (
                <Bar
                  key={`subfaction-${subfactionIndex}`}
                  dataKey={(datum) => datum.subfactions[subfactionIndex]?.count || 0}
                  stackId="a"
                >
                  {processedData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.subfactions[subfactionIndex]?.color || 'transparent'}
                    />
                  ))}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default FactionAppearanceChart;
