import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import {Users, Trophy, Link as LinkIcon, UserCircle, NotepadText, ScrollText} from 'lucide-react';
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import {GrowthChart, RegionBreakdownChart} from "@/components/ui/admin/StatsCharts.tsx";

const AdminStatsPage = ({ stats }: { stats: StatsData }) => {
  const { totals } = stats;

  return (
    <PageContainer
      header={<PageHeader title="Platform Statistics" />}
    >
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-6 pt-6">
          <StatsCard
            title="Total Tournaments"
            value={totals.tournaments}
            icon={Trophy}
            description="Across all regions"
          />
          <StatsCard
            title="Total Players"
            value={totals.players}
            icon={Users}
            description="Registered in the system"
          />
          <StatsCard
            title="Total Users"
            value={totals.users}
            icon={UserCircle}
            description={`${totals.linked_users} linked to players`}
          />
          <StatsCard
            title="User Linkage"
            value={`${totals.linked_percentage}%`}
            icon={LinkIcon}
            description="Users linked to players"
          />
          <StatsCard
            title="Army Lists"
            value={totals.army_lists}
            icon={ScrollText}
            description={`${totals.army_list_percentage}% of tournament results`}
          />
        </div>

        <div className="grid grid-cols-1 gap-6">
          <RegionBreakdownChart regions={stats.regions} />
          <GrowthChart growth={stats.growth} />
        </div>
      </div>
    </PageContainer>
  );
};

const StatsCard = ({ title, value, icon: Icon, description }) => (
  <Card>
    <CardContent className="pt-6 pb-4 px-6">
      <div className="flex justify-between">
        <div>
          <p className="text-sm font-medium text-muted-foreground mb-1">{title}</p>
          <p className="text-2xl font-bold">{value}</p>
          <p className="text-sm text-muted-foreground mt-1">{description}</p>
        </div>
        <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center">
          <Icon className="h-6 w-6 text-primary" />
        </div>
      </div>
    </CardContent>
  </Card>
);
export default AdminStatsPage;
