import React from 'react';
import ReactAvatar from 'react-avatar';
import { colord } from 'colord';
import {formatNameToAcronyms} from "@/lib/utils.ts";

interface AvatarProps {
  name?: string;
  email?: string;
  size?: number;
  color?: string;
  round?: boolean;
}

function Avatar({
                  name,
                  email,
                  size = 40,
                  color,
                  round = true
                }: AvatarProps) {
  const getTextColor = (backgroundColor: string | undefined) => {
    if (!backgroundColor) return undefined;
    const c = colord(backgroundColor);
    return c.isDark()
      ? c.lighten(0.6).toHex()
      : c.darken(0.6).toHex();
  };

  const displayName = name
    ? formatNameToAcronyms(name)
    : (email || 'Anonymous');

  return (
    <ReactAvatar
      email={email}
      name={displayName}
      size={size}
      textSizeRatio={2.5}
      round={round}
      color={color}
      fgColor={color ? getTextColor(color) : undefined}
      maxInitials={2}
      alt={name || email || 'Avatar'}
    />
  );
}

export default Avatar;
