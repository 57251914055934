import {Head, Link, usePage} from '@inertiajs/react';
import { StaticPage } from "@/components/layouts/StaticPageLayout.tsx";
import BuyMeCoffee from "@/components/ui/controls/BuyMeACoffee.tsx";
import { Button } from "@/components/ui/controls/Button.tsx";
import { ExternalLink } from 'lucide-react';
import paypalLogo from '@/assets/images/paypal_logo.png';
import gommo from '@/assets/images/gommo.png';

const PricingPage = () => {
  const { front_end_configs } = usePage().props;
  const { paypal_link } = front_end_configs;

  return (
    <>
      <Head title="Pricing" />
      <StaticPage title="Pricing">
        <div className="space-y-6 text-white">
          {/* Free Tournament Rankings Section */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Free Tournament Rankings</h2>
            <p className="text-white/90">
              Old World Rankings is completely free for tournament organizers and players.
              We believe in supporting the Warhammer: The Old World community by providing
              accessible tournament management and ranking services.
            </p>
          </section>

          {/* Supporting Development Section */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Supporting Development</h2>
            <p className="text-white/90 mb-6">
              While our platform is free to use, we welcome support from the community
              to help cover our hosting costs and fund future development. You can support us through:
            </p>

            {/* Support Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 my-6">
              <BuyMeCoffee className="w-full sm:w-auto justify-center" />

              <Button
                onClick={() => window.open(paypal_link, '_blank')}
                className="w-full sm:w-auto bg-warhammer-gold text-warhammer-black hover:bg-yellow-400
                          border-2 border-yellow-600 hover:border-yellow-500 transition-colors duration-200
                          font-bold rounded-md flex items-center justify-center gap-2"
              >
                <img src={paypalLogo} alt="PayPal Logo" className="h-4 w-auto" />
                <span>Support via PayPal</span>
              </Button>
            </div>
          </section>

          {/* Future Vision Section */}
          <section className="mb-12">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Our Vision</h2>
            <p className="text-white/90 mb-6">
              We're building Old World Rankings to be more than just a tournament tracker. Our vision is to create
              the central hub for the competitive Warhammer: The Old World community.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-warhammer-gold">Platform Features</h3>
                <ul className="list-disc pl-6 space-y-2 text-white/90">
                  <li>Comprehensive meta statistics and analysis</li>
                  <li>Global ranking system for competitive play</li>
                  <li>Tools to help professionalize the competitive scene</li>
                  <li>Tournament hosting and management</li>
                  <li>Hobby profiles to showcase your armies and achievements</li>
                  <li>Social features to connect the community</li>
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-warhammer-gold">Community Goals</h3>
                <ul className="list-disc pl-6 space-y-2 text-white/90">
                  <li>Foster a welcoming competitive environment</li>
                  <li>Support tournament organizers and players</li>
                  <li>Provide insights into the evolving meta</li>
                  <li>Create a platform for sharing hobby progress</li>
                  <li>Build connections between players globally</li>
                </ul>
              </div>
            </div>
          </section>

          {/* About Me Section */}
          <section className="mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">About Me</h2>
                <p className="text-white/90">
                  I'm the host of <Link href='https://oldworldfanatics.com'>Old World Fanatics</Link>, a podcast dedicated to competitive Warhammer: The Old World.
                  Through the podcast and this platform, I'm working to build and support our growing community.
                </p>

                <div className="space-y-4 mt-6">
                  <a
                    href="https://oldworldfanatics.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-warhammer-gold hover:text-warhammer-gold-dark transition-colors"
                  >
                    <ExternalLink className="h-5 w-5" />
                    <span>oldworldfanatics.com</span>
                  </a>

                  <a
                    href="https://www.youtube.com/@oldworldfanatics"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-warhammer-gold hover:text-warhammer-gold-dark transition-colors"
                  >
                    <ExternalLink className="h-5 w-5" />
                    <span>YouTube Channel</span>
                  </a>

                  <a
                    href="https://www.patreon.com/oldworldfanatics"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-warhammer-gold hover:text-warhammer-gold-dark transition-colors"
                  >
                    <ExternalLink className="h-5 w-5" />
                    <span>Join our Patreon</span>
                  </a>
                </div>
              </div>

              <div className="bg-black/30 rounded-lg overflow-hidden">
                <div className="w-full h-[200px] bg-gray-800 flex items-center justify-center text-gray-600">
                  <img src={gommo} alt="Gommo" className="h-full w-full object-cover" />
                </div>

                <div className="mt-8">
                  <span className="font-handwriting text-4xl text-warhammer-gold">gommo</span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </StaticPage>
    </>
  );
};

export default PricingPage;
