import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import { ScrollArea } from "@/components/ui/shadcn/scroll-area";
import PlayerNameLink from "@/components/ui/players/PlayerNameLink";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";
import ArmyListButton from "@/components/ui/tournaments/common/ArmyListViewButton";

interface Result {
  id: number;
  rank: number;
  wins: number;
  losses: number;
  draws: number;
  player: {
    id: string;
    full_name: string;
    nickname?: string;
  };
  faction?: {
    name: string;
  };
  tournament: {
    id: number;
    name: string;
  };
}

interface LatestWinningListsCardProps {
  results: Result[];
}

const LatestWinningListsCard = ({ results }: LatestWinningListsCardProps) => {
  if (!results?.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Latest Top Player Lists & Results</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8">
            <p className="text-4xl mb-4">📋</p>
            <p className="text-xl font-semibold">No Recent Results</p>
            <p className="text-muted-foreground">Check back after the next tournament!</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Latest Top Player Lists & Results</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[500px] pr-4">
          <div className="space-y-4">
            {results.map((result) => (
              <div
                key={result.id}
                className="flex items-center justify-between p-4 bg-muted/50 rounded-lg hover:bg-muted/70 transition-colors"
              >
                <div className="min-w-0 flex-1">
                  <div className="flex items-center mb-1">
                    <PlayerNameLink player={result.player} />
                  </div>

                  <div className="flex items-center gap-4 text-sm text-muted-foreground">
                    <TournamentLink tournament={result.tournament} />
                    {result.faction && (
                      <span>{result.faction.name}</span>
                    )}
                  </div>

                  <div className="flex items-center gap-2 mt-1 text-sm">
                    <span className="font-mono">
                      <span className="text-green-500">{result.wins}</span>-
                      <span className="text-red-500">{result.losses}</span>-
                      <span className="text-orange-500">{result.draws}</span>
                    </span>
                    {result.rank <= 3 && (
                      <span className="text-xl">
                        {result.rank === 1 ? '🥇' : result.rank === 2 ? '🥈' : '🥉'}
                      </span>
                    )}
                  </div>
                </div>

                <ArmyListButton
                  tournament={result.tournament}
                  result={result}
                />
              </div>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default LatestWinningListsCard;
