import React, { useState } from 'react';
import { usePage, router } from '@inertiajs/react';
import { Input } from "@/components/ui/shadcn/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import { Label } from "@/components/ui/shadcn/label";
import { Switch } from "@/components/ui/shadcn/switch";
import { Button } from "@/components/ui/controls/Button";
import { PLATFORM_CONFIGS, PlatformIdentifier } from '@/components/ui/tournaments/common/PlatformConfigs';
import { useToast } from "@/components/ui/shadcn/hooks/use-toast";
import { DatePicker } from "@/components/ui/controls/DatePicker";
import { RefreshCw } from 'lucide-react';

const ImportTournamentForm = () => {
  const { current_season } = usePage().props;
  const [platform, setPlatform] = useState<PlatformIdentifier>('tp-new-recruit');
  const [isImporting, setIsImporting] = useState(false);
  const [isBatchMode, setIsBatchMode] = useState(false);
  const { toast } = useToast();

  // Single tournament state
  const [tournamentId, setTournamentId] = useState('');

  // Date range state
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date(current_season.end_date));

  const validateInput = (value: string, platformId: PlatformIdentifier) => {
    if (!value) return "Tournament ID or URL is required";

    const config = PLATFORM_CONFIGS[platformId];

    // Check if it's a URL
    if (value.includes(config.domain)) {
      return null; // Let the backend handle URL parsing
    }

    // Check if it's a valid ID format
    if (!value.match(config.idPattern)) {
      return `Please enter a valid ${config.displayName} tournament ID or URL`;
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isBatchMode) {
      const error = validateInput(tournamentId, platform);
      if (error) {
        toast({
          variant: "destructive",
          title: "Validation Error",
          description: error
        });
        return;
      }
    }

    setIsImporting(true);

    const importData = {
      platform,
      ...(isBatchMode ? {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      } : {
        tournament_id: tournamentId
      })
    };

    router.post('/import', importData, {
      onFinish: () => setIsImporting(false)
    });
  };

  const getPlaceholder = (platformId: PlatformIdentifier) => {
    const config = PLATFORM_CONFIGS[platformId];
    return `Enter ${config.displayName} tournament ID or paste URL`;
  };

  const getHelpText = (platformId: PlatformIdentifier) => {
    const config = PLATFORM_CONFIGS[platformId];
    return (
      <div className="text-sm text-muted-foreground mt-1">
        <p>Examples:</p>
        <p className="mt-1">ID: <span className="font-mono">{config.exampleId}</span></p>
        <p className="mt-1">URL: <span className="font-mono break-all">{config.exampleUrl}</span></p>
      </div>
    );
  };

  return (
    <div className="p-6 space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="platform">Platform</Label>
          <Select
            value={platform}
            onValueChange={(value: PlatformIdentifier) => setPlatform(value)}
          >
            <SelectTrigger id="platform">
              <SelectValue placeholder="Select platform"/>
            </SelectTrigger>
            <SelectContent>
              {Object.entries(PLATFORM_CONFIGS).map(([identifier, config]) => (
                <SelectItem key={identifier} value={identifier}>
                  <div className="flex items-center gap-2">
                    <img
                      src={config.logo}
                      alt={`${config.displayName} logo`}
                      className={config.logoClass}
                    />
                    <span>{config.displayName}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center space-x-2 pt-2">
          <Switch
            checked={isBatchMode}
            onCheckedChange={setIsBatchMode}
            disabled={!PLATFORM_CONFIGS[platform].supportsBatchImport}
          />
          <Label className={!PLATFORM_CONFIGS[platform].supportsBatchImport ? "text-muted-foreground" : ""}>
            Date Range Import
          </Label>
        </div>

        {isBatchMode ? (
          <div className="space-y-4">
            <div>
              <Label>Start Date</Label>
              <DatePicker
                date={startDate}
                setDate={setStartDate}
              />
            </div>
            <div>
              <Label>End Date</Label>
              <DatePicker
                date={endDate}
                setDate={setEndDate}
              />
            </div>
          </div>
        ) : (
          <div>
            <Label>Tournament ID or URL</Label>
            <Input
              value={tournamentId}
              onChange={(e) => setTournamentId(e.target.value)}
              placeholder={getPlaceholder(platform)}
              disabled={isImporting}
            />
            {getHelpText(platform)}
          </div>
        )}

        <div className="flex justify-start pt-2">
          <Button
            onClick={handleSubmit}
            disabled={isImporting}
          >
            <RefreshCw className={`mr-2 h-4 w-4 ${isImporting ? 'animate-spin' : ''}`}/>
            {isImporting ? 'Importing...' : (isBatchMode ?
                `Import ${PLATFORM_CONFIGS[platform].displayName} Tournaments for Date Range` :
                'Import Tournament'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportTournamentForm;
