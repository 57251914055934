import React from 'react';
import MainLayout from '@/components/layouts/MainLayout';
import {ThemeProvider} from "@/components/ThemeProvider";
import {Toaster} from "@/components/ui/shadcn/toaster.tsx";
import {ToastProvider} from "@/components/ui/shadcn/toast.tsx";
import Flash from "@/components/ui/Flash.tsx";
import Ga4 from "@/lib/ga";
import SentryInit from "@/lib/SentryInit.tsx";

const App = ({ children }) => {

  return (
    <ThemeProvider>
      <ToastProvider>
        <SentryInit />
        <Ga4 />
        <MainLayout>{children}</MainLayout>
        <Toaster />
        <Flash />
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
