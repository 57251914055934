import { Head } from '@inertiajs/react';
import { StaticPage } from "@/components/layouts/StaticPageLayout.tsx";
import { Mail } from 'lucide-react';

const PrivacyPage = () => {
  return (
    <>
      <Head title="Privacy Policy" />
      <StaticPage title="Privacy Policy">
        <div className="space-y-6 text-white">
          {/* Introduction */}
          <section className="mb-8">
            <p className="text-white/90 mb-4">
              At Old World Rankings, we take your privacy seriously. This Privacy Policy outlines how we collect,
              use, and protect your personal information when you use our services.
            </p>

            <div className="bg-warhammer-gold/10 rounded-lg p-4 border border-warhammer-gold/20 flex items-center gap-4">
              <Mail className="h-5 w-5 text-warhammer-gold flex-shrink-0" />
              <p className="text-white/90">
                For any privacy, security, or data-related inquiries, including data removal requests,
                please contact us at{' '}
                <a href="mailto:data@oldworldrankings.com"
                   className="text-warhammer-gold hover:underline">
                  data@oldworldrankings.com
                </a>
              </p>
            </div>
          </section>

          {/* Data Collection */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Information We Collect</h2>
            <p className="text-white/90 mb-4">We collect the following types of information:</p>

            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>Account information (email address, username)</li>
              <li>Profile information (name, nickname, region)</li>
              <li>Tournament results and participation history</li>
              <li>Platform usage data and preferences</li>
              <li>Authentication data from third-party services (when using social login)</li>
            </ul>
          </section>

          {/* How We Use Your Data */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">How We Use Your Information</h2>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>To provide and maintain our tournament ranking services</li>
              <li>To calculate and display tournament rankings and achievements</li>
              <li>To communicate important updates about our services</li>
              <li>To improve our platform and user experience</li>
              <li>To respond to your requests and support inquiries</li>
            </ul>
          </section>

          {/* Data Protection */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Data Protection</h2>
            <p className="text-white/90 mb-4">
              We implement appropriate security measures to protect your personal information from unauthorized
              access, alteration, disclosure, or destruction. Your data is stored securely and accessed only
              by authorized personnel.
            </p>
          </section>

          {/* GDPR Rights */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Your Data Rights</h2>
            <p className="text-white/90 mb-4">
              Under GDPR and other applicable data protection laws, you have the following rights:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>Right to access your personal data</li>
              <li>Right to rectify inaccurate personal data</li>
              <li>Right to erasure ('right to be forgotten')</li>
              <li>Right to restrict processing of your data</li>
              <li>Right to data portability</li>
              <li>Right to object to processing of your data</li>
            </ul>
          </section>

          {/* Data Retention */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Data Retention</h2>
            <p className="text-white/90 mb-4">
              We retain your personal data for as long as necessary to provide our services and comply with
              legal obligations. Tournament results and rankings may be retained indefinitely as part of the
              historical record unless specifically requested for removal.
            </p>
            <p className="text-white/90">
              To request data removal or modification, please contact{' '}
              <a href="mailto:data@oldworldrankings.com"
                 className="text-warhammer-gold hover:underline">
                data@oldworldrankings.com
              </a>
            </p>
          </section>

          {/* Third Party Services */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Third-Party Services</h2>
            <p className="text-white/90 mb-4">
              We use certain third-party services to operate our platform:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-white/90">
              <li>Authentication services (Google, Microsoft)</li>
              <li>Analytics services to improve user experience</li>
              <li>Tournament platform integrations (BCP, New Recruit, Stats & Ladders)</li>
            </ul>
          </section>

          {/* Updates to Privacy Policy */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-warhammer-gold mb-4">Changes to This Policy</h2>
            <p className="text-white/90">
              We may update this Privacy Policy from time to time. We will notify you of any changes by
              posting the new Privacy Policy on this page and updating the "last updated" date.
            </p>
          </section>

          {/* Contact Information */}
          <section className="bg-black/30 rounded-lg p-6 border border-warhammer-gold/20">
            <h2 className="text-xl font-semibold text-warhammer-gold mb-4">Contact Us</h2>
            <p className="text-white/90">
              For any questions or concerns about this Privacy Policy or our data practices, please contact:
            </p>
            <div className="mt-4">
              <p className="text-warhammer-gold">Data Protection Officer</p>
              <a href="mailto:data@oldworldrankings.com"
                 className="text-white hover:text-warhammer-gold transition-colors">
                data@oldworldrankings.com
              </a>
            </div>
          </section>

          <div className="text-sm text-white/60 mt-8">
            Last updated: November 15, 2024
          </div>
        </div>
      </StaticPage>
    </>
  );
};

export default PrivacyPage;
