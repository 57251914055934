import React, { useMemo } from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, Legend} from 'recharts';
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/shadcn/card";

const CHART_COLORS = {
  tournaments: {
    fill: 'rgba(147, 51, 234, 0.7)',  // Purple
    stroke: '#9333EA',
  },
  players: {
    fill: 'rgba(59, 130, 246, 0.7)',  // Blue
    stroke: '#3B82F6',
  },
  users: {
    fill: 'rgba(16,185,129,0.7)', // Green
    stroke: '#10B981',
  },
  linkedUsers: {
    fill: 'rgba(249,115,22,0.7)', // Orange
    stroke: '#F97316',
  },
  armyLists: {
    fill: 'rgba(239,68,68,0.7)',
    stroke: '#EF4444'      // Red
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload) return null;
  const data = payload[0]?.payload;
  const linkagePercent = data.users > 0 ?
    ((data.linked_users / data.users) * 100).toFixed(1) : "0.0";

  return (
    <div className="bg-background/95 backdrop-blur-sm border border-border p-4 rounded-lg shadow-lg">
      <p className="font-bold mb-2">{data.displayDate}</p>
      <div className="space-y-2">
        {[
          { key: 'tournaments', label: 'Tournaments', color: CHART_COLORS.tournaments },
          { key: 'players', label: 'Players', color: CHART_COLORS.players },
          { key: 'users', label: 'Total Users', color: CHART_COLORS.users },
          { key: 'linked_users', label: 'Linked Users', color: CHART_COLORS.linkedUsers }
        ].map(item => (
          <div
            key={item.key}
            className="flex items-center gap-2"
            style={{ color: item.color.stroke }}
          >
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: item.color.stroke }}
            />
            <span className="font-medium">{item.label}:</span>
            <span>{data[item.key]}</span>
            {item.key === 'linked_users' && (
              <span className="text-muted-foreground text-sm">
                ({linkagePercent}% of users)
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


const RegionBreakdownChart = ({ regions }) => {
  const data = useMemo(() => {
    return regions.map(region => ({
      name: region.country_flag,
      fullName: region.name,
      tournaments: region.stats.tournaments,
      players: region.stats.players,
      linked_users: region.stats.linked_users
    }));
  }, [regions]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Regional Breakdown</CardTitle>
        <CardDescription>
          Tournaments per region and player statistics with user linkage
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <XAxis
                dataKey="name"
                tickFormatter={(value) => value}
                interval={0}
              />
              <YAxis />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (!active || !payload) return null;
                  const data = payload[0]?.payload;

                  return (
                    <div className="bg-background/95 backdrop-blur-sm border border-border p-4 rounded-lg shadow-lg">
                      <p className="font-bold mb-2">{data.fullName}</p>
                      <div className="space-y-1">
                        <div className="flex items-center gap-2" style={{ color: CHART_COLORS.tournaments.stroke }}>
                          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: CHART_COLORS.tournaments.stroke }} />
                          <span className="font-medium">Tournaments:</span>
                          <span>{data.tournaments}</span>
                        </div>
                        <div className="flex items-center gap-2" style={{ color: CHART_COLORS.players.stroke }}>
                          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: CHART_COLORS.players.stroke }} />
                          <span className="font-medium">Players:</span>
                          <span>{data.players}</span>
                        </div>
                        <div className="flex items-center gap-2" style={{ color: CHART_COLORS.linkedUsers.stroke }}>
                          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: CHART_COLORS.linkedUsers.stroke }} />
                          <span className="font-medium">Linked Users:</span>
                          <span>{data.linked_users}</span>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
              <Legend />
              <Bar
                dataKey="tournaments"
                fill={CHART_COLORS.tournaments.fill}
                name="Tournaments"
                radius={[4, 4, 0, 0]}
              />
              {/* Stack players and linked users together */}
              <Bar
                dataKey="players"
                fill={CHART_COLORS.players.fill}
                name="Players"
                radius={[4, 4, 0, 0]}
                stackId="a"
              />
              <Bar
                dataKey="linked_users"
                fill={CHART_COLORS.linkedUsers.fill}
                name="Linked Users"
                radius={[4, 4, 0, 0]}
                stackId="a"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

const GrowthChart = ({ growth }) => {
  const data = useMemo(() => {
    return growth.map(point => ({
      ...point,
      displayDate: point.week
    }));
  }, [growth]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Growth Over Time (Last 3 Months)</CardTitle>
        <CardDescription>
          Weekly growth of tournaments, players, and user statistics
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <defs>
                {Object.entries(CHART_COLORS).map(([key, colors]) => (
                  <linearGradient
                    key={key}
                    id={`gradient-${key}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor={colors.stroke} stopOpacity={0.2}/>
                    <stop offset="95%" stopColor={colors.stroke} stopOpacity={0}/>
                  </linearGradient>
                ))}
              </defs>
              <XAxis
                dataKey="displayDate"
                interval={0}
                angle={-45}
                textAnchor="end"
                height={60}
                tick={{ fontSize: 12 }}
              />
              <YAxis />
              <Tooltip content={CustomTooltip} />
              <Legend
                verticalAlign="top"
                height={36}
                formatter={(value) => {
                  const labels = {
                    tournaments: "Tournaments",
                    players: "Players",
                    users: "Total Users",
                    linked_users: "Linked Users"
                  };
                  return <span className="text-sm">{labels[value]}</span>;
                }}
              />

              {/* Chart Lines */}
              {[
                { key: 'tournaments', color: CHART_COLORS.tournaments },
                { key: 'players', color: CHART_COLORS.players },
                { key: 'users', color: CHART_COLORS.users },
                { key: 'linked_users', color: CHART_COLORS.linkedUsers }
              ].map(({ key, color }) => (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={color.stroke}
                  strokeWidth={2}
                  name={key}
                  dot={{
                    r: 4,
                    fill: color.stroke,
                    strokeWidth: 2,
                    stroke: color.stroke
                  }}
                  activeDot={{ r: 6 }}
                  fillOpacity={1}
                  fill={`url(#gradient-${key})`}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export { RegionBreakdownChart, GrowthChart };
