// app/frontend/lib/auth.ts
type AuthType = 'login' | 'signup';

interface OmniAuthOptions {
  authType?: AuthType;
}

export const handleOmniAuth = (provider: string, options: OmniAuthOptions = {}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

  const form = document.createElement('form');
  form.method = 'POST';

  // Add auth_type as query parameter if provided
  const authTypeParam = options.authType ? `?auth_type=${options.authType}` : '';
  form.action = `/auth/${provider}${authTypeParam}`;

  const csrfInput = document.createElement('input');
  csrfInput.type = 'hidden';
  csrfInput.name = 'authenticity_token';
  csrfInput.value = csrfToken || '';
  form.appendChild(csrfInput);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const handleMicrosoftSignIn = (options: OmniAuthOptions = {}) => {
  handleOmniAuth('microsoft_graph', options);
};

export const handleGoogleSignIn = (options: OmniAuthOptions = {}) => {
  handleOmniAuth('google_oauth2', options);
};
