import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/shadcn/select";
import { Table } from '@tanstack/react-table';
import { Tournament } from '@/types/Tournament.types';
import React, {useEffect, useMemo} from "react";

interface RoundsFilterProps {
  table: Table<Tournament>;
}

export function RoundsFilter({ table }: RoundsFilterProps) {
  const initialType = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('type') || 'all';
  }, []);
  const [currentFilter, setCurrentFilter] = React.useState(initialType);

  useEffect(() => {
    if (initialType !== 'all') {
      table.getColumn('rounds')?.setFilterValue(initialType);
    }
  }, [initialType, table]);

  const handleFilterChange = (value: string) => {
    setCurrentFilter(value);
    if (value === 'all') {
      table.getColumn('rounds')?.setFilterValue(undefined);
    } else {
      table.getColumn('rounds')?.setFilterValue(value);
    }
  };

  return (
    <Select onValueChange={handleFilterChange} value={currentFilter}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Filter by event type" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All Events</SelectItem>
        <SelectItem value="rtt">RTT (&lt; 5 rounds)</SelectItem>
        <SelectItem value="gt">GT (5+ rounds)</SelectItem>
      </SelectContent>
    </Select>
  );
}
