import {router, usePage} from "@inertiajs/react";
import {useEffect} from "react";

const Ga4 = () => {
  const { front_end_configs } = usePage().props
  const { ga_measurement_id } = front_end_configs

  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag && ga_measurement_id) {
        window.gtag('config', ga_measurement_id, {
          page_path: url,
        });
      }
    }

    const handleNavigate = (event) => {
      const url = event.detail.page.url;
      trackPageView(url);
    };

    trackPageView(window.location.pathname);

    router.on('navigate', handleNavigate);
    return () => {

    };
  }, [ga_measurement_id]);

  return null;
}

export default Ga4
