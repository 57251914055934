import React from 'react';
import {useForm, usePage} from '@inertiajs/react';
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import EditorDialog from "@/components/ui/forms/EditorDialog";
import { PlayerSelect } from "@/components/ui/controls/PlayerSelect";
import { FactionSelect } from "@/components/ui/controls/FactionSelect";
import { AchievementMultiSelect } from "@/components/ui/controls/AchievementMultiSelect";
import { ArmyListSubmission } from "@/components/ui/tournaments/common/ArmyListSubmission";

interface Player {
  id: string | null;
  full_name: string;
  is_new?: boolean;
}

export interface ArmyList {
  id: string | null;
  list_type: 'text' | 'owb';
  list_text?: string;
  list_json?: object | null;
  verified: boolean;
  locked: boolean;
}

interface TournamentResultDialogProps {
  isOpen: boolean;
  onClose: () => void;
  tournament_result: {
    id: number;
    wins: number;
    losses: number;
    draws: number;
    rank: number;
    score: number;
    player?: Player;
    faction?: {
      id: string;
      name: string;
    } | null;
    achievements?: string[];
    army_list?: ArmyList;
  };
  isCreating?: boolean;
}

function TournamentResultDialog({
                                  isOpen,
                                  onClose,
                                  tournament_result,
                                  isCreating = false,
                                }: TournamentResultDialogProps) {
  const { tournament, current_region, factions, achievements } = usePage<PageProps>().props;

  const { data, setData, post, patch, processing, errors, reset, delete: destroy } = useForm({
    player: tournament_result.player || null,
    faction_id: tournament_result.faction?.id || null,
    achievement_ids: (tournament_result.achievements || []).map(a => a.id),
    rank: tournament_result.rank,
    wins: tournament_result.wins,
    losses: tournament_result.losses,
    draws: tournament_result.draws,
    score: tournament_result.score,
    army_list: tournament_result.army_list || {
      id: null,
      list_type: 'text' as const,
      list_text: '',
      list_json: null,
      verified: false,
      locked: false
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const url = isCreating
      ? `/${current_region.slug}/tournaments/${tournament.id}/tournament_results`
      : `/${current_region.slug}/tournaments/${tournament.id}/tournament_results/${tournament_result.id}`;
    const method = isCreating ? post : patch;

    method(url, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  const handleDelete = () => {
    if (!isCreating) {
      destroy(`/${current_region.slug}/tournaments/${tournament.id}/tournament_results/${tournament_result.id}`, {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <EditorDialog
      isOpen={isOpen}
      onClose={onClose}
      title={isCreating ? "Create Tournament Result" : "Edit Tournament Result"}
      onSubmit={handleSubmit}
      onDelete={!isCreating ? handleDelete : undefined}
      isProcessing={processing}
      className="sm:max-w-[700px]"
    >
      <div className="space-y-4 py-4">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <Label htmlFor="player">Player</Label>
            <PlayerSelect
              selectedPlayer={data.player}
              onSelect={(player) => setData('player', player)}
            />
            {errors.player && <p className="text-red-500 text-sm mt-1">{errors.player}</p>}
          </div>
          <div>
            <Label htmlFor="faction">Faction</Label>
            <FactionSelect
              factions={factions}
              selectedFactionId={data.faction_id}
              onSelect={(factionId) => setData('faction_id', factionId)}
            />
            {errors.faction_id && <p className="text-red-500 text-sm mt-1">{errors.faction_id}</p>}
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4">
          {(['rank', 'wins', 'losses', 'draws', 'score'] as const).map((field) => (
            <div key={field}>
              <Label htmlFor={field} className="capitalize">{field}</Label>
              <Input
                id={field}
                type="number"
                value={data[field]}
                onChange={(e) => setData(field, Number(e.target.value))}
              />
              {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
            </div>
          ))}
        </div>

        <div>
          <Label htmlFor="achievements">Achievements</Label>
          <AchievementMultiSelect
            achievements={achievements}
            selectedAchievementIds={data.achievement_ids}
            onSelect={(achievementIds) => setData('achievement_ids', achievementIds)}
          />
        </div>
      </div>
      <div className="mt-4">
        <ArmyListSubmission
          data={data.army_list}
          onDataChange={(armyList) => {
            setData('army_list', armyList);
          }}
        />
      </div>
    </EditorDialog>
  );
}

export default TournamentResultDialog;
