import React from 'react';
import { Button as ShadcnButton } from "@/components/ui/shadcn/button";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { buttonVariants } from "@/components/ui/shadcn/button";
import * as LucideIcons from 'lucide-react';

type IconName = keyof typeof LucideIcons;

interface ResponsiveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: IconName;
  children?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ResponsiveButtonProps>(
  ({ className, variant, size, asChild = false, icon, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    // If there's no icon, render the button normally
    if (!icon) {
      return (
        <ShadcnButton
          ref={ref}
          variant={variant}
          size={size}
          className={className}
          {...props}
        >
          {children}
        </ShadcnButton>
      );
    }

    const Icon = LucideIcons[icon];

    return (
      <ShadcnButton
        ref={ref}
        variant={variant}
        size={size}
        className={className}
        {...props}
      >
        <Icon className="h-4 w-4" />
        <span className="hidden md:inline-block md:ml-2">
          {children}
        </span>
      </ShadcnButton>
    );
  }
);

Button.displayName = "Button";

export { Button };
export type { IconName };
