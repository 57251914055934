import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import {AlertTriangle, SquareCheckBig} from 'lucide-react';
import {Link, usePage} from "@inertiajs/react";

const STORAGE_KEY = 'scoring_disabled_acknowledged';

const ScoringDisabledDialog = () => {
  const { current_region } = usePage().props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const checkAcknowledgement = () => {
      const acknowledged = localStorage.getItem(STORAGE_KEY);
      if (acknowledged) {
        const acknowledgedDate = new Date(acknowledged);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        if (acknowledgedDate > thirtyDaysAgo) {
          return;
        }
      }
      setIsOpen(true);
    };

    checkAcknowledgement();
  }, []);

  const handleAcknowledge = () => {
    localStorage.setItem(STORAGE_KEY, new Date().toISOString());
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
            <DialogTitle>Rankings are not official {current_region.country_flag}</DialogTitle>
          </div>
        </DialogHeader>
        <div className="py-6">
          <p className="text-sm text-muted-foreground mb-6">
            The rankings system for this region is currently in demonstration mode. The ranking scores
            shown are based on the <Link className="underline font-semibold" href={"/au/rankings"}>Australian
            Masters</Link> format using real tournament results from this region.
          </p>
          <p className="text-sm text-muted-foreground mb-6">
            These rankings then do not represent true or official standings for this region.
          </p>
          <p className="text-sm text-muted-foreground mb-6">
            Contact us if you are interested in helping organise your region's rankings.
          </p>
          <Button className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
                  onClick={handleAcknowledge}>
            <SquareCheckBig className="w-6 h-6 mr-2 text-warhammer-black"/>
            I Understand
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScoringDisabledDialog;
