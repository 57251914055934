import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { router } from '@inertiajs/react';
import { Search } from 'lucide-react';
import { debounce } from 'lodash';
import axios from 'axios';
import { cn } from "@/lib/utils";

interface SearchResult {
  players: Array<{
    id: string;
    full_name: string;
    nickname?: string;
    friendly_url: string;
    home_region: {
      code: string;
      country_flag: string;
    };
  }>;
  tournaments: Array<{
    id: string;
    name: string;
    friendly_url: string;
    region: {
      code: string;
      country_flag: string;
    };
  }>;
}

const GlobalSearch = () => {
  const [results, setResults] = useState<SearchResult | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const allResults = useMemo(() => {
    if (!results) return [];
    return [
      ...results.players.map(player => ({ type: 'player', ...player })),
      ...results.tournaments.map(tournament => ({ type: 'tournament', ...tournament }))
    ];
  }, [results]);

  const searchAcrossRegions = async (query: string) => {
    if (!query || query.length < 2) {
      setResults(null);
      return;
    }

    setIsSearching(true);
    try {
      const response = await axios.get('/global_search', {
        params: { query }
      });
      setResults(response.data);
    } catch (error) {
      console.error('Search error:', error);
      setResults(null);
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(searchAcrossRegions, 300),
    []
  );

  const handleSelect = (url: string) => {
    setIsOpen(false);
    router.visit(url);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen || !allResults.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prev => (prev + 1) % allResults.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prev => (prev <= 0 ? allResults.length - 1 : prev - 1));
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0) {
          const result = allResults[activeIndex];
          handleSelect(result.friendly_url);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        setActiveIndex(-1);
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
        setActiveIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="w-full max-w-2xl mx-auto mb-6">
      <div className="relative" ref={dropdownRef}>
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"/>
        <input
          type="text"
          placeholder="Search for players or tournaments..."
          onChange={(e) => {
            debouncedSearch(e.target.value);
            setIsOpen(true);
          }}
          onKeyDown={handleKeyDown}
          className="w-full bg-black/50 border-2 border-warhammer-gold/30 rounded-lg pl-12 pr-4 py-3 text-white placeholder:text-gray-400 focus:outline-none focus:border-warhammer-gold"
        />

        {isOpen && results && (results.players.length > 0 || results.tournaments.length > 0) && (
          <div className="absolute w-full mt-2 bg-black border border-warhammer-gold/30 rounded-lg shadow-lg divide-y divide-warhammer-gold/20 z-50">
            {results.players.length > 0 && (
              <div className="p-2">
                <div className="text-xs text-warhammer-gold/60 uppercase mb-2 px-2">Players</div>
                {results.players.map((player, index) => (
                  <button
                    key={player.id}
                    onClick={() => handleSelect(player.friendly_url)}
                    className={cn(
                      "w-full text-left px-4 py-2 hover:bg-warhammer-gold/10 rounded-lg flex items-center justify-between group",
                      activeIndex === index && "bg-warhammer-gold/10"
                    )}
                  >
                    <div>
                      <span className="text-white">{player.full_name}</span>
                      {player.nickname && (
                        <span className="text-gray-400 ml-2">(@{player.nickname})</span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <span className="text-2xl mr-2" title={`${player.home_region.code} Region`}>
                        {player.home_region.country_flag}
                      </span>
                      <span className="text-warhammer-gold opacity-0 group-hover:opacity-100 transition-opacity">
                        View profile →
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            )}

            {results.tournaments.length > 0 && (
              <div className="p-2">
                <div className="text-xs text-warhammer-gold/60 uppercase mb-2 px-2">Tournaments</div>
                {results.tournaments.map((tournament, index) => (
                  <button
                    key={tournament.id}
                    onClick={() => handleSelect(tournament.friendly_url)}
                    className={cn(
                      "w-full text-left px-4 py-2 hover:bg-warhammer-gold/10 rounded-lg flex items-center justify-between group",
                      activeIndex === (results.players.length + index) && "bg-warhammer-gold/10"
                    )}
                  >
                    <span className="text-white">{tournament.name}</span>
                    <div className="flex items-center">
                      <span className="text-2xl mr-2" title={`${tournament.region.code} Region`}>
                        {tournament.region.country_flag}
                      </span>
                      <span className="text-warhammer-gold opacity-0 group-hover:opacity-100 transition-opacity">
                        View tournament →
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        )}

        {isSearching && (
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-warhammer-gold"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalSearch;
