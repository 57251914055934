import React, { useState, useCallback } from 'react'
import Select from 'react-select/async-creatable'
import axios from 'axios'
import { debounce } from 'lodash'

interface Player {
  id: string | null
  full_name: string
  is_new?: boolean,
  home_region?: {
    code: string;
    country_flag: string;
  };
}

interface PlayerOption {
  value: string
  label: string
  isNew?: boolean
  region_flag?: string;
}

interface PlayerSelectProps {
  selectedPlayer: Player | null;
  onSelect: (player: Player) => void;
  allowCreate?: boolean;
  currentPlayerId?: string;
  excludeLinked?: boolean;
}

export function PlayerSelect({
                               selectedPlayer,
                               onSelect,
                               allowCreate = true,
                               currentPlayerId,
                               excludeLinked = false
                             }: PlayerSelectProps) {
  const [inputValue, setInputValue] = useState('')


  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: PlayerOption[]) => void) => {
      if (inputValue.length > 2) {
        axios.get('/players/search', {
          params: {
            query: inputValue,
            exclude_id: currentPlayerId,
            exclude_linked: excludeLinked
          }
        }).then(response => {
          const options = response.data.map((player: Player) => ({
            value: player.id,
            label: player.full_name,
            region_flag: player.home_region?.country_flag
          }));
          callback(options);
        }).catch(error => {
          console.error('Error searching players:', error);
          callback([]);
        });
      } else {
        callback([]);
      }
    }, 300),
    [currentPlayerId, excludeLinked]
  );

  const handleChange = (newValue: PlayerOption | null, actionMeta: any) => {
    if (newValue) {
      onSelect({
        id: newValue.__isNew__ ? null : newValue.value,
        full_name: newValue.label,
        is_new: newValue.__isNew__
      })
    }
  }

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue)
  }

  /** So we get it close to our shadcn components */
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: '#9ca3af'
      },
      minHeight: '40px', // Adjust this value to match your other inputs
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    input: (provided: any) => ({
      ...provided,
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4f46e5' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4f46e5' : '#f3f4f6'
      },
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
  }

  const formatOptionLabel = ({ label, region_flag, isNew }: PlayerOption) => (
    <div className="flex items-center gap-2">
      {!isNew && region_flag && (
        <span className="text-lg" title="Player's home region">
          {region_flag}
        </span>
      )}
      <span>{label}</span>
    </div>
  )

  return (
    <Select
      cacheOptions
      loadOptions={loadOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      styles={customStyles}
      isClearable
      placeholder="Search for a player..."
      noOptionsMessage={() => "No players found"}
      formatCreateLabel={allowCreate ? (inputValue) => `Create new player "${inputValue}"` : undefined}
      formatOptionLabel={formatOptionLabel}
      value={selectedPlayer ? {
        value: selectedPlayer.id || '',
        label: selectedPlayer.full_name,
        region_flag: selectedPlayer.home_region?.country_flag
      } : null}
      isValidNewOption={allowCreate ? undefined : () => false}
      createOptionPosition="first"
      allowCreateWhileLoading={false}
    />
  );
}
