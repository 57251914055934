import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { Calendar } from 'lucide-react';
import { TournamentCard } from '../common/TournamentCard';
import { Tournament } from '../Types';
import { useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { EmptyMonthState, MonthMarker, TimelineConnector } from "@/components/ui/tournaments/timeline/TournamentComponents";
import { useCombinedFilters } from '../common/useCombinedFilters';
import { TournamentFilterControls } from '../common/TournamentFilterControls';
import {addMonths} from "date-fns/addMonths";
import {startOfMonth} from "date-fns/startOfMonth";
import {endOfMonth} from "date-fns/endOfMonth";
import {isThisMonth} from "date-fns/isThisMonth";
import {cn} from "@/lib/utils.ts";

interface TournamentTimelineProps {
  tournaments: Tournament[];
  onAdd?: () => void;
  showMonthTimeline?: boolean;
  variant?: 'default' | 'results';
}

export default function TournamentTimeline({
                                             tournaments,
                                             onAdd,
                                             showMonthTimeline = true,
                                             variant = 'default'
                                           }: TournamentTimelineProps) {
  // Setup minimal table instance for StateFilter to work with
  const columns = useMemo<ColumnDef<Tournament>[]>(() => [
    {
      accessorFn: (row) => row.address?.state,
      id: 'location'
    },
    {
      accessorFn: (row) => row.rounds,
      id: 'rounds'
    }
  ], []);

  const table = useReactTable({
    data: tournaments,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useCombinedFilters(table, [
    { columnId: 'location', paramName: 'state' },
    { columnId: 'rounds', paramName: 'type' }
  ]);

  // Generate tournament groups based on whether we're showing month timeline
  const tournamentGroups = useMemo(() => {
    const state = table.getColumn('location')?.getFilterValue() as string;
    const roundsFilter = table.getColumn('rounds')?.getFilterValue() as string;

    // Filter tournaments based on selected filters
    const filteredTournaments = tournaments.filter(t => {
      const stateMatch = !state || t.address?.state === state;
      let roundsMatch = true;
      if (roundsFilter) {
        if (roundsFilter === 'rtt') {
          roundsMatch = t.rounds < 5;
        } else if (roundsFilter === 'gt') {
          roundsMatch = t.rounds >= 5;
        }
      }
      return stateMatch && roundsMatch;
    });

    if (!showMonthTimeline) {
      return [{
        month: '',
        isCurrentMonth: false,
        tournaments: tournaments
      }];
    }

    // Generate array of next 6 months including current month
    const months = Array.from({ length: 6 }, (_, i) => {
      const date = addMonths(startOfMonth(new Date()), i);
      return {
        month: format(date, 'MMMM yyyy'),
        start: startOfMonth(date),
        end: endOfMonth(date),
        isCurrentMonth: isThisMonth(date)
      };
    });

    // Group tournaments by month
    return months.map(({ month, start, end, isCurrentMonth }) => {
      const monthTournaments = filteredTournaments.filter(t => {
        const tournamentDate = parseISO(t.start_date);
        return tournamentDate >= start && tournamentDate <= end;
      });

      return {
        month,
        isCurrentMonth,
        tournaments: monthTournaments.sort((a, b) =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        )
      };
    });
  }, [tournaments, table.getState().columnFilters, showMonthTimeline]);

  if (!tournaments.length) {
    return (
      <div className="flex flex-col items-center justify-center p-12 text-center">
        <Calendar className="h-16 w-16 text-muted-foreground mb-4" />
        <h3 className="text-xl font-semibold mb-2">No Upcoming Tournaments</h3>
        <p className="text-muted-foreground">
          Check back soon for new tournament announcements
        </p>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto md:p-4">
      <TournamentFilterControls table={table} data={tournaments} />
      <div className="relative">
        {showMonthTimeline && <TimelineConnector />}
        {tournamentGroups.map(({ month, tournaments: groupTournaments, isCurrentMonth }) => (
          <div key={month}>
            {showMonthTimeline && month && (
              <MonthMarker month={month} isCurrentMonth={isCurrentMonth} />
            )}
            {groupTournaments.length > 0 ? (
              <div className={cn(
                "grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-6",
                // Adjust padding based on variant
                variant === 'results'
                  ? "px-2 sm:pr-4 sm:pl-12" // Less padding on mobile for results view
                  : "pr-4 pl-12" // Default padding
              )}>                {groupTournaments.map((tournament) => (
                <TournamentCard
                  key={tournament.id}
                  tournament={tournament}
                  variant={variant}
                />
              ))}
              </div>
            ) : (
              showMonthTimeline && (
                <EmptyMonthState
                  month={month}
                  state={table.getColumn('location')?.getFilterValue() as string || null}
                  onAdd={onAdd}
                />
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
