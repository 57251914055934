import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/shadcn/table";
import PointSystemInfo from "@/components/ui/rankings/PointSystemInfo.tsx";
import {Link, usePage} from "@inertiajs/react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/components/ui/shadcn/tooltip.tsx";
import PlayerNameLink from "@/components/ui/players/PlayerNameLink";

interface RankingData {
  id: number;
  name: string;
  nickname?: string;
  factions: string[];
  totalPoints: number;
  last_three: {
    points: number;
    tournament_id: number;
  }[];
  achievements: string[];
}

interface RankingsTableProps {
  rankings: RankingData[];
  showDetailedView?: boolean;
}

function RankingsTable({ rankings, showDetailedView = true } :RankingsTableProps) {
  const { achievements, current_region } = usePage<PageProps>().props;

  const achievementMap = achievements.reduce((acc, achievement) => {
    acc[achievement.emoji] = achievement;
    return acc;
  }, {});

  const getRankEmoji = (rank: number) => {
    if (rank === 1) return '🏆';
    if (rank === 2) return '🥈';
    if (rank === 3) return '🥉';
    return `${rank}.`;
  };

  const renderTournamentPoints = (pointData: { points: number; tournament_id: number } | null) => {
    if (!pointData) return '-';

    return (
      <Link
        href={`/${current_region.slug}/tournaments/${pointData.tournament_id}`}
        className="text-black-600 hover:underline"
      >
        {pointData.points || '-'}
      </Link>
    );
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[50px]">Rank</TableHead>
          <TableHead>Player</TableHead>
          <TableHead className="text-right">
            <div className="flex items-center justify-end space-x-2">
              <span>Ranking Points</span>
              <PointSystemInfo />
            </div>
          </TableHead>
          <TableHead>Achievements</TableHead>
          {showDetailedView && (
            <>
              <TableHead className="hidden md:table-cell">Faction(s)</TableHead>
              <TableHead className="hidden md:table-cell text-right">Best Tournament</TableHead>
              <TableHead className="hidden md:table-cell text-right">2nd Best Tournament</TableHead>
              <TableHead className="hidden md:table-cell text-right">3rd Best Tournament</TableHead>
            </>
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rankings.map((player, index) => (
          <TableRow
            key={player.id}
            className={`
              ${index < current_region.masters_cutoff ? 'font-semibold bg-muted/50 dark:bg-muted/20' : ''}
              ${index === (current_region.masters_cutoff - 1) ? 'border-b-2 border-warhammer-gold' : ''}
            `}
          >
            <TableCell className="font-medium">{getRankEmoji(index + 1)}</TableCell>
            <TableCell><PlayerNameLink player={player}/></TableCell>
            <TableCell className="text-right font-bold">{player.total_points}</TableCell>
            <TableCell>
              <div className="flex items-center space-x-1">
                <TooltipProvider>
                  {player.achievements.map((emoji, index) => (
                    <Tooltip key={`${emoji}-${index}`}>
                      <TooltipTrigger>
                        <span className="cursor-help">{emoji}</span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{achievementMap[emoji].label}</p>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </TooltipProvider>
              </div>
            </TableCell>
            {showDetailedView && (
              <>
                <TableCell className="hidden md:table-cell">{player.factions.join(', ')}</TableCell>
                <TableCell className="hidden md:table-cell text-right">
                  {renderTournamentPoints(player.last_three[0])}
                </TableCell>
                <TableCell className="hidden md:table-cell text-right">
                  {renderTournamentPoints(player.last_three[1])}
                </TableCell>
                <TableCell className="hidden md:table-cell text-right">
                  {renderTournamentPoints(player.last_three[2])}
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RankingsTable;
