import { useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { usePage } from '@inertiajs/react';

interface PageProps {
  front_end_configs: {
    sentry_dsn: string;
  }
}

const SentryInit = () => {
  const { front_end_configs } = usePage<PageProps>().props;
  const { sentry_dsn } = front_end_configs;

  useEffect(() => {
    if (!sentry_dsn) {
      return;
    }

    Sentry.init({
      dsn: sentry_dsn,
      integrations: [
      ],
    });
  }, [sentry_dsn]);

  return null;
};

export default SentryInit;
