import React from 'react';
import { Head, usePage } from '@inertiajs/react';
import { cn } from "@/lib/utils";
import { SeasonSelector } from "@/components/ui/SeasonSelector";
import Breadcrumb from "@/components/ui/Breadcrumb";

interface PageHeaderProps {
  title?: string;
  description?: string;
  showSeasonSelector?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PageHeader = ({
                      title,
                      description,
                      showSeasonSelector = false,
                      className,
                      children
                    }: PageHeaderProps) => {
  const { current_region, breadcrumbs } = usePage().props;

  // Get the title from the last breadcrumb if no title is provided
  const derivedTitle = title || (breadcrumbs && breadcrumbs.length > 0
    ? breadcrumbs[breadcrumbs.length - 1].label
    : '');

  // Generate page title with region
  const pageTitle = derivedTitle
    ? `${derivedTitle} - ${current_region.name}`
    : current_region.name;

  return (
    <div className={cn("space-y-4", className)}>
      <Head title={pageTitle} />

      <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
        <div className="space-y-1 min-w-0 w-full">
          {!title && breadcrumbs && (
            <Breadcrumb items={breadcrumbs} />
          )}
          <h1 className="text-3xl font-bold truncate ">{title}</h1>
          {description && (
            <p className="text-muted-foreground">{description}</p>
          )}
        </div>

        <div className="flex items-center space-x-4 shrink-0">
          {children}
          {showSeasonSelector && <SeasonSelector />}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
