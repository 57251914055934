import React from 'react';
import {Link, useForm, Head} from '@inertiajs/react';
import { Button } from "@/components/ui/shadcn/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import owfLogoWhite from '@/assets/images/owr_logo_white.png';
import GoogleSignInButton from "@/components/ui/controls/GoogleSignInButton.tsx";
import Divider from "@/components/ui/controls/Divider.tsx";
import {Loader2} from "lucide-react";
import MicrosoftSignInButton from "@/components/ui/controls/MicrosoftSignInButton.tsx";
import {handleGoogleSignIn, handleMicrosoftSignIn} from "@/lib/auth.ts";

const Login = () => {
  const { data, setData, post, transform, processing, errors } = useForm({
    email: '',
    password: '',
    remember: false,
  });
  // Wrap the credentials in a user object for devise
  transform((data) => ({
    user: {
      ...data
    }
  }));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    post('/login');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-warhammer-red via-warhammer-red-dark to-warhammer-black">
      <Head title={`Login`} />

      <Card className="w-full max-w-sm bg-warhammer-black border-warhammer-gold">
        <CardHeader className="relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Link href="/">
              <img src={owfLogoWhite} alt="OWF Logo" className="h-16 w-16" />
            </Link>
          </div>
          <CardTitle className="text-2xl text-warhammer-gold mt-8">Login</CardTitle>
          <CardDescription className="text-warhammer-silver">
            Enter your credentials to access your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="grid gap-4">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').content}
            />
            <div className="grid gap-2">
              <Label htmlFor="email" className="text-warhammer-gold">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
                value={data.email}
                onChange={e => setData('email', e.target.value)}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password" className="text-warhammer-gold">Password</Label>
                <Link href="/password/reset"
                      className="ml-auto inline-block text-sm underline text-warhammer-silver hover:text-warhammer-gold">
                  Forgot your password?
                </Link>
              </div>
              <Input
                id="password"
                type="password"
                required
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
                value={data.password}
                onChange={e => setData('password', e.target.value)}
              />
              {errors.password && <p className="text-red-500">{errors.password}</p>}
            </div>
            <Button type="submit" className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
                    disabled={processing}>
              {processing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Logging in...
                </>
              ) : (
                'Login'
              )}
            </Button>
            <Divider text="OR" />
            <GoogleSignInButton
              onClick={() => handleGoogleSignIn({ authType: 'login' })}
            />
            <MicrosoftSignInButton
              onClick={() => handleMicrosoftSignIn({ authType: 'login' })}
            />
          </form>
          <div className="mt-4 text-center text-sm text-warhammer-silver">
            Don't have an account?{" "}
            <Link href="/register" className="underline text-warhammer-gold hover:text-warhammer-gold-dark">
              Sign up
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
