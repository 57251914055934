import React, { useRef } from 'react';
import { Card, CardContent } from "@/components/ui/shadcn/card";
import { Label } from "@/components/ui/shadcn/label";
import { Button } from "@/components/ui/shadcn/button";
import { Input } from "@/components/ui/shadcn/input";
import { Upload } from "lucide-react";

interface ArmyList {
  id: string | null;
  list_type: 'text' | 'owb';
  list_text?: string;
  list_json?: object | null;
  verified: boolean;
  locked: boolean;
}

interface ArmyListSubmissionProps {
  data: ArmyList;
  required?: boolean;
  onDataChange: (data: ArmyList) => void;
}

export function ArmyListSubmission({ data, required = false, onDataChange }: ArmyListSubmissionProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !file.name.endsWith('.owb.json')) {
      alert('Please select a valid .owb.json file');
      return;
    }

    try {
      const content = await file.text();
      const json = JSON.parse(content);
      onDataChange({
        ...data,
        list_type: 'owb',
        list_json: json,
        list_text: undefined
      });
    } catch (error) {
      alert('Invalid JSON file');
    }
  };

  const handleTextChange = (value: string) => {
    onDataChange({
      ...data,
      list_type: 'text',
      list_text: value,
      list_json: null
    });
  };

  const handleTypeChange = (type: 'text' | 'owb') => {
    onDataChange({
      ...data,
      list_type: type,
      list_text: type === 'text' ? data.list_text || '' : undefined,
      list_json: type === 'owb' ? data.list_json || null : null
    });
  };

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div className="space-y-2">
          <Label>List Type</Label>
          <div className="flex gap-4">
            <Button
              type="button"
              variant={data.list_type === 'text' ? 'default' : 'outline'}
              onClick={() => handleTypeChange('text')}
            >
              Text List
            </Button>
            <Button
              type="button"
              disabled
              variant={data.list_type === 'owb' ? 'default' : 'outline'}
              onClick={() => handleTypeChange('owb')}
            >
              Old World Builder
            </Button>
          </div>
        </div>

        {data.list_type === 'text' ? (
          <div className="space-y-2">
            <Label htmlFor="list-content">Army List</Label>
            <textarea
              id="list-content"
              value={data.list_text || ''}
              onChange={(e) => handleTextChange(e.target.value)}
              className="w-full min-h-[200px] p-3 rounded-md border bg-background font-mono text-sm"
              placeholder="Paste your army list here..."
              required={required}
            />
          </div>
        ) : (
          <div className="space-y-2">
            <Label>Upload Old World Builder List</Label>
            <div className="mt-2">
              <Input
                ref={fileInputRef}
                type="file"
                accept=".owb.json"
                onChange={handleFileChange}
                className="hidden"
                id="owb-file"
              />
              <Label
                htmlFor="owb-file"
                className="cursor-pointer inline-flex items-center gap-2 px-4 py-2 rounded-md bg-primary text-primary-foreground hover:bg-primary/90"
              >
                <Upload className="h-4 w-4" />
                Select OWB File
              </Label>
              {data.list_json && (
                <div className="mt-2">
                  <pre className="p-3 bg-muted rounded-md overflow-auto max-h-[200px] text-xs">
                    {JSON.stringify(data.list_json, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
