import React from 'react';
import {usePage} from '@inertiajs/react';
import StaticPageLayout from '@/components/layouts/StaticPageLayout';
import TournamentTicker from "@/components/ui/landing/TournamentTicker";
import { RegionsSection } from "@/components/ui/landing/RegionsSection";
import GlobalSearch from "@/components/ui/landing/GlobalSearch";
import owfLogo from '@/assets/images/owf_logo_white.png';
import {isCrispAvailable, openCrispChat} from "@/lib/crisp.ts";
import {Button} from "@/components/ui/controls/Button.tsx";

const LandingPage = () => {
  const { regions, generated_at } = usePage<PageProps>().props;
  const formattedLastUpdated = new Date(generated_at).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  const handleCrispClick = () => {
    if (isCrispAvailable()) {
      openCrispChat();
    }
  };

  return (
    <div className="flex-grow flex flex-col items-center justify-center px-4">
      {/* Tournament Ticker */}
      <TournamentTicker />

      <div className="text-center space-y-6 mb-12">
        <div className="flex justify-center mb-6">
          <img src={owfLogo} alt="Old World Rankings" className="h-48 w-48 animate-fade-in" />
        </div>
        <p className="text-xl md:text-2xl text-white/90 max-w-2xl mx-auto font-light">
          Track your tournament progress and climb the rankings in the world's premier
          Warhammer: The Old World ranking system
        </p>
      </div>

      <GlobalSearch />

      {/* Sign Up CTA */}
      <div className="w-full max-w-2xl mx-auto mb-12 flex items-center justify-center space-x-4">
        <div className="h-px bg-warhammer-gold/30 flex-1"></div>
        <span className="text-warhammer-gold px-4">OR</span>
        <div className="h-px bg-warhammer-gold/30 flex-1"></div>
      </div>

      <Button
        onClick={handleCrispClick}
        className="mb-12 bg-warhammer-gold text-black px-4 py-2 rounded-md hover:bg-warhammer-gold-dark transition-colors"
      >
        Sign up your region or league
      </Button>

      <RegionsSection regions={regions} />

      <div className="text-xs text-gray-500 text-center mt-4">
        Last updated: {formattedLastUpdated}
      </div>
    </div>
  );
};

// Use the StaticPageLayout but customize it for the landing page
const LandingPageWithLayout = (page: React.ReactNode) => (
  <StaticPageLayout>
    {/* Override the default padding/margins from StaticPage component */}
    <div className="pt-0">
      {page}
    </div>
  </StaticPageLayout>
);

LandingPage.layout = LandingPageWithLayout;

export default LandingPage;
