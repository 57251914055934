import { useState, useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { Storage } from '@/lib/storage';

export const useLinkPlayerPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { current_user } = usePage().props;

  useEffect(() => {
    // Only show if user is logged in, has no player linked, and hasn't dismissed recently
    const shouldShow =
      current_user &&
      !current_user.player &&
      Storage.shouldShowLinkPlayer();

    if (shouldShow) {
      setShowPrompt(true);
    }
  }, [current_user]);

  const closePrompt = () => {
    setShowPrompt(false);
  };

  return {
    showPrompt,
    closePrompt
  };
};
