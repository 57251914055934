import React, { useState } from 'react';
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import { Search } from 'lucide-react';
import { debounce } from 'lodash';

export interface Address {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  latitude?: number;
  longitude?: number;
  formatted_address?: string;
}

interface AddressFormProps {
  address: Address;
  onAddressChange: (address: Address) => void;
}

function AddressForm({ address, onAddressChange }: AddressFormProps) {
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const searchAddress = debounce(async (query: string) => {
    if (!query) return;
    setIsSearching(true);
    try {
      const response = await fetch(`/geocode?address=${encodeURIComponent(query)}`);
      const results = await response.json();
      setAddressSuggestions(results);
    } catch (error) {
      console.error('Error searching address:', error);
    } finally {
      setIsSearching(false);
    }
  }, 500);

  const handleAddressSelect = (newAddress: any) => {
    onAddressChange({
      id: newAddress.id,
      street: newAddress.street,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      formatted_address: newAddress.formatted_address,
      latitude: newAddress.latitude,
      longitude: newAddress.longitude,
    });
    setAddressSuggestions([]);
  };

  const handleFieldChange = (field: keyof Address, value: string) => {
    onAddressChange({ ...address, [field]: value });
  };

  return (
    <div className="space-y-4">
      {/* Full width search field */}
      <div className="relative">
        <Label htmlFor="location">Location Search</Label>
        <div className="relative mt-2">
          <Input
            id="location"
            type="text"
            placeholder="Search for venue address..."
            value={address.formatted_address || ''}
            onChange={(e) => {
              searchAddress(e.target.value);
              handleFieldChange('formatted_address', e.target.value);
            }}
            className="pl-10"
          />
          <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        </div>
        {addressSuggestions.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-background border rounded-md shadow-lg">
            {addressSuggestions.map((suggestion, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-muted cursor-pointer"
                onClick={() => handleAddressSelect(suggestion)}
              >
                {suggestion.formatted_address}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Street and City row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="street">Street Address</Label>
          <Input
            id="street"
            value={address.street || ''}
            onChange={(e) => handleFieldChange('street', e.target.value)}
            className="mt-2"
          />
        </div>
        <div>
          <Label htmlFor="city">City/Suburb</Label>
          <Input
            id="city"
            value={address.city || ''}
            onChange={(e) => handleFieldChange('city', e.target.value)}
            className="mt-2"
          />
        </div>
      </div>

      {/* State, Country, Postcode row */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <Label htmlFor="state">State</Label>
          <Input
            id="state"
            value={address.state || ''}
            onChange={(e) => handleFieldChange('state', e.target.value)}
            className="mt-2"
          />
        </div>
        <div>
          <Label htmlFor="country">Country</Label>
          <Input
            id="country"
            value={address.country || ''}
            onChange={(e) => handleFieldChange('country', e.target.value)}
            className="mt-2"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Label htmlFor="postal_code">Postal Code</Label>
          <Input
            id="postal_code"
            value={address.postal_code || ''}
            onChange={(e) => handleFieldChange('postal_code', e.target.value)}
            className="mt-2"
          />
        </div>
      </div>
    </div>
  );
}

export default AddressForm;
