import React, { useEffect } from 'react';
import {Bell, Moon, Sun} from "lucide-react";
import {Button} from "@/components/ui/shadcn/button.tsx";
import {usePage} from "@inertiajs/react";

declare global {
  interface Window {
    Headway?: {
      init: (config: any) => void;
    };
  }
}

const HEADWAY_ACCOUNT = "Jl9GOy";

const HeadwayWidget = () => {
  const { front_end_configs } = usePage<PageProps>().props;
  const { headway_account } = front_end_configs;

  if (!headway_account) return null;

  useEffect(() => {
    let script;
    const config = {
      account: HEADWAY_ACCOUNT,
      selector: '#headway_badge',
      trigger: '#headway_badge',
    };

    if (document) {

      // Inject custom styles
      const style = document.createElement('style');
      style.textContent = `
      #HW_badge_cont {
        position: absolute !important;
        top: -8px !important;
        right: -6px !important;
      }

      #HW_badge {
        background: #ff0000 !important;
        font-family: 'Montserrat', sans-serif !important;
        font-size: 10px !important;
        height: 16px !important;
        min-width: 16px !important;
        line-height: 16px !important;
        color: #1C1C1C !important; /* warhammer-black */
        font-weight: 600 !important;
      }
    `;
      document.head.appendChild(style);

      script = document.createElement('script');
      script.async = true;
      script.src = 'https://cdn.headwayapp.co/widget.js';
      document.head.appendChild(script);

      script.onload = function () {
        window.Headway.init(config);
      };
    }
  }, []);

  return (
    <Button
      id={"headway_badge"}
      variant="ghost"
      size="icon"
      className="text-warhammer-gold hover:text-black relative"
    >
      <Bell className="h-6 w-6" />
    </Button>
  )
};

export default HeadwayWidget;

