import { User } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/shadcn/card";

interface UserWithBio {
  id: number;
  first_name: string;
  last_name: string;
  bio: string;
}

interface Player {
  id: string;
  full_name: string;
  nickname?: string;
  user?: UserWithBio;
}

interface PlayerBioCardProps {
  player: Player;
}

const PlayerBioCard = ({ player }: PlayerBioCardProps) => {
  if (!player.user?.bio) return null;

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center space-x-2">
          <User className="h-5 w-5 text-warhammer-gold" />
          <CardTitle>About {player.nickname || player.full_name}</CardTitle>
        </div>
        <CardDescription>
          Player bio and information
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="prose prose-sm dark:prose-invert max-w-none">
          {player.user.bio.split('\n').map((paragraph, index) => (
            <p key={index} className="mb-4 last:mb-0">
              {paragraph}
            </p>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default PlayerBioCard;
