import { PLATFORM_CONFIGS, PlatformIdentifier } from "@/components/ui/tournaments/common/PlatformConfigs";
import {ExternalLink} from "lucide-react";

interface PlayerPlatformLinkProps {
  platform: string;
  platformIdentifier: string;
  url: string;
  className?: string;
}

const PlayerPlatformLink = ({
                              platform,
                              platformIdentifier,
                              url,
                              className = ""
                            }: PlayerPlatformLinkProps) => {
  const platformConfig = PLATFORM_CONFIGS[platformIdentifier as PlatformIdentifier];

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`inline-flex items-center gap-2 px-3 py-1 rounded-full bg-secondary hover:bg-secondary/80 transition-colors ${className}`}
    >
      {platformConfig ? (
        <>
          <img
            src={platformConfig.logo}
            alt={`${platformConfig.displayName} logo`}
            className={platformConfig.logoClass}
          />
          <span className="text-sm">{platformConfig.displayName}</span>
          <ExternalLink className="w-4 w-4" />
        </>
      ) : (
        <span className="text-sm">{platform}</span>
      )}
    </a>
  );
};

export default PlayerPlatformLink;
