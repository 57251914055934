import { Button } from "@/components/ui/controls/Button";
import { Calendar, PlusCircle } from 'lucide-react';
import Guard from "@/components/Guard";
import {Link, usePage} from "@inertiajs/react";

export function TimelineConnector() {
  return (
    <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-border" />
  );
}

interface MonthMarkerProps {
  month: string;
  isCurrentMonth: boolean;
}

export function MonthMarker({ month, isCurrentMonth }: MonthMarkerProps) {
  return (
    <div className="relative flex items-center pl-12 py-4">
      <div className="absolute left-0 w-8 h-8 rounded-full bg-warhammer-gold flex items-center justify-center">
        <Calendar className="h-4 w-4 text-black" />
      </div>
      <h3 className="text-xl font-semibold">{month}</h3>
    </div>
  );
}

interface EmptyMonthStateProps {
  month: string;
  state: string | null;
  onAdd?: () => void;
}

export function EmptyMonthState({ month, state, onAdd }: EmptyMonthStateProps) {
  const { current_region } = usePage().props;

  return (
    <div className="pl-12 py-6">
      <div className="bg-muted/30 rounded-lg p-8 text-center">
        <Calendar className="h-12 w-12 mx-auto mb-4 text-muted-foreground" />
        <h4 className="text-lg font-semibold mb-2">No Tournaments in {month}</h4>
        <p className="text-sm text-muted-foreground mb-4">
          {state ?
            `There are no tournaments scheduled in ${state} for this month yet.` :
            'There are no tournaments scheduled for this month yet.'
          }
        </p>
        <Guard requiresRegionAdmin>
          <Link href={`/${current_region.slug}/tournaments/import`}>
            <Button variant="secondary" icon="Download">
              Import Tournament
            </Button>
          </Link>
          <Button variant="outline" size="sm" className="mx-auto" onClick={onAdd}>
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Tournament
          </Button>
        </Guard>
      </div>
    </div>
  );
}
