import React from 'react';
import {CircleHelp} from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";

const PointSystemInfo = ({ iconTrigger = true, isOpen = undefined, onClose = undefined }) => {

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      {iconTrigger && (
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="p-0 h-auto">
          <CircleHelp className="h-5 w-5 text-muted-foreground hover:text-warhammer-gold transition-colors" />
        </Button>
      </DialogTrigger>
      )}
      <DialogContent className="sm:max-w-[700px] sm:mx-auto mx-4 p-4 max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Ranking Point System</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          <div>
            <p className="text-muted-foreground mb-4">
              Understanding how points are calculated for the rankings.
            </p>

            <div className="space-y-6">
              <div>
                <h3 className="font-medium mb-2">Eligibility Criteria</h3>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Only the top 3 results for each player in the current season are considered.</li>
                  <li>Tournaments must have 10+ players to be eligible.</li>
                  <li>Tournaments must have 3+ rounds to be eligible.</li>
                  <li>The maximum points possible per tournament is 80-100 based on 3,4 or 5+ rounds.</li>
                </ul>
              </div>

              <div>
                <h3 className="font-medium mb-2">Point Calculation</h3>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Base points start at 80 and increase by 1 per player over 10, capped at 100</li>
                  <li>A multiplier is applied based on the number of rounds:
                    <ul className="list-disc pl-5 mt-1">
                      <li>3 rounds: 0.8x</li>
                      <li>4 rounds: 0.9x</li>
                      <li>5+ rounds: 1.0x</li>
                    </ul>
                  </li>
                  <li>Points are then adjusted based on the player's final ranking in the tournament</li>
                </ul>
              </div>

              <div>
                <h3 className="font-medium mb-2">Example Calculation</h3>
                <p className="text-sm text-muted-foreground mb-2">
                  A 16-player, 3-round tournament would work out as:
                </p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Base points: 86 (16 players - 10 + 80)</li>
                  <li>Round multiplier: 0.8x (3 rounds)</li>
                  <li>Total tournament points: 68.8</li>
                  <li>1st place would receive: 68.8 points</li>
                  <li>4th place would receive: 51.6 points</li>
                  <li>8th place would receive: 34.4 points</li>
                  <li>12th place would receive: 17.2 points</li>
                  <li>16th place would receive: 4.3 points</li>
                </ul>
              </div>

              <div className="bg-muted/50 p-4 rounded-lg">
                <p className="text-sm">
                  The final points awarded to each player are calculated as a percentage of the total possible points
                  based on their finishing position: ((N-R+1)/N * P) where N is the number of players, R is the player's
                  rank, and P is the tournament's total possible points after applying the round multiplier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PointSystemInfo;
