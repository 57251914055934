import { Button } from "@/components/ui/shadcn/button";
import { CalendarPlus, FileEdit } from "lucide-react";
import { isCrispAvailable, openCrispChat } from "@/lib/crisp";
import {cn} from "@/lib/utils.ts";

type ReportVariant = "submit-event" | "correct-result";

interface ButtonConfig {
  label: string;
  icon: React.ComponentType;
  description?: string;
}

const BUTTON_VARIANTS: Record<ReportVariant, ButtonConfig> = {
  "submit-event": {
    label: "Submit your Event",
    icon: CalendarPlus,
    description: "Add a new tournament to our database"
  },
  "correct-result": {
    label: "Correct a Result",
    icon: FileEdit,
    description: "Report a correction in tournament results"
  }
};

interface ReportButtonProps {
  variant: ReportVariant;
  className?: string;
}

export const ReportButton = ({
                               variant,
                               className
                             }: ReportButtonProps) => {
  // if (!isCrispAvailable()) return null;

  const config = BUTTON_VARIANTS[variant];
  const Icon = config.icon;

  return (
    <Button
      onClick={openCrispChat}
      variant="default"
      className={cn(
        "bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark flex items-center gap-2",
        className
      )}
      title={config.description}
    >
      <Icon className="h-4 w-4" />
      <span className="hidden sm:inline">{config.label}</span>
    </Button>
  );
};
