import React, { useState } from 'react';
import { Input } from "@/components/ui/shadcn/input";
import {router, usePage} from "@inertiajs/react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import LoadingButton from "@/components/ui/controls/LoadingButton";
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import ImportTournamentForm from "@/components/ui/ImportTournamentForm.tsx";

const TournamentImport = () => {
  const { current_region } = usePage().props;
  const [tournamentId, setTournamentId] = useState('');
  const [platform, setPlatform] = useState('tp-new-recruit');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    router.post(`/${current_region.slug}/submit_results`, {
      tournament_id: tournamentId,
      platform: platform,
    }, {
      onFinish: () => setIsLoading(false),
    });
  };

  return (
    <PageContainer
      header={
        <PageHeader
          title="Import Tournament"
          description="Import tournament results from supported platforms"
        />
      }
    >
      <ImportTournamentForm />
    </PageContainer>
  );
};

export default TournamentImport;
