import React from 'react';
import { Head, useForm, usePage } from '@inertiajs/react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/shadcn/tabs";
import { Button } from "@/components/ui/shadcn/button";
import { PasswordSection } from "@/components/ui/profile/PasswordReset";
import { LinkedPlayerCard } from "@/components/ui/profile/LinkedPlayerCard";
import { BasicInfoCard } from "@/components/ui/profile/BasicInfoCard";
import { SocialLinksCard } from "@/components/ui/profile/SocialLinksCard";
import { NotificationCard } from "@/components/ui/profile/NotificationCard";
import Guard from "@/components/Guard.tsx";
import {Player} from "@/components/ui/players/Types.ts";

interface UserProfileProps {
  email: string;
  bio: string;
  socials: Record<string, string>;
  notifications: {
    new_tournaments: boolean;
    ranking_changes: boolean;
    followed_players: boolean;
    site_updates: boolean;
  };
  player?: Player;
}

const Index = () => {
  const { current_user, router } = usePage().props;
  const { data, setData, patch, transform, processing, errors } = useForm<UserProfileProps>({
    email: current_user.email || '',
    bio: current_user.bio || '',
    player: current_user.player,
    socials: {},
    notifications: {
      new_tournaments: true,
      ranking_changes: true,
      followed_players: false,
      site_updates: true,
    },
  });

  transform((data) => ({
    user: {
      ...data
    }
  }));

  const handleSocialChange = (platform: string, value: string) => {
    setData('socials', { ...data.socials, [platform]: value });
  };

  const handleNotificationChange = (setting: string, checked: boolean) => {
    setData('notifications', { ...data.notifications, [setting]: checked });
  };

  const handlePlayerFieldChange = (field: string, value: string) => {
    if (data.player) {
      setData('player', {
        ...data.player,
        [field]: value,
      });
    }
  };

  const handleProfileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    patch('/profile', {
      preserveState: true,
      preserveScroll: true,
    });
  };

  const handleLinkPlayer = () => {
    router.reload({
      preserveState: false,
      preserveScroll: false
    });
  };

  return (
    <div className="container flex-1 overflow-hidden">
      <Head title="User Settings"/>
      <div className="flex h-full flex-col overflow-hidden">

        <h1 className="text-3xl font-bold text-gray-800 dark:text-warhammer-gold">Settings</h1>

        <Tabs defaultValue="profile" className="flex-1 overflow-hidden">
          <TabsList className="grid w-full grid-cols-3 bg-warhammer-black">
            <TabsTrigger value="profile" className="text-warhammer-gold hover:text-white">
              Profile
            </TabsTrigger>
            <TabsTrigger value="notifications" className="text-warhammer-gold hover:text-white">
              Notifications
            </TabsTrigger>
            <TabsTrigger value="security" className="text-warhammer-gold hover:text-white">
              Security
            </TabsTrigger>
          </TabsList>

          <div className="flex-1 overflow-auto">
            <TabsContent value="profile">
              <form onSubmit={handleProfileSubmit} className="space-y-6">
                <BasicInfoCard
                  email={data.email}
                  bio={data.bio}
                  onEmailChange={(e) => setData('email', e.target.value)}
                  onBioChange={(e) => setData('bio', e.target.value)}
                  player={data.player}
                  onPlayerFieldChange={handlePlayerFieldChange}
                />

                <LinkedPlayerCard
                  player={data.player}
                  onLinkPlayer={handleLinkPlayer}
                />

                <Guard requiresSuperAdmin={true}>
                  <SocialLinksCard
                    socials={data.socials}
                    onSocialChange={handleSocialChange}
                  />
                </Guard>

                <div className="flex justify-end">
                  <Button type="submit" disabled={processing}>
                    {processing ? 'Saving...' : 'Save changes'}
                  </Button>
                </div>
              </form>
            </TabsContent>

            <TabsContent value="notifications">
              <form onSubmit={handleProfileSubmit} className="space-y-6">
                <NotificationCard
                  notifications={data.notifications}
                  onUpdate={handleNotificationChange}
                />

                <div className="flex justify-end">
                  <Button type="submit" disabled={processing}>
                    {processing ? 'Saving...' : 'Save changes'}
                  </Button>
                </div>
              </form>
            </TabsContent>

            <TabsContent value="security">
              <div className="space-y-6">
                <PasswordSection errors={errors}/>
              </div>
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

  export default Index;
