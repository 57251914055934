import React from 'react';
import { Link } from '@inertiajs/react';
import { cn } from "@/lib/utils";

interface SidebarItemProps {
  icon: React.ElementType;
  label: string;
  href: string;
  disabled?: boolean;
  external?: boolean;
  isCollapsed: boolean;
  onClick?: () => void;
}

const SidebarItem = ({
                       icon: Icon,
                       label,
                       href,
                       isCollapsed,
                       onClick,
                       disabled = false,
                       external = false
                     }: SidebarItemProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick();
    }
  };

  const className = cn(
    "flex items-center w-full px-4 py-2 transition-colors duration-200",
    isCollapsed ? "justify-center" : "justify-start",
    disabled
      ? "text-warhammer-gold-dark/80 hover:text-white/80 hover:bg-warhammer-red-dark/30"
      : "text-warhammer-gold hover:text-white hover:bg-warhammer-red-dark/30",
  );

  if (external) {
    return (
      <a
        href={href}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon className={cn(
          "h-5 w-5",
          isCollapsed ? "mx-auto" : "mr-2",
          disabled && "opacity-50"
        )}/>
        {!isCollapsed && (
          <>
            <span className="flex-1">{label}</span>
          </>
        )}
      </a>
    );
  }

  return (
    <Link
      href={href}
      className={className}
      onClick={handleClick}
      preserveScroll={false}
      preserveState={false}
    >
      <Icon className={cn(
        "h-5 w-5",
        isCollapsed ? "mx-auto" : "mr-2",
        disabled && "opacity-50"
      )}/>
      {!isCollapsed && <span>{label}</span>}
    </Link>
  );
};

export default SidebarItem;
