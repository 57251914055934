import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import { SocialIcon } from 'react-social-icons';
import {SocialPlatform} from "@/components/ui/profile/Types.ts";

 const DEFAULT_SOCIAL_PLATFORMS: SocialPlatform[] = [
  { key: 'twitter', label: 'X (Twitter)' },
  { key: 'facebook', label: 'Facebook' },
  { key: 'discord', label: 'Discord' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'youtube', label: 'YouTube' },
  { key: 'twitch', label: 'Twitch' },
];

export const SocialLinksCard = ({ socials, onSocialChange }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Social Links</CardTitle>
        <CardDescription>
          Connect your social media profiles
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {DEFAULT_SOCIAL_PLATFORMS.map(({ key, label }) => (
          <div key={key} className="flex items-center space-x-4">
            <div className="w-8">
              <SocialIcon
                network={key}
                style={{ height: 32, width: 32 }}
              />
            </div>
            <div className="flex-1">
              <Label htmlFor={key} className="sr-only">{label}</Label>
              <Input
                id={key}
                placeholder={`Your ${label} profile URL`}
                value={socials[key] || ''}
                onChange={(e) => onSocialChange(key, e.target.value)}
              />
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
