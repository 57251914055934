import {useEffect, useRef} from 'react';
import { Button } from "@/components/ui/shadcn/button";
import { ScrollArea } from "@/components/ui/shadcn/scroll-area";
import { cn } from "@/lib/utils";
import SidebarItem from "@/components/layouts/sidebar/SidebarItem";
import Guard from '@/components/Guard';
import ProfileButtons from '@/components/layouts/ProfileButtons';
import {
  LayoutDashboard,
  Users,
  Trophy,
  Calendar,
  X,
  ChevronLeft,
  ChevronRight,
  Globe,
  UserCog,
  BarChart,
  Cpu,
  Clock, CalendarCheck, CalendarArrowUp, CalendarDays, MessageSquare, ListTodo
} from 'lucide-react';
import wtowLogo from '@/assets/images/wtow_logo.png';
import {Link, usePage} from "@inertiajs/react";
import SidebarGroup from "@/components/layouts/sidebar/SidebarGroup.tsx";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  disabled?: boolean;
}

function Sidebar({ isOpen, onClose, isCollapsed, onToggleCollapse }: SidebarProps) {
  const { current_user, current_region } = usePage().props;
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = current_user !== null;

  const globalItems = [
    { icon: Globe, label: "Global Overview", href: "/" },
  ]

  const publicItems = [
    { icon: LayoutDashboard, label: 'Dashboard', href: `/${current_region.slug}/dashboard` },
    { icon: Trophy, label: 'Masters Rankings', href: `/${current_region.slug}/rankings`, disabled: !current_region.scoring_enabled },
  ];

  const tournamentItems = [
    {
      icon: CalendarDays,
      label: 'Latest Results',
      href: `/${current_region.slug}/tournaments/latest`,
    },
    {
      icon: CalendarArrowUp,
      label: 'Upcoming',
      href: `/${current_region.slug}/tournaments/upcoming`,
    },
    {
      icon: CalendarCheck,
      label: 'Finished',
      href: `/${current_region.slug}/tournaments`,
    },
  ];

  const regionInfo = [
    { icon: Users, label: 'Players', href: '/players'},
  ]

  const regionAdminItems = [
    { icon: ListTodo, label: 'Feedback', href: `/${current_region.slug}/feedback`,  requiresRegionAdmin: true },
    { icon: Calendar, label: 'Seasons', href: '/seasons', requiresRegionAdmin: true },
  ];

  const superAdminItems = [
    { icon: UserCog, label: 'Users', href: '/users', requiresSuperAdmin: true },
    { icon: Globe, label: 'Regions', href: '/regions', requiresSuperAdmin: true },
    { icon: BarChart, label: 'Platform Stats', href: '/admin/stats', requiresSuperAdmin: true },
    { icon: Cpu, label: 'Background Jobs', href: '/admin/jobs', requiresSuperAdmin: true, external: true }
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && isOpen) {
        onClose();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const renderSidebarItems = (items: any[]) => {
    return items.map((item) => (
      <Guard
        key={item.label}
        requiresRegionAdmin={item.requiresRegionAdmin}
        requiresSuperAdmin={item.requiresSuperAdmin}
      >
        <SidebarItem
          icon={item.icon}
          label={item.label}
          href={item.href}
          isCollapsed={isCollapsed}
          onClick={() => {
            if (window.innerWidth < 1024) { // Assuming 'lg' breakpoint is 1024px
              onClose();
            }
          }}
          disabled={item.disabled}
          external={item.external}
        />
      </Guard>
    ));
  };

  const renderDivider = () => (
    <div className={cn(
      "my-1 border-t border-warhammer-gold/20",
      isCollapsed ? "mx-2" : "mx-4"
    )} />
  );

  const canSeeRegionAdminItems = current_user?.is_region_admin || current_user?.is_super_admin;
  const canSeeSuperAdminItems = current_user?.is_super_admin;

  return (
    <div ref={sidebarRef}
       className={cn(
      "fixed inset-y-0 left-0 z-50 transform transition-all duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-y-auto lg:flex lg:flex-col",
      "bg-gradient-to-b from-warhammer-red via-warhammer-red-dark to-warhammer-black",
      isOpen ? "translate-x-0" : "-translate-x-full",
      isCollapsed ? "lg:w-16" : "lg:w-64",
      "w-64", // Default width for mobile
      "flex flex-col justify-between"
      )}>
      <div>
        <div className="flex items-center justify-between px-4 pt-4 relative">
          <div className={cn(
            "hidden lg:block mb-4",
            isCollapsed ? "lg:hidden" : "lg:w-full"
          )}>
            <Link href={`/${current_region.slug}`}>
              <img
                src={wtowLogo}
                alt="Warhammer: The Old World Logo"
                className="w-full h-auto"
              />
            </Link>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={onClose}
            className="lg:hidden text-warhammer-gold hover:text-white"
          >
            <X className="h-6 w-6"/>
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={onToggleCollapse}
            className="hidden lg:flex absolute -right-3 top-4 rounded-full bg-warhammer-red shadow-md text-warhammer-gold hover:text-black"
          >
            {isCollapsed ? <ChevronRight className="h-4 w-4"/> : <ChevronLeft className="h-4 w-4"/>}
          </Button>
        </div>

        <ScrollArea className="flex-1">
          {renderSidebarItems(globalItems)}
          <SidebarGroup label="Regional" isCollapsed={isCollapsed}>
            {renderSidebarItems(publicItems)}
          </SidebarGroup>
          <SidebarGroup label="Tournaments" isCollapsed={isCollapsed}>
            {renderSidebarItems(tournamentItems)}
          </SidebarGroup>
          <SidebarGroup label="Other Information" isCollapsed={isCollapsed}>
            {renderSidebarItems(regionInfo)}
          </SidebarGroup>

          {canSeeRegionAdminItems && renderDivider()}
          {renderSidebarItems(regionAdminItems)}
          {canSeeSuperAdminItems && renderDivider()}
          {renderSidebarItems(superAdminItems)}
        </ScrollArea>
      </div>
      <div className="mt-auto border-warhammer-gold/20">
        <ProfileButtons isLoggedIn={isLoggedIn} isCollapsed={isCollapsed}/>
        {!isCollapsed && (
          <div className="text-center">
            <span className="text-xs text-warhammer-gold/80">
              oldworldrankings.com
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
