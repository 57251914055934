import { Link, usePage } from '@inertiajs/react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import { Calendar, Star, ChevronRight, MapPin, Users, Hash } from 'lucide-react';
import { PlatformLink } from "@/components/ui/controls/PlatformLink";
import FormattedDate from "@/components/ui/FormattedDate";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";
import defaultThumb from "@/assets/images/default-tournament-thumb.png";
import {Tournament} from "@/components/ui/tournaments/Types.ts";

interface UpcomingTournamentsCardProps {
  tournaments: Tournament[];
}

const TournamentRow = ({ tournament }: { tournament: Tournament }) => {
  const isFeatured = tournament.featured;
  const thumbnailUrl = tournament.thumbnail_urls?.card || defaultThumb;

  return (
    <div
      className={`
        relative p-4 mb-2 rounded-lg transition-all
        ${isFeatured ? 'bg-warhammer-gold/10 border border-warhammer-gold' : 'hover:bg-muted/50'}
      `}
    >
      {isFeatured && (
        <div className="absolute -left-1 top-1/2 -translate-y-1/2 w-2 h-16 bg-warhammer-gold rounded-r-full" />
      )}

      <div className="flex items-center gap-4">
        {/* Thumbnail */}
        <div className="hidden sm:block w-24 h-16 flex-shrink-0">
          <img
            src={thumbnailUrl}
            alt={tournament.name}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        {/* Tournament Info */}
        <div className="flex-1 min-w-0">
          <div className="flex items-center">
            {isFeatured && (
              <Star className="h-4 w-4 text-warhammer-gold mr-2 fill-warhammer-gold" />
            )}
            <TournamentLink tournament={tournament} />
          </div>

          <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-4 mt-1 text-sm text-muted-foreground">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2"/>
              <FormattedDate startDate={tournament.start_date} endDate={tournament.end_date}/>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <Hash className="h-4 w-4 mr-1"/>
                {`${tournament.rounds} rounds`}
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-1"/>
                {`${tournament.player_cap} cap`}
              </div>
            </div>
          </div>

          {tournament.address && (
            <div className="flex items-center mt-1 text-sm text-muted-foreground">
              <MapPin className="h-4 w-4 mr-1"/>
              <span>{[tournament.address.city, tournament.address.state].filter(Boolean).join(", ")}</span>
            </div>
          )}
        </div>

        <div className="flex flex-col items-end justify-between">
        <PlatformLink tournament={tournament} />
          <ChevronRight className="h-5 w-5 text-muted-foreground mt-4" />
        </div>
      </div>
    </div>
  );
};

const UpcomingTournamentsCard = ({ tournaments }: UpcomingTournamentsCardProps) => {
  const { current_region } = usePage<PageProps>().props;

  const renderContent = () => {
    if (!tournaments || tournaments.length === 0) {
      return (
        <div className="text-center py-8">
          <Calendar className="h-12 w-12 mx-auto mb-4 text-muted-foreground" />
          <p className="text-lg font-semibold">No upcoming tournaments</p>
          <p className="text-muted-foreground">Check back soon for new events</p>
        </div>
      );
    }

    // Sort tournaments: featured first, then by date
    const sortedTournaments = [...tournaments]
      .sort((a, b) => {
        if (a.featured && !b.featured) return -1;
        if (!a.featured && b.featured) return 1;
        return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
      })
      .slice(0, 5);

    return (
      <div className="space-y-2">
        {sortedTournaments.map((tournament) => (
          <TournamentRow key={tournament.id} tournament={tournament} />
        ))}
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex flex-col space-y-2">
          <Link
            href={`/${current_region.slug}/tournaments/upcoming`}
            className="text-xl font-semibold hover:text-muted-foreground transition-colors"
          >
            <h2 className="text-2xl font-semibold">Upcoming Tournaments</h2>
          </Link>
          <Link
            href={`/${current_region.slug}/tournaments/upcoming`}
            className="text-sm hover:text-foreground transition-colors"
          >
            See all <span className="font-semibold">upcoming tournaments</span> →
          </Link>
        </div>
      </CardHeader>
      <CardContent>
        {renderContent()}
      </CardContent>
    </Card>
  );
};

export default UpcomingTournamentsCard;
