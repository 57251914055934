import React from 'react';
import { Link } from '@inertiajs/react';
import { ChevronRight } from 'lucide-react';

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  if (!items.length) return null;

  const firstItem = items[0];
  const lastItem = items[items.length - 1];

  return (
    <nav aria-label="Breadcrumb">
      {/* Mobile View */}
      <ol className="md:hidden flex flex-col space-y-1">
        <li>
          <Link
            href={firstItem.href || '#'}
            className="text-sm font-medium text-gray-400 hover:text-gray-600"
          >
            {firstItem.label}
          </Link>
        </li>
        <li>
          <h1 className="text-2xl font-bold text-foreground">
            {lastItem.label}
          </h1>
        </li>
      </ol>

      {/* Desktop View */}
      <ol className="hidden md:inline-flex items-center">
        {items.map((item, index) => {
          const isLast = index === items.length - 1;
          const textSize = isLast && items.length == 1 ? 'text-3xl' : 'text-2xl';

          const content = isLast ? (
            <h1 className={`${textSize} font-bold text-foreground`}>
              {item.label}
            </h1>
          ) : (
            <Link
              href={item.href || '#'}
              className="text-sm font-medium text-gray-400 hover:text-gray-600"
            >
              {item.label}
            </Link>
          );

          return (
            <li key={index} className="inline-flex items-center">
              {index > 0 && (
                <ChevronRight className="w-4 h-4 text-muted-foreground mx-2" />
              )}
              {content}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
