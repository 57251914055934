import React from 'react';
import { Card, CardContent, CardHeader } from "@/components/ui/shadcn/card";
import { cn } from "@/lib/utils";
import PageHeader from "@/components/layouts/PageHeader";

interface PageContainerProps {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  header: React.ReactElement<typeof PageHeader>;
  details?: React.ReactNode;
  actions?: React.ReactNode;
  noPadding?: boolean;
}

const PageContainer = ({
                         children,
                         className,
                         contentClassName,
                         header,
                         details,
                         actions,
                         noPadding = false,
                       }: PageContainerProps) => {
  const removePadding = noPadding || window.innerWidth < 640; // sm breakpoint

  // Type guard to ensure header is a PageHeader component
  if (!React.isValidElement(header) || header.type !== PageHeader) {
    throw new Error('PageContainer requires a PageHeader component as the header prop');
  }

  return (
    <div className="p-4 md:p-6 md:space-y-6">
      {header}
      {details}
      <Card className={cn(
        // Remove Card styling on mobile
        "bg-transparent md:bg-background border-0 md:border",
        // Remove default Card padding/shadow on mobile
        "shadow-none md:shadow-sm",
        className)}>
        {actions && (
          <CardHeader className="flex flex-row items-center justify-end space-x-2 pb-2">
            {actions}
          </CardHeader>
        )}
        <CardContent className={cn(
          removePadding ? "p-0" : "",
          contentClassName
        )}>
          {children}
        </CardContent>
      </Card>
    </div>
  );
};

export default PageContainer;
