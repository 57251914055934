import React from 'react';
import { useForm, Head } from '@inertiajs/react';
import { Button } from "@/components/ui/shadcn/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import owfLogoWhite from '@/assets/images/owr_logo_white.png';

interface ResetPasswordFormData {
  email: string;
}

const ResetPassword = () => {
  const { data, setData, post, processing, errors, transform } = useForm<ResetPasswordFormData>({
    email: '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    transform((data) => ({
      user: { ...data }
    }));

    post('/password/reset');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-warhammer-red via-warhammer-red-dark to-warhammer-black p-4">
      <Head title="Reset Password" />

      <Card className="w-full max-w-md bg-warhammer-black border-warhammer-gold">
        <CardHeader className="relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img src={owfLogoWhite} alt="OWF Logo" className="h-16 w-16" />
          </div>
          <CardTitle className="text-2xl text-warhammer-gold mt-8">Reset Password</CardTitle>
          <CardDescription className="text-warhammer-silver">
            Enter your email address and we'll send you a link to reset your password
          </CardDescription>
        </CardHeader>

        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email" className="text-warhammer-gold">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                value={data.email}
                onChange={e => setData('email', e.target.value)}
                className="bg-warhammer-black text-warhammer-silver border-warhammer-gold"
              />
              {errors.email && (
                <p className="text-sm text-destructive">{errors.email}</p>
              )}
            </div>

            <Button
              type="submit"
              className="w-full bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
              disabled={processing}
            >
              {processing ? 'Sending...' : 'Send Reset Instructions'}
            </Button>

            <div className="text-center text-sm">
              <a href="/login" className="text-warhammer-gold hover:text-warhammer-gold-dark">
                Back to login
              </a>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPassword;
