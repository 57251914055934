import React from 'react';
import { Link } from '@inertiajs/react';
import { openCrispChat, isCrispAvailable } from '@/lib/crisp';
import { cn } from '@/lib/utils';

interface FooterLinkProps {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

const FooterLink = ({ href, onClick, children, className }: FooterLinkProps) => {
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={cn(
          "text-gray-400 hover:text-white transition-colors cursor-pointer",
          className
        )}
      >
        {children}
      </button>
    );
  }

  return (
    <Link
      href={href || '#'}
      className={cn(
        "text-gray-400 hover:text-white transition-colors",
        className
      )}
    >
      {children}
    </Link>
  );
};

interface LandingFooterProps {
  className?: string;
}

export const LandingFooter = ({ className }: LandingFooterProps) => {
  const handleCrispClick = () => {
    if (isCrispAvailable()) {
      openCrispChat();
    }
  };

  return (
    <div className={cn(
      "w-full z-30 px-6 py-4 bg-black/70 backdrop-blur-sm",
      "border-t border-warhammer-gold/10",
      className
    )}>
      <div className="max-w-7xl mx-auto flex justify-center md:justify-between items-center">
        <div className="hidden md:block">
          <span className="text-gray-400">© 2024 Old World Rankings. All rights reserved.</span>
        </div>
        <div className="flex gap-6 text-sm">
          <Link href="/terms" className="text-gray-400 hover:text-white transition-colors">
            Terms
          </Link>
          <Link href="/privacy" className="text-gray-400 hover:text-white transition-colors">
            Privacy
          </Link>
          <FooterLink onClick={handleCrispClick}>
            Help
          </FooterLink>
          <FooterLink onClick={handleCrispClick}>
            Contact
          </FooterLink>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
