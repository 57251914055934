import {Tournament} from "@/types/Tournament.types.ts";
import {Link} from "@inertiajs/react";

interface TournamentLinkProps {
  tournament: Tournament;
}

export function TournamentLink({ tournament }: TournamentLinkProps) {

  return (
    <Link href={tournament.friendly_url}
          className="font-semibold text-foreground hover:underline"
          preserveScroll={false}
    >
      <span>{tournament.name}</span>
    </Link>
  );
}
