import React from 'react';
import { Switch } from "@/components/ui/shadcn/switch";
import { Label } from "@/components/ui/shadcn/label";
import { cn } from "@/lib/utils";

interface ToggleProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label?: string;
  description?: string;
  variant?: 'primary' | 'secondary' | 'gold';
}

const Toggle = ({
                  checked,
                  onCheckedChange,
                  label,
                  description,
                  variant = 'primary'
                }: ToggleProps) => {
  const switchClasses = cn(
    "data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-input",
    {
      "data-[state=checked]:bg-secondary": variant === 'secondary',
      "data-[state=checked]:bg-warhammer-gold hover:data-[state=checked]:bg-warhammer-gold-dark": variant === 'gold',
    }
  );

  return (
    <div className="flex items-start space-x-2">
      <Switch
        checked={checked}
        onCheckedChange={onCheckedChange}
        className={switchClasses}
      />
      <div className="grid gap-1.5 leading-none">
        {label && (
          <Label className={cn(
            "text-sm font-medium leading-none",
            checked && variant === 'gold' && "text-warhammer-gold"
          )}>
            {label}
          </Label>
        )}
        {description && (
          <p className="text-sm text-muted-foreground">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default Toggle;
