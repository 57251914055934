import React from 'react';
import Select from 'react-select';

interface Achievement {
  id: string;
  emoji: string;
  label: string;
}

interface AchievementMultiSelectProps {
  achievements: Achievement[];
  selectedAchievementIds: string[];
  onSelect: (achievementIds: string[]) => void;
}

export function AchievementMultiSelect({
                                         achievements,
                                         selectedAchievementIds,
                                         onSelect
                                       }: AchievementMultiSelectProps) {
  const options = achievements.filter(achievement => achievement.podium == null).map(achievement => ({
    value: achievement.id,
    label: achievement.label,
    emoji: achievement.emoji,
  }));

  const selectedOptions = options.filter(option =>
    selectedAchievementIds.includes(option.value)
  );

  const handleChange = (selectedOptions: any) => {
    const newSelectedIds = selectedOptions.map((option: any) => option.value);
    onSelect(newSelectedIds);
  };
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: '#9ca3af'
      },
      minHeight: '40px',
      boxShadow: 'none',
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 999, // High z-index but not too high
      border: '1px solid var(--border)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      pointerEvents: 'auto', // Ensure clicks work
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 999,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'var(--accent-foreground)' : 'var(--foreground)',
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    multiValue: (provided: any) => ({
      ...provided,
      borderRadius: '4px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--accent-foreground)',
      padding: '2px',
      fontSize: '0.875rem', // This equals 14px, adjust if needed
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: 'var(--accent-foreground)',
      ':hover': {
        color: 'var(--accent-foreground)',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: 'var(--muted-foreground)',
    }),
    input: (provided: any) => ({
      ...provided,
      color: 'var(--foreground)',
    }),
  };

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      styles={customStyles}
      className="basic-multi-select"
      classNamePrefix="select"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      placeholder=""
      formatOptionLabel={({ emoji, label, isPodium }) => (
        <div className="flex items-center">
          <span className="mr-2">{emoji}</span>
          <span>{label}</span>
          {isPodium && (
            <span className="ml-2 text-xs opacity-75">(Auto-assigned)</span>
          )}
        </div>
      )}
    />
  );
}
